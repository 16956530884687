import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

// styles and components from material-ui
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

// components
import CompanyInput from '../CompanyInput';

import { getCompanyName } from 'now-shared/helpers/company-helpers';

const useStyles = makeStyles(theme => ({
  chipContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const CompaniesInput = ({
  autoFocus,
  companies,
  disabled,
  input,
  label,
  loading,
  meta,
  placeholder,
  showDeactivated,
}) => {
  const classes = useStyles();
  const [companyInputKey, setCompanyInputKey] = useState(0);

  const {
    onChange,
    onBlur,
    value: selectedIds,
  } = input;

  false && console.log('typeof selectedIds', typeof selectedIds);
  false && console.log('selectedIds', selectedIds);
  false && console.log('meta', meta);

  const error = (meta.touched && meta.error) || '';
  false && console.log('error', error);

  // TODO: [UX][INTEGRITY] (NOW-925) handle displaying selectedIds that do not exist in the companies list
  // (for example, a company that is deleted or no longer active)
  const selectedCompanies = useMemo(() => {
    let result = [];
    if (selectedIds) {
      result = companies.filter(company => selectedIds.includes(company.id));
    }
    return result;
  }, [companies, selectedIds]);

  false && console.log('selectedCompanies', selectedCompanies);

  const availableCompanies = useMemo(() => {
    let result = companies;
    if (selectedIds) {
      result = result.filter(company => !selectedIds.includes(company.id));
    }
    return result;
  }, [companies, selectedIds]);

  const handleCompanyInputChange = useCallback(companyId => {
    false && console.log('handleCompanyInputChange', companyId);

    if (companyId && onChange) {
      const newValue = [...(selectedIds || []).filter(id => id !== companyId), companyId];
      false && console.log('newValue', newValue);
      onChange(newValue);
    }
    setCompanyInputKey(key => key + 1);
  }, [onChange, selectedIds]);

  const handleRemoveCompany = companyId => {
    false && console.log('handleRemoveCompany', companyId);
    if (onChange && selectedIds) {
      const newValue = selectedIds.filter(id => id !== companyId);
      false && console.log('newValue', newValue);
      onChange(newValue);
    }
    setCompanyInputKey(key => key + 1);
  };

  return (
    <Grid container direction="column">
      <CompanyInput
        key={companyInputKey}
        autoFocus={autoFocus || companyInputKey > 0}
        companies={availableCompanies}
        showDeactivated={showDeactivated}
        input={{
          value: null,
          onBlur,
          onChange: handleCompanyInputChange,
        }}
        meta={meta}
        onBlur={onBlur}
        disabled={disabled}
        label={label}
        loading={loading}
        placeholder={placeholder}
      />
      <div className={classes.chipContainer}>
        {selectedCompanies.map(company => (
          <Chip
            key={company.id}
            label={getCompanyName(company)}
            onDelete={() => handleRemoveCompany(company.id)}
          />
        ))}
      </div>
    </Grid>
  );
};

CompaniesInput.propTypes = {
  autoFocus: PropTypes.bool,
  companies: PropTypes.arrayOf(PropTypes.shape({
    deactivated: PropTypes.bool,
    id: PropTypes.number.isRequired,
    fullLegalCompanyName: PropTypes.string.isRequired,
    operatingName: PropTypes.string,
  })).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  showDeactivated: PropTypes.bool,
};

CompaniesInput.defaultProps = {
  showDeactivated: false,
};

export default CompaniesInput;
