import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/SaveAlt';
import Grid from '@material-ui/core/Grid';
import styles from './styles.js';

// components
import CheckBox from 'now-frontend-shared/components/Checkbox';
import MainSection from 'now-frontend-shared/layouts/AuthSections/MainSection';
import BaseInput from 'now-frontend-shared/components/inputs/BaseInput';
import DropZone, { dropZoneTypes } from 'now-frontend-shared/components/DropZone';
import SelectForIdValue from 'now-frontend-shared/components/Select/SelectForIdValue';

// layouts
import LabelLayout from 'now-frontend-shared/components/inputs/layouts/LabelLayout';

import { pureDecompile } from 'now-frontend-shared/helpers/structure';

// constants
import { acceptFileFormats } from 'now-shared/validation/listing-validation';
import { ListingDocumentType } from 'now-shared/enums/listing-document-type';
import { nonOpWellsDateTime } from 'now-shared/helpers/time-helpers';

import { toast } from 'react-toastify';
import { getDocumentExtensionFromS3Key } from 'now-frontend-shared/utils/helpers';
import { downloadFileAsFilename } from 'now-frontend-shared/utils/download-helpers';
import { getStorageItem } from 'now-frontend-shared/utils/storage';
import { apiAuthTokenQueryParamName } from 'now-shared/helpers/auth-helpers';
import axios from 'axios';
import queryString from 'query-string';
import { compose } from 'redux';
import { withStyles } from '@material-ui/styles';

export const downloadListingDocuments = async ({
  documentId,
  downloadUrl,
  filename,
  key,
  downloadAll,
  documents,
  listingId,
  filesPreparing,
  setFilesPreparing,
  onDownload,

}) => {
  const docId = downloadAll ? 'downloadAll' : documentId;

  let filePreparing = filesPreparing.find(file => file.docId === docId);
  if (filePreparing) {
    toast.warn(`Canceled download for ${filePreparing.label}`);
    filePreparing.source.cancel();
    return;
  }

  filePreparing = {
    docId,
    downloadAll,
    label: downloadAll ? 'ALL (AS ZIP)' : filename,
    source: axios.CancelToken.source(),
  };

  setFilesPreparing(files => [...files, filePreparing]);

  try {
    let url;

    if (downloadAll) {
      // TODO: [REFACTOR] currently `downloadAll` option is only supported in the main frontend, not in
      // admin-frontend, because it relies on code here that is specific to frontend-main.
      // Please refactor this function so that the code for running the download all as zip request can be
      // passed in as a function, or otherwise be made to work here on both admin and main sites.
      // TODO: refresh access token if needed, or logout if refresh token is expired
      const accessToken = JSON.parse(
        getStorageItem('accessToken', '{}'),
      );
      url = queryString.stringifyUrl(
        {
          url: `${process.env.REACT_APP_API_URL}/properties/${listingId}/documentsAsArchive`,
          query: {
            [apiAuthTokenQueryParamName]: accessToken,
          },
        },
        {
          arrayFormat: 'bracket',
        },
      );
    } else {
      // TODO: [UX] refresh the presigned download URL from the server if the current one has expired,
      // or to ensure that we have a fresh URL that has not expired.
      url = downloadUrl;

      // Add extension to filename if it is missing
      //
      // TODO: [CLEANUP][MIGRATION] data from older versions of the application did not include a
      // file extension in the filename, but newer versions do. If a migration is run to modify
      // older data to append the extension to the filename from the s3 key, this code here to modify
      // the filename can be removed.
      const fileMatch = filename.match(/(?<name>.*)(?<extension>\.[^.]+)$/);
      const name = fileMatch?.groups?.name ?? filename;
      const ext = fileMatch?.groups?.extension ?? getDocumentExtensionFromS3Key(key);
      filePreparing.label = `${name}${ext || ''}`;
    }

    /**
     * TODO: [MIGRATION][SIMPLIFY] downloading the file as a blob and changing its filename dynamically is
     * not necessary with uploads from newer versions of the application, because those uploads set the
     * filename in S3 as Content-Disposition meta data. So, the filename will use the one specified
     * by the user, not the S3 key.
     *
     * Downloading as a blob and then initiating a data URL download also prevents us from suggesting that
     * the file be opened inline (using Content-Disposition header) instead of downloaded directly.
     *
     * Data from older versions of the application may not have the Content-Disposition data set in S3.
     * So, a migration would be necessary to update the S3 meta data for each upload if we wanted to use
     * a simple href for this link.
     */
    const shouldDownloadToClientFirst = false;

    let didDownloadToClient = false;
    let wasCanceled = false;

    if (shouldDownloadToClientFirst) {
      toast.info(`Preparing download for ${filePreparing.label}`);

      try {
        await downloadFileAsFilename({
          downloadUrl: url,
          /**
           * Don't rename the file if it's for Download All (as Zip)
           */
          filename: filePreparing.downloadAll ? undefined : filePreparing.label,
          cancelToken: filePreparing.source.token,
          onDownloadProgress: progress => setFilesPreparing(
            files => files.map(
              file => (
                file.docId === docId
                  ? {
                    ...file,
                    loaded: progress.loaded,
                    total: progress.total,
                  }
                  : file
              ),
            ),
          ),
        });
        didDownloadToClient = true;
      } catch (e) {
        if (axios.isCancel(e)) {
          wasCanceled = true;
        } else {
          // eslint-disable-next-line no-console
          console.error(e);

          toast.warn(`Error preparing download for ${filePreparing.label}`);
        }
      }
    }

    if (!didDownloadToClient && !wasCanceled) {
      const a = document.createElement('a');
      a.href = url;
      // NOTE: this may not always work for renaming the downloaded file
      a.download = filePreparing.downloadAll ? true : filePreparing.label;
      a.target = '_blank';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

    // mark the file(s) as downloaded
    if (onDownload) {
      if (downloadAll) {
        documents.forEach(document => onDownload({ documentId: document.id }));
      } else {
        onDownload({ documentId });
      }
    }
  } catch (err) {
    toast.error(`Error downloading ${filePreparing.label}`);
    throw err;
  } finally {
    setFilesPreparing(files => files.filter(file => file.docId !== docId));
  }
};

const DropZoneName = {
  Required: 'required',
  Optional: 'optional',
};

const GeneralInformation = ({
  handleToggleCheckbox,
  AWSData,
  currentPropertyHasBeenApproved,
  currentPropertyDocuments,
  formValues,
  getLandingZones,
  getPreSignedUrls,
  preSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setBasinId,
  onSetFilesAreProcessing,
  setStateId,
  setUnloadedFilesExist,
  setUploading,
  states,
  classes,
  children,
  isAnonymous,
}) => {
  const dispatch = useDispatch();
  const decompiled = pureDecompile(states, formValues);

  const [filesPreparing, setFilesPreparing] = useState([]);
  const [dropZonesUploading, setDropZonesUploading] = useState([]);
  const [dropZonesProcessingFiles, setDropZonesProcessingFiles] = useState([]);
  const [dropZonesWithFileNotUploaded, setDropZonesWithFileNotUploaded] = useState([]);

  const isProcessingFiles = !!dropZonesProcessingFiles.length;
  const doesExistFileNotUploaded = !!dropZonesWithFileNotUploaded.length;
  const existsFileNotProcessedOrUploaded = isProcessingFiles || doesExistFileNotUploaded;

  const fileIsUploading = !!dropZonesUploading.length;

  useEffect(
    () => {
      dispatch(setUnloadedFilesExist(existsFileNotProcessedOrUploaded));
    },
    [dispatch, setUnloadedFilesExist, existsFileNotProcessedOrUploaded],
  );

  useEffect(
    () => {
      setUploading(fileIsUploading);
    },
    [setUploading, fileIsUploading],
  );

  useEffect(
    () => {
      if (onSetFilesAreProcessing) {
        onSetFilesAreProcessing(isProcessingFiles);
      }
    },
    [onSetFilesAreProcessing, isProcessingFiles],
  );

  const handleChangeState = ({ target: { value } }) => {
    dispatch(setStateId(value));
  };

  const handleChangeBasin = ({ target: { value } }) => {
    dispatch(setBasinId(value));
  };

  useEffect(() => {
    if (formValues.state && formValues.basin && formValues.county) {
      dispatch(getLandingZones(formValues.county));
    }
  }, [formValues.state, formValues.basin, formValues.county, getLandingZones, dispatch]);

  return (
    <div style={{ marginTop: '20px', width: '100%' }}>
      <div className={classes.detailsContainer}>
        <MainSection fullWidth heading="General Information">
          <hr className={classes.hr} />
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              {!currentPropertyHasBeenApproved && (
              <LabelLayout name="projectName" label="Project Name" space="small" isRequired>
                <Field name="projectName" component={BaseInput} props={{ placeholder: 'Project Name' }} />
              </LabelLayout>
              )}

              {!currentPropertyHasBeenApproved && (
              <LabelLayout name="state" label="State" space="small" isRequired>
                <Field
                  name="state"
                  component={SelectForIdValue}
                  props={{ options: decompiled.states || [] }}
                  onChange={handleChangeState}
                />
              </LabelLayout>
              )}

              {!currentPropertyHasBeenApproved && (
              <LabelLayout name="basin" label="Basin" space="small" isRequired>
                <Field
                  name="basin"
                  component={SelectForIdValue}
                  props={{ options: decompiled.basins || [] }}
                  onChange={handleChangeBasin}
                />
              </LabelLayout>
              )}

              {!currentPropertyHasBeenApproved && (
              <LabelLayout name="county" label="County" space="small" isRequired>
                <Field
                  name="county"
                  component={SelectForIdValue}
                  props={{ options: decompiled.counties || [] }}
                />
              </LabelLayout>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{ paddingLeft: '30px' }}>
              {children}
            </Grid>
          </Grid>
          <div style={{ width: '100%', margin: '20px 0' }}>
            <CheckBox
              name="anonymous"
              label="Seller to remain anonymous"
              checked={isAnonymous}
              onChange={handleToggleCheckbox}
            />
          </div>
        </MainSection>
      </div>
      <div className={classes.detailsContainer}>
        <MainSection fullWidth heading="Files">
          <hr className={classes.hr} />
          <Grid>
            <Grid style={{ display: 'flex', gap: '20px', paddingTop: '20px' }}>
              <div style={{ margin: '10px 0', width: '50%' }}>
                <p style={{ fontWeight: 'bold', marginBottom: '12px' }}>Closing Document *</p>
                <DropZone
                  AWSData={AWSData}
                  preSignedUrls={preSignedUrls}
                  savedDocuments={currentPropertyDocuments?.filter(doc => [
                    ListingDocumentType.NEW_LISTING_CLOSING_DOCUMENT,
                    ListingDocumentType.ADDITIONAL_LISTING_CLOSING_DOCUMENT,
                  ].includes(doc.type))}
                  setAWSData={setAWSData}
                  getPreSignedUrls={getPreSignedUrls}
                  removeAWSDataFile={removeAWSDataFile}
                  setUploading={flag => {
                    if (flag) {
                      setDropZonesUploading(dropZones => [...dropZones, DropZoneName.Required]);
                    } else {
                      setDropZonesUploading(dropZones => dropZones.filter(dropZone => dropZone !== DropZoneName.Required));
                    }
                  }}
                  onSetIsSomeFileNotUploaded={flag => {
                    if (flag) {
                      setDropZonesWithFileNotUploaded(dropZones => [...dropZones, DropZoneName.Required]);
                    } else {
                      setDropZonesWithFileNotUploaded(dropZones => dropZones.filter(dropZone => dropZone !== DropZoneName.Required));
                    }
                  }}
                  documentType={
                  currentPropertyHasBeenApproved
                    ? ListingDocumentType.ADDITIONAL_LISTING_CLOSING_DOCUMENT
                    : ListingDocumentType.NEW_LISTING_CLOSING_DOCUMENT
                }
                  accept={acceptFileFormats}
                  placeholderPrompt="Please upload"
                  placeholder="Closing document"
                  onSetIsProcessingFiles={flag => {
                    if (flag) {
                      setDropZonesProcessingFiles(dropZones => [...dropZones, DropZoneName.Required]);
                    } else {
                      setDropZonesProcessingFiles(dropZones => dropZones.filter(dropZone => dropZone !== DropZoneName.Required));
                    }
                  }}
                  canRemoveSavedFiles={!currentPropertyHasBeenApproved}
                  isSimpleView
                  disabled={isProcessingFiles}
                />
              </div>
              <div style={{ margin: '10px 0', width: '50%' }}>
                <p style={{ fontWeight: 'bold', marginBottom: '12px' }}>Other Documents</p>
                <DropZone
                  AWSData={AWSData}
                  preSignedUrls={preSignedUrls}
                  savedDocuments={currentPropertyDocuments?.filter(doc => [
                    ListingDocumentType.NEW_LISTING_OTHER_DOCUMENT,
                    ListingDocumentType.ADDITIONAL_LISTING_OTHER_DOCUMENT,
                  ].includes(doc.type))}
                  setAWSData={setAWSData}
                  getPreSignedUrls={getPreSignedUrls}
                  removeAWSDataFile={removeAWSDataFile}
                  setUploading={flag => {
                    if (flag) {
                      setDropZonesUploading(dropZones => [...dropZones, DropZoneName.Optional]);
                    } else {
                      setDropZonesUploading(dropZones => dropZones.filter(dropZone => dropZone !== DropZoneName.Optional));
                    }
                  }}
                  onSetIsSomeFileNotUploaded={flag => {
                    if (flag) {
                      setDropZonesWithFileNotUploaded(dropZones => [...dropZones, DropZoneName.Optional]);
                    } else {
                      setDropZonesWithFileNotUploaded(dropZones => dropZones.filter(dropZone => dropZone !== DropZoneName.Optional));
                    }
                  }}
                  documentType={
                  currentPropertyHasBeenApproved
                    ? ListingDocumentType.ADDITIONAL_LISTING_OTHER_DOCUMENT
                    : ListingDocumentType.NEW_LISTING_OTHER_DOCUMENT
                }
                  accept={acceptFileFormats}
                  placeholderPrompt="Please upload:"
                  placeholder="title, AFE, plats, JOA documents, lien releases, any other waivers/releases, completion details, and/or seller comments"
                  onSetIsProcessingFiles={flag => {
                    if (flag) {
                      setDropZonesProcessingFiles(dropZones => [...dropZones, DropZoneName.Optional]);
                    } else {
                      setDropZonesProcessingFiles(dropZones => dropZones.filter(dropZone => dropZone !== DropZoneName.Optional));
                    }
                  }}
                  canRemoveSavedFiles={!currentPropertyHasBeenApproved}
                  isSimpleView
                  disabled={isProcessingFiles}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Document Name</TableCell>
                    <TableCell>Uploaded At</TableCell>
                    <TableCell>Download</TableCell>
                    <TableCell>Seller Comments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {AWSData.map((file, index) => (
                    <TableRow key={file.key}>
                      <TableCell>{file.filename}</TableCell>
                      <TableCell>{file.createdAt ? nonOpWellsDateTime(new Date(file.createdAt)).toFormat('MM / dd / yyyy / t ZZZZ') : ''}</TableCell>
                      <TableCell>
                        {file.downloadUrl && (
                        <IconButton
                          onClick={() => downloadListingDocuments({
                            documentId: file.id,
                            downloadUrl: file.downloadUrl,
                            filename: file.filename,
                            key: file.key,
                            filesPreparing,
                            setFilesPreparing,
                          })}
                        >
                          <DownloadIcon />
                        </IconButton>
                        )}
                      </TableCell>
                      <TableCell>
                        {(
                      file.id
                        ? file.comment
                        : (
                          <Field
                            name={`documents[${index}].comment`}
                            component={BaseInput}
                            style={{ marginTop: 18 }}
                          />
                        )
                    )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {AWSData.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={4}>No documents</TableCell>
                  </TableRow>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </MainSection>
      </div>
    </div>
  );
};

export const generalInformationTypes = {
  AWSData: dropZoneTypes.AWSData,
  getPreSignedUrls: dropZoneTypes.getPreSignedUrls,
  preSignedUrls: dropZoneTypes.preSignedUrls,
  removeAWSDataFile: dropZoneTypes.removeAWSDataFile,
  setAWSData: dropZoneTypes.setAWSData,
  setUploading: dropZoneTypes.setUploading,
  setUnloadedFilesExist: PropTypes.func,
  currentPropertyDocuments: dropZoneTypes.savedDocuments,
  formValues: PropTypes.object,
  setBasinId: PropTypes.func,
  getLandingZones: PropTypes.func,
  setStateId: PropTypes.func,
  states: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, title: PropTypes.string })),
  currentPropertyHasBeenApproved: PropTypes.bool,
  onSetFilesAreProcessing: dropZoneTypes.onSetIsProcessingFiles,
};

GeneralInformation.propTypes = {
  AWSData: generalInformationTypes.AWSData.isRequired,
  getPreSignedUrls: generalInformationTypes.getPreSignedUrls.isRequired,
  preSignedUrls: generalInformationTypes.preSignedUrls,
  removeAWSDataFile: generalInformationTypes.removeAWSDataFile.isRequired,
  setAWSData: generalInformationTypes.setAWSData.isRequired,
  setUploading: generalInformationTypes.setUploading.isRequired,
  setUnloadedFilesExist: generalInformationTypes.setUnloadedFilesExist.isRequired,
  currentPropertyHasBeenApproved: generalInformationTypes.currentPropertyHasBeenApproved,
  currentPropertyDocuments: generalInformationTypes.currentPropertyDocuments,
  formValues: generalInformationTypes.formValues.isRequired,
  setBasinId: generalInformationTypes.setBasinId.isRequired,
  getLandingZones: generalInformationTypes.getLandingZones.isRequired,
  setStateId: generalInformationTypes.setStateId.isRequired,
  states: generalInformationTypes.states,
  onSetFilesAreProcessing: generalInformationTypes.onSetFilesAreProcessing,
};

GeneralInformation.defaultProps = {
  currentPropertyHasBeenApproved: undefined,
  currentPropertyDocuments: undefined,
  preSignedUrls: undefined,
  states: undefined,
  onSetFilesAreProcessing: undefined,
};

export default compose(withStyles(styles))(GeneralInformation);
