import React, {
  useEffect, useState, useCallback, useMemo, useRef,
} from 'react';
import { useDispatch, connect } from 'react-redux';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { isUsingEsriMaps } from 'now-frontend-shared/features/feature-flags';

// components
import Pagination from 'now-frontend-shared/components/Pagination';
import GoogleMap from 'now-frontend-shared/components/GoogleMap';
import Search from 'now-frontend-shared/components/inputs/Search';
import ControlledSelect from 'now-frontend-shared/components/Select/ControlledSelect';
import Spinner from 'now-frontend-shared/components/Spinner';
import EsriMap from 'now-frontend-shared/components/EsriMap';

// layouts
import PropertyLayout from 'layouts/PropertyLayout';

// helpers
import {
  createCustomQuery, DEFAULT_QUERY, coordinatesGenerator, removeQueryParam,
} from 'now-frontend-shared/utils/helpers';

// custom hooks
import useIsLaptop from 'now-frontend-shared/hooks/useIsLaptop';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Chip,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ViewListIcon from '@material-ui/icons/ViewList';
import MapIcon from '@material-ui/icons/Map';

// store
import {
  getProperties,
  getStatuses,
  getStates,
  clearState,
} from 'store/actions/propertiesActions';

// helpers
import decompile from 'now-frontend-shared/helpers/structure';
import styles from './styles';
import { userHasPermissionToEditListing } from 'now-shared/helpers/permissions';

const Properties = ({
  classes,
  history,
  propertiesList,
  propertiesMeta,
  statuses,
  states,
  pending,
  userCanCreateListing,
}) => {
  const [wells, setWells] = useState();
  const [mapIsOpen, setMapIsOpen] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState();
  const dispatch = useDispatch();
  const isLaptop = useIsLaptop();
  const queryParams = new URLSearchParams(history.location.search);
  const decompiled = decompile(states || [], queryParams);
  const sideNavRef = useRef();
  const sideNavContentRef = useRef();
  const filterButtonRef = useRef();
  const listingsFilterLabelRef = useRef();
  const listingsRef = useRef();
  const mapRef = useRef();
  const topLevelContainerRef = useRef();
  const [mapHeight, setMapHeight] = useState();
  const [navOpen, setNavOpen] = useState(false);
  const selectedStatus = useRef();
  const selectedState = useRef();
  const selectedBasin = useRef();
  const selectedCounty = useRef();
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [alignment, setAlignment] = React.useState('right');
  const topLevelContainer = topLevelContainerRef?.current;

  useEffect(() => {
    dispatch(getStatuses());
    dispatch(getStates());

    return () => dispatch(clearState());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch({ type: getProperties.type, payload: history.location.search || DEFAULT_QUERY });
  }, [dispatch, history, history.location.search]);

  useEffect(() => {
    if (propertiesList?.length > 0) {
      setWells(propertiesList[0].wells.map(well => ({ ...well, property: propertiesList[0] })));
      setSelectedProperty(propertiesList[0]);
    } else {
      setWells(null);
    }
  }, [setSelectedProperty, propertiesList]);

  const setQueryValue = (queryName, queryValue) => {
    if (queryName && queryValue) {
      const search = createCustomQuery(history, queryName, queryValue);
      history.push(search);
    } else if (queryName && !queryValue) {
      const search = removeQueryParam(history, queryName);
      history.push(search);
    } else {
      history.push(DEFAULT_QUERY);
    }
  };

  const setCurrentProperty = useCallback(
    currentProperty => {
      setMapIsOpen(true);
      setWells(currentProperty.wells.map(well => ({ ...well, property: currentProperty })));
      setSelectedProperty(currentProperty);
    },
    [setWells, setMapIsOpen, setSelectedProperty],
  );

  const coordinates = useMemo(() => coordinatesGenerator(wells), [wells]);

  // open the filter side nav by adjusting element's styles
  const openNav = () => {
    const sideNav = sideNavRef?.current;
    const sideNavContent = sideNavContentRef?.current;
    const filterButton = filterButtonRef?.current;
    const listingsFilterLabel = listingsFilterLabelRef?.current;

    // if filter side nav is closed, open it
    if (!navOpen) {
      setNavOpen(true);
      // slide listings label to the right
      listingsFilterLabel.style.setProperty('margin', '30px 0px 0px 410px');

      // slide the listings to the right
      sideNav.style.setProperty('width', '400px');

      // add the side nav content to the DOM
      sideNavContent.style.setProperty('visibility', 'visible');

      // make the side nav content visible
      sideNavContent.style.setProperty('opacity', 1);

      // bring filter to front to process mouse events because containers are overlayed in grid
      sideNavContent.style.setProperty('z-index', 3);

      // make filter button invisible
      filterButton.style.setProperty('opacity', 0);
    }
  };

  // open the filter side nav by adjusting element's styles
  const closeNav = () => {
    const sideNav = sideNavRef?.current;
    const sideNavContent = sideNavContentRef?.current;
    const filterButton = filterButtonRef?.current;
    const listingsFilterLabel = listingsFilterLabelRef?.current;

    // if filter side nav is open, close it
    if (navOpen) {
      setNavOpen(false);
      // slide listings label to the right (its original position) by removing margin added when opened
      listingsFilterLabel.style.setProperty('margin', '30px 0px 0px 0px');

      // slide the listings to the left (its original position) by removing width added whern opened
      sideNav.style.setProperty('width', '0px');

      // make the side nav content invisible
      sideNavContent.style.setProperty('opacity', 0);

      // remove the side nav content from the DOM after it fades to prevent it from picking up any mouse interactions
      setTimeout(() => {
        sideNavContent.style.setProperty('visibility', 'hidden');
      }, 500);

      // push filter to back to allow listings container to process mouse events because containers are overlayed in grid
      sideNavContent.style.setProperty('z-index', 1);

      // make filter button visible
      filterButton.style.setProperty('opacity', 1);
    }
  };

  // go to 'create-listing' page
  const goToCreatePage = () => {
    history.push('/create-listing');
  };

  useEffect(() => {
    if (topLevelContainer) {
      // set height of map to the height of 10 listings (the max allowed on a single page)
      setMapHeight('1110px');
    }
  }, [topLevelContainer]);

  const handleAccordionChange = panel => (event, isExpanded) => {
    setAccordionExpanded(isExpanded ? panel : false);
  };

  const handleToggleButtonAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);

    const listings = listingsRef.current;
    const map = mapRef?.current;
    if (newAlignment === 'left') {
      // expand listings, hide map
      listings.style.setProperty('grid-column-end', 4);
      map.style.setProperty('grid-column-start', 4);
    } else {
      // shrink listings, show map
      listings.style.setProperty('grid-column-end', 3);
      map.style.setProperty('grid-column-start', 3);
    }
  };

  if (!propertiesList) return <Spinner wrapped />;

  return (
    <>
      <Box sx={{ display: 'grid', margin: '0px 0px 0px 0px', maxWidth: '100%' }}>
        {/* side nav content */}
        <Box
          ref={sideNavContentRef}
          className={classes.sideNavContent}
        >
          <Box sx={{
            justifySelf: 'end',
          }}
          >
            <CloseIcon style={{ cursor: 'hand', marginRight: '10px' }} onClick={closeNav} />
          </Box>
          <Box sx={{ paddingBottom: '14px' }}>Filters:</Box>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              Property Name/ID
            </AccordionSummary>
            <AccordionDetails>
              <Search setQueryValue={setQueryValue} placeholder="" />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} expanded={accordionExpanded === 'status'} onChange={handleAccordionChange('status')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              Status
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.statusFilter}>
                <FormControlLabel
                  label="All"
                  checked={!selectedStatus.current}
                  control={(
                    <Checkbox
                      onChange={() => { selectedStatus.current = undefined; setQueryValue('status'); }}
                      className={classes.checkbox}
                    />
                  )}
                />
                {statuses.map((status, index) => (
                  <FormControlLabel
                    key={index}
                    label={status.title}
                    checked={status.id === selectedStatus.current}
                    control={(
                      <Checkbox
                        onChange={() => { selectedStatus.current = status.id; setQueryValue('status', status.id); }}
                        className={classes.checkbox}
                      />
                    )}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} expanded={accordionExpanded === 'states'} onChange={handleAccordionChange('states')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              State
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.filters}>
                <FormControlLabel
                  disabled={(selectedBasin.current || selectedCounty.current)}
                  label="All"
                  checked={!selectedState.current}
                  control={(
                    <Checkbox
                      onChange={() => { selectedState.current = undefined; setQueryValue('state'); }}
                      className={classes.checkbox}
                    />
                  )}
                />
                {decompiled.states.map((state, index) => (
                  <FormControlLabel
                    key={index}
                    label={state.title}
                    checked={state.id === selectedState.current}
                    control={(
                      <Checkbox
                        onChange={() => { selectedState.current = state.id; setQueryValue('state', state.id); }}
                        className={classes.checkbox}
                      />
                    )}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} expanded={accordionExpanded === 'basins'} onChange={handleAccordionChange('basins')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              Basin
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.filters}>
                <FormControlLabel
                  disabled={selectedCounty.current}
                  label="All"
                  checked={!selectedBasin.current}
                  control={(
                    <Checkbox
                      onChange={() => { selectedBasin.current = undefined; setQueryValue('basin'); }}
                      className={classes.checkbox}
                    />
                  )}
                />
                {decompiled.basins.map((basin, index) => (
                  <FormControlLabel
                    key={index}
                    label={basin.title}
                    checked={basin.id === selectedBasin.current}
                    control={(
                      <Checkbox
                        onChange={() => { selectedBasin.current = basin.id; setQueryValue('basin', basin.id); }}
                        className={classes.checkbox}
                      />
                    )}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} expanded={accordionExpanded === 'counties'} onChange={handleAccordionChange('counties')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              County
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.filters}>
                <FormControlLabel
                  label="All"
                  checked={!selectedCounty.current}
                  control={(
                    <Checkbox
                      onChange={() => { selectedCounty.current = undefined; setQueryValue('county', undefined); }}
                      className={classes.checkbox}
                    />
                  )}
                />
                {decompiled.counties.map((county, index) => (
                  <FormControlLabel
                    key={index}
                    label={county.title}
                    checked={county.id === selectedCounty.current}
                    control={(
                      <Checkbox
                        onChange={() => { selectedCounty.current = county.id; setQueryValue('county', county.id); }}
                        className={classes.checkbox}
                      />
                    )}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* listings label and filter chips */}
        <Box
          ref={listingsFilterLabelRef}
          className={classes.labelAndChips}
        >
          <span style={{ fontSize: '24px', padding: '0px 10px 0px 10px' }}>Listings</span>
          {selectedState?.current
            && (
            <Chip
              label={
              decompiled.states.filter(state => state.id === selectedState.current)[0]?.title
            }
              variant="outlined"
              onDelete={
                () => {
                  selectedState.current = undefined;
                  setQueryValue('state');
                }
}
              style={{ margin: '0px 5px' }}
            />
            )}
          {selectedCounty.current
            && (
            <Chip
              label={
                decompiled.counties.filter(county => county.id === selectedCounty.current)[0]?.title
              }
              variant="outlined"
              onDelete={
                () => {
                  selectedCounty.current = undefined;
                  setQueryValue('county');
                }
}
              style={{ margin: '0px 5px' }}
            />
            )}
          {selectedBasin.current
            && (
            <Chip
              label={
                decompiled.basins.filter(basin => basin.id === selectedBasin.current)[0]?.title
              }
              variant="outlined"
              onDelete={
                () => {
                  selectedBasin.current = undefined;
                  setQueryValue('basin');
                }
}
              style={{ margin: '0px 5px' }}
            />
            )}
          {selectedStatus.current
            && (
            <Chip
              label={
                statuses.filter(status => status.id === selectedStatus.current)[0]?.title
              }
              variant="outlined"
              onDelete={
                () => {
                  selectedStatus.current = undefined;
                  setQueryValue('status');
                }
}
              style={{ margin: '0px 5px' }}
            />
            )}
        </Box>

        {/* map and listings view toggle */}
        <Box
          className={classes.mapToggle}
        >
          {userCanCreateListing && (
            <Button
              className={classes.createListingButton}
              data-cy="createListingButton"
              variant="contained"
              onClick={goToCreatePage}
            >
              Create new listing
            </Button>
          )}

          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleToggleButtonAlignment}
          >
            <ToggleButton value="left" aria-label="left aligned">
              <ViewListIcon />
            </ToggleButton>
            <ToggleButton value="right" aria-label="right aligned">
              <MapIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* listings and map */}
        <Box
          ref={topLevelContainerRef}
          className={classes.listingsAndMap}
        >
          <Box ref={sideNavRef} className={classes.sidenav} />
          <Box ref={listingsRef} sx={{ gridColumnStart: 2, gridColumnEnd: 3 }}>
            {propertiesList?.length === 0 ? (
              <>
                <Grid justify="center">
                  <span className={classes.message}>Sorry, there are no listings available at this time.</span>
                </Grid>
                <Box
                  ref={filterButtonRef}
                  className={classes.filterButton}
                >
                  {!navOpen && (
                    <Button
                      className={classes.filterButtonStyle}
                      variant="contained"
                      onClick={openNav}
                    >
                      Filter
                    </Button>
                  )}
                </Box>
              </>
            ) : (
              <>
                <Box sx={{
                  display: 'grid', gridAutoFlow: 'column', padding: '0px 10px', width: '100%',
                }}
                >
                  <Box
                    ref={filterButtonRef}
                    className={classes.filterButton}
                  >
                    <Button
                      className={classes.filterButtonStyle}
                      variant="contained"
                      onClick={openNav}
                    >
                      Filter
                    </Button>
                  </Box>
                  <Box sx={{ width: '175px', justifySelf: 'end' }}>
                    <ControlledSelect
                      options={[
                        { title: 'Net AFE Amount', id: 'netAfe' },
                        { title: 'Minimum Bid', id: 'minimumBid' },
                        { title: 'Minimum Carry', id: 'minimumBidCarry' },
                        { title: 'Auction End', id: 'endTime' },
                      ]}
                      placeholder="Sort by"
                      name="order"
                      setQueryValue={setQueryValue}
                    />
                  </Box>
                </Box>
                <Box className={classes.listings}>
                  {propertiesList?.map((property, index) => (
                    <PropertyLayout
                      key={index}
                      property={property}
                      setCurrentProperty={setCurrentProperty}
                      withoutHandleClick={isLaptop}
                      isSelected={selectedProperty?.id === property.id && !isLaptop}
                    />
                  ))}
                </Box>
              </>
            )}
          </Box>
          <Box ref={mapRef} className={classes.map}>
            {wells && mapIsOpen && (
              isUsingEsriMaps()
                ? (
                  <div style={{ position: 'relative', height: mapHeight }}>
                    <EsriMap wells={wells} />
                  </div>
                )
                : (
                  <GoogleMap
                    wells={coordinates}
                    containerStyle={{
                      position: 'relative',
                      height: `${mapHeight}`,
                    }}
                  />
                )
            )}
          </Box>
        </Box>

        {pending && <Spinner backdrop />}
        {propertiesMeta?.totalItems > 10 && (
          <Grid container justifyContent="center">
            <Pagination count={propertiesMeta.totalPages} itemsPerPage={10} />
          </Grid>
        )}
      </Box>
    </>
  );
};

Properties.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  userCanCreateListing: PropTypes.bool.isRequired,
};

export default compose(
  connect(({ properties, auth }) => ({
    propertiesList: properties.propertiesList?.items,
    propertiesMeta: properties.propertiesList?.meta,
    statuses: properties.statuses || [],
    states: properties.states,
    pending: properties.pending,
    userCanCreateListing: (
      !!auth.user.company?.approved
      && !!auth.user.company?.active
      && auth.user.company.hasSellerAgreement
      && userHasPermissionToEditListing(auth.user)
    ),
  })),
  withStyles(styles),
)(Properties);
