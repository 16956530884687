import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import React, { useCallback, useEffect } from 'react';
import styles from './styles.js';

// components
import ListingForm from 'now-frontend-shared/components/ListingForm';

// layouts
import HeadSection from 'now-frontend-shared/layouts/AuthSections/HeadSection';

// store
import {
  clearAWSData,
  createProperty,
  getBasins,
  getCounties,
  getLandingZones,
  getPreSignedUrls,
  getStates,
  removeAWSDataFile,
  setAWSData,
  setBasinId,
  setStateId,
  setUnloadedFilesExist,
} from 'store/actions/createPropertyActions';
import { getAllCompanies } from 'store/actions/companiesActions';
import { refreshTokens } from 'utils/apiMethods';
import Header from 'components/Header';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const formName = 'createListing';

const CreateProperty = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStates());
    dispatch(getAllCompanies());
  }, [dispatch]);

  const onSubmit = useCallback(formData => {
    dispatch(createProperty({ formName, ...formData }));
  }, [dispatch]);

  return (
    <>
      <Header />
      <Grid container className={props.classes.wrapper}>
        <ListingForm
          formName={formName}
          isForCreate
          onSubmit={onSubmit}
          keepSessionAlive={refreshTokens}
          { ...props }
        >
          <HeadSection heading="Create a Listing" path="/my-listings" />
        </ListingForm>
      </Grid>
    </>
  );
};

CreateProperty.propTypes = {
  // TODO: [TYPE] specify missing types
};

const mapStateToProps = ({ companies, createProperty, auth }) => ({
  AWSData: createProperty.AWSData,
  basins: createProperty.basins,
  clearAWSData,
  companies: companies.companies,
  counties: createProperty.counties,
  getLandingZones,
  getBasins,
  getCounties,
  getPreSignedUrls,
  isLoadingCompanies: companies.companiesLoading,
  landingZones: createProperty.landingZones,
  preSignedUrls: createProperty.preSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setBasinId,
  setStateId,
  setUnloadedFilesExist,
  states: createProperty.states,
  unloadedFilesExist: createProperty.unloadedFilesExist,
  listingOwner: auth.user,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(CreateProperty);
