export const teamMemberNames = {
  russell: 'Russell McGowan',
  james: 'James Magee',
  josh: 'Josh Hack',
};

export const teamMemberContact = {
  russell: 'russ@nonopwells.com',
  james: 'james@nonopwells.com',
  josh: 'josh@nonopwells.com',
};

export const teamMemberBio = {
  jamesBio: 'James is a third-generation oil and gas professional and has spent over a decade working across multiple industry verticals, both in the lower 48 and Canada. Prior to co-founding NOW, he was a member of the Corporate Development Team at a public upstream company, focused on M&A and A&D. James’ other experience includes helping to manage an oil and gas public equities investment portfolio, as well as working in equity research and for a Canadian OFS company.',
  russellBio: 'Prior to co-founding NonOpWells, Russ served in various management roles for private E&P operators. At Torrent Oil, Russ served as a Vice President leading finance and business development efforts. Prior to his time at Torrent, Russ served as a financial consultant for both public and private E&P companies, assembled and led a Midland Basin-focused private E&P company, and ran finance and business development for a private operator in East Texas. Russ began his career in investment banking advising on capital markets, M&A, and A&D transactions.',
  joshBio: 'Josh joined NonOpWells in 2022. Prior to NOW, he served in various commercial and non-operated leadership roles at public E&P companies. He has facilitated the participation in over $1B in non-operated assets in multiple basins. Most recently, he served as a Corporate Development Land Advisor for a public E&P company. Josh has executed nearly $2B in asset-level transactions, including the purchase and sale of wellbore-only interests and non-operated producing assets as both Buyer and Seller. Josh is a Certified Professional Landman and an avid sportsman.',
};
