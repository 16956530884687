import {
  all, fork, take, takeLatest, call, put,
} from 'redux-saga/effects';

// actions
import { getHistoricalListings, getPropertiesMarkers } from '../actions/mapActions';

// api methods
import Api from 'api/map';

function* ensureGetActivePropertiesCoordinates() {
  try {
    const { data } = yield call(Api.getActivePropertiesCoordinates);
    yield put({ type: getPropertiesMarkers.success, payload: data });
  } catch (err) {
    yield put({ type: getPropertiesMarkers.failure, err });
  }
}

function* watchGetActivePropertiesCoordinates() {
  yield takeLatest(getPropertiesMarkers.type, ensureGetActivePropertiesCoordinates);
  yield take(getPropertiesMarkers.success);
}

function* ensureGetHistoricalListings() {
  try {
    const { data } = yield call(Api.getHistoricalListings);
    yield put({ type: getHistoricalListings.success, payload: data });
  } catch (err) {
    yield put({ type: getHistoricalListings.failure, err });
  }
}

function* watchGetHistoricalListings() {
  yield takeLatest(getHistoricalListings.type, ensureGetHistoricalListings);
  yield take(getHistoricalListings.success);
}

export default function* mapViewSagas() {
  yield all([
    fork(watchGetActivePropertiesCoordinates),
    fork(watchGetHistoricalListings),
  ]);
}
