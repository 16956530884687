import React, { useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { StyledTextField } from '../StyledTextField/styledTextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';

// styles
import styles from '../styles';

import { getCompanyName } from 'now-shared/helpers/company-helpers';

const useStyles = makeStyles({
  /**
   * these styles are intended to make the TextField closely match the look of BaseInput
   */
  helperText: {
    margin: 0,
    padding: '2px 0 0 6px',
    fontSize: '12px',
    lineHeight: '14px',
    fontFamily: 'Roboto, sans-serif',
    color: '#EB5757 !important',
    fontWeight: 'inherit',
    letterSpacing: 'inherit',
    boxSizing: 'border-box',
    display: 'flex',
    minHeight: '18px',
    alignItems: 'center',
    outline: 0,
    borderColor: 'init',
  },
  option: {
    fontSize: 16,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const CompanyInput = ({
  autoFocus,
  classes,
  companies,
  disabled,
  forUserRegistration,
  input,
  inputRef,
  label,
  loading,
  meta,
  placeholder,
  showDeactivated,
}) => {
  const myClasses = useStyles();

  const {
    onChange,
    onBlur,
    value: selectedId,
  } = input;

  false && console.log('typeof selectedId', typeof selectedId);
  false && console.log('selectedId', selectedId);
  false && console.log('meta', meta);

  const error = (meta.touched && meta.error) || '';
  false && console.log('error', error);

  const filteredCompanies = useMemo(() => {
    let result = companies;
    if (!showDeactivated) {
      result = result.filter(company => !company.deactivated);
    }
    return result;
  }, [companies, showDeactivated]);

  // TODO: [UX][INTEGRITY] handle showing a selected company that is not in the filteredCompanies list
  const value = useMemo(() => filteredCompanies.find(company => company.id === selectedId) || null, [filteredCompanies, selectedId]);

  const isMissingSelected = selectedId && !value;

  false && console.log('value', value);

  const handleChange = useCallback((_event, newValue) => {
    false && console.log('handleChange', newValue);

    if (onChange) {
      const newSelectedId = newValue?.id;
      false && console.log('newSelectedId', newSelectedId);
      onChange(newSelectedId);
    }
  }, [onChange]);

  const handleBlur = useCallback(() => {
    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  return (
    <Grid container direction="column">
      <Autocomplete
        loading={loading}
        options={filteredCompanies}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        classes={{
          inputRoot: classes.fieldStyles,
          option: myClasses.option,
        }}
        autoHighlight
        blurOnSelect
        clearOnEscape
        disableListWrap
        disabled={disabled}
        openOnFocus={false}
        getOptionLabel={getCompanyName}
        renderOption={option => (
          <>
            <span>{getCompanyName(option)}</span>
          </>
        )}
        renderInput={({ ...params }) => (
          <StyledTextField
            {...params}
            autoFocus={autoFocus}
            label={label}
            placeholder={
              (loading && selectedId)
                ? 'Loading...'
                : (!loading && isMissingSelected)
                  ? '<company not found>'
                  : placeholder
            }
            helperText={error || '\u00a0'}
            margin="none"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              /**
               * disables autocomplete and autofill
               */
              autoComplete: 'new-password',
              style: {
                padding: '4px 7px',
              },
            }}
            inputRef={inputRef}
            FormHelperTextProps={{
              className: myClasses.helperText,
            }}
          />
        )}
      />
      {forUserRegistration && !value && (
        <div>
          Don't see your company in the list?
          {' '}
          <NavLink to="/contact-us">
            Contact us
          </NavLink>
          {' '}
          to add it.
        </div>
      )}
    </Grid>
  );
};

CompanyInput.propTypes = {
  autoFocus: PropTypes.bool,
  companies: PropTypes.arrayOf(PropTypes.shape({
    deactivated: PropTypes.bool,
    id: PropTypes.number.isRequired,
    fullLegalCompanyName: PropTypes.string.isRequired,
    operatingName: PropTypes.string,
  })).isRequired,
  disabled: PropTypes.bool,
  forUserRegistration: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  inputRef: PropTypes.func,
  showDeactivated: PropTypes.bool,
};

CompanyInput.defaultProps = {
  showDeactivated: false,
};

export default compose(withStyles(styles))(CompanyInput);
