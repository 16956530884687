import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from '@material-ui/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import { getListingAnalyticsGraphData } from '../../utils/helpers';

export const AnalyticsBarGraph = ({
  filterByCompany, analytics, dateRange, setFilterByCompany, setSelectionModel,
}) => {
  const data = getListingAnalyticsGraphData({ filterByCompany, analytics, dateRange });
  return (
    <>
      <BarChart
        width={1200}
        height={400}
        data={data}
        margin={{
          top: 50,
          bottom: 50,
        }}
        barSize={40}
      >
        <XAxis
          dataKey="date"
          tickFormatter={
            d => {
              const formattedDate = moment(d).format(dateRange.usingWeeks ? 'dd MM/DD' : 'MM/DD');
              return formattedDate;
            }
          }
          scale="point"
          padding={{ left: 40, right: 40 }}
        />
        <YAxis
          allowDecimals={false}
        />
        <Tooltip
          labelFormatter={
            d => {
              const formattedDate = moment(d).format('ddd MM/DD/YY');
              return dateRange.usingWeeks
                ? `Week starting on ${formattedDate}`
                : formattedDate;
            }
          }
        />
        <Legend />
        <Bar dataKey="totalViews" fill="#7890A6" name="Total Views" />
        <Bar dataKey="totalDownloads" fill="#4b6377" name="Total Downloads" />
      </BarChart>
      {filterByCompany && (
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'end',
        marginBottom: '20px',
      }}
      >
        <Button
          variant="contained"
          onClick={() => {
            setFilterByCompany(null);
            setSelectionModel([]);
          }}
        >
          Clear Company
        </Button>
      </div>
      )}
    </>
  );
};

AnalyticsBarGraph.propTypes = {
  filterByCompany: PropTypes.objectOf(PropTypes.string).isRequired,
  setFilterByCompany: PropTypes.func.isRequired,
  setSelectionModel: PropTypes.func.isRequired,
  analytics: PropTypes.objectOf(PropTypes.string).isRequired,
  dateRange: PropTypes.shape({
    usingWeeks: PropTypes.bool,
    // TODO: fill in additional fields
  }).isRequired,
};
