import React, {
  useCallback, useMemo, memo, useRef, useEffect,
} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

import StyledLink from '../../pages/Profile/components/StyledLink';

// helpers
import { formatNumberToCurrency } from 'now-frontend-shared/utils/helpers';
import { computeWellsGrossAfe } from 'now-shared/helpers/listing-helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// styles
import styles from './styles';
import './styles.css';

const PropertyLayout = ({
  classes, property, setCurrentProperty, withoutHandleClick,
}) => {
  const handleViewClick = useCallback(() => setCurrentProperty(property), [setCurrentProperty, property]);

  const totalWellGrossAfe = useMemo(() => {
    const totalAfe = computeWellsGrossAfe(property?.wells);
    return formatNumberToCurrency(totalAfe, true);
  }, [property]);
  const netAfe = useMemo(() => formatNumberToCurrency(property?.netAfe, true), [property]);

  const topLevelContainerRef = useRef();
  const widthBreakPoint = 625;

  const resizeGridItemTwo = () => {
    // decide if the total width is wide enough to show view link in grid-template-column five
    // dynamically query and update the css property via css variable
    const componentWidth = topLevelContainerRef?.current?.clientWidth;
    const viewLinkDisplayValue = getComputedStyle(topLevelContainerRef?.current).getPropertyValue('--show-view-link').trim();

    // if wide enough show view link by setting display css property to initial
    // add the grid column (and by association its gap) back as well
    if (viewLinkDisplayValue === 'none'
      && componentWidth > widthBreakPoint) {
      const element = topLevelContainerRef?.current;
      element.style.setProperty('--show-view-link', 'initial');
      const gridColumns = 'max-content minmax(200px, 1fr) max-content max-content max-content 0px';
      element.style.setProperty('--container-grid-columns', gridColumns);
    } else if (viewLinkDisplayValue === ''
      && componentWidth <= widthBreakPoint) {
      // else if narrow enough hide view link by setting display css property to none
      // remove the grid column (and by association its gap) as well
      const element = topLevelContainerRef?.current;
      element.style.setProperty('--show-view-link', 'none');
      const gridColumns = 'max-content minmax(200px, 1fr) max-content max-content max-content';
      element.style.setProperty('--container-grid-columns', gridColumns);
    }
  };

  useEffect(() => {
    resizeGridItemTwo();
  }, []);

  return (
    <>
      <Box
        ref={topLevelContainerRef}
        className={cx('container', classes.card)}
        sx={{ width: '100%' }}
        onClick={withoutHandleClick ? null : handleViewClick}
      >
        <Box
          className={cx(classes.status, {
            [classes.active]: ['active'].includes(property.status.title),
            [classes.closed]: !['active'].includes(property.status.title),
          })}
          sx={{ gridColumnStart: 1, gridColumnEnd: 2 }}
        >
          {property.status.title}
        </Box>
        <Box sx={{
          gridColumnStart: 2,
          gridColumnEnd: 3,
          justifySelf: 'start',
          width: '100%',
        }}
        >
          <Box
            className={classes.topRow}
            sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
          >
            <Link
              to={{ pathname: `/listings/${property.id}` }}
              className={classes.titleLink}
            >
              {property.projectName}
              {' '}
              (
              {property.wells.length}
              {' '}
              {property.wells.length > 1 ? 'wells' : 'well'}
              )
            </Link>
          </Box>
          <Box
            className={classes.bottomRow}
            sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
          >
            {property.county.title}
            {', '}
            {property.state.title}
            {', '}
            {property.basin.title}
          </Box>
        </Box>
        <Box sx={{ gridColumnStart: 3, gridColumnEnd: 5, textAlign: 'center' }}>
          <Box className={classes.topRow}>
            {totalWellGrossAfe}
          </Box>
          <Box className={classes.bottomRow}>
            Gross AFE Amount
          </Box>
        </Box>
        <Box sx={{ gridColumnStart: 5, gridColumnEnd: 7, textAlign: 'center' }}>
          <Box className={classes.topRow}>
            {netAfe}
          </Box>
          <Box className={classes.bottomRow}>
            Total Well Net AFE
          </Box>
        </Box>
        <Box className="linkClass" sx={{ gridColumnStart: 9, gridColumnEnd: 10, textAlign: 'center' }}>
          <StyledLink
            path={`/listings/${property.id}`}
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '15px',
              lineHeight: '26px',
              letterSpacing: '0.46px',
              textDecorationLine: 'underline',
              textTransform: 'uppercase',
              color: '#7890A6',
            }}
          >
            view
          </StyledLink>
        </Box>
      </Box>
    </>
  );
};

PropertyLayout.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setCurrentProperty: PropTypes.func,
};

export default compose(withStyles(styles), memo)(PropertyLayout);
