import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// styles
import styles from './styles';

const TeamBioDropdown = ({
  classes,
  photoSource,
  memberContact,
  memberName,
  memberBio,
}) => (
  <Grid container direction="column" justify="center" alignItems="center" className={classes.wrapper}>
    <Grid container item className={classes.singleBioContainer}>
      <div className={classes.bioCircle}>
        <img src={photoSource} alt="Team Member Headshot" />
      </div>
      <Box className={classes.bioSection}>
        <span className={classes.teamMemberName}>
          {memberName}
        </span>
        <Accordion className={classes.bioAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon data-cy="expandButton" />}
            className={classes.viewMoreIcon}
          />
          <AccordionDetails>
            <Box className={classes.bioText}>
              <span>{memberBio}</span>
              <div className={classes.contactInfo}>
                <span style={{ fontWeight: 'bold' }}>Contact: </span>
                <span>{memberContact}</span>
              </div>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Grid>
  </Grid>
);

TeamBioDropdown.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  memberName: PropTypes.string.isRequired,
  photoSource: PropTypes.string.isRequired,
  memberBio: PropTypes.string.isRequired,
  memberContact: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), memo)(TeamBioDropdown);
