export default {
  fieldStyles: {
    width: '100%',
    padding: '6px 8px',
    fontWeight: '500',
    fontSize: '12px',
    border: '1px solid rgba(0,0,0,0.5)',
    borderRadius: '4px',
    outline: 'none',
    textAlign: 'right',
    color: '#000000',
    background: '#fff',

    '&:hover': {
      border: '1px solid rgba(0,0,0,1)',
    },

    '&:focus': {
      border: '1px solid #3f51b5',
      boxShadow: '0px 0px 0px 1px #3f51b5 inset',
    },
    '&::placeholder': {
      fontWeight: '400',
      fontSize: '10px',
    },
  },
  disabled: {
    pointerEvents: 'none',
    userSelect: 'none',
    color: '#000000',
    opacity: '1',
  },
};
