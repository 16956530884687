import { Grid } from '@material-ui/core';
import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { eightsCalculations, getWellBidDetailById } from '../helpers';
import {
  formatNumberToCurrency,
} from 'now-frontend-shared/utils/helpers';
import {
  computeAcquiredRetainedInterest,
  computeImpliedPrice,
} from 'now-shared/helpers/bid-helpers';

const WellListingTable = props => {
  const { classes, property, bid } = props;
  const bidType = useMemo(() => (bid.wellBids[0].carryPercentage ? 'Carry' : 'Cash'), [bid]);
  const bidedWells = useMemo(
    () => property.wells.filter(w => bid.wellBids.find(wb => w.id === wb.wellId)),
    [property, bid],
  );

  return (
    <>
      <div
        className={classes.wellsContainer}
        style={{ gridTemplateColumns: 'repeat(13, 1fr)', width: '100%' }}
      >
        <Grid className={classes.bottomSectionHeading}>
          <div>Name</div>
        </Grid>
        <Grid className={classes.bottomSectionHeading}>
          <div>API Number</div>
        </Grid>
        <Grid className={classes.bottomSectionHeading}>
          <div>Landing Zone</div>
        </Grid>
        <Grid className={classes.bottomSectionHeading}>
          <div>Net Working Interest</div>
        </Grid>
        <Grid className={classes.bottomSectionHeading}>
          <div>8/8ths Revenue Interest</div>
        </Grid>
        <Grid className={classes.bottomSectionHeading}>
          <div>Net Revenue Interest</div>
        </Grid>
        <Grid className={classes.bottomSectionHeading}>
          <div>Well Gross AFE</div>
        </Grid>
        <Grid className={classes.bottomSectionHeading}>
          <div>Well Net AFE</div>
        </Grid>
        <Grid className={`${classes.bottomSectionHeading} ${classes.darkerTile}`} style={{ borderLeft: 'solid 1px #000000' }}>
          <div>{bidType === 'Cash' ? 'Cash Bid' : 'Carry Bid'}</div>
        </Grid>
        <Grid className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}>
          <div>Buyer Assigned Net WI</div>
        </Grid>
        <Grid className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}>
          <div>Seller Retained Net WI</div>
        </Grid>
        <Grid className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}>
          <div>Closing Fee</div>
        </Grid>
        <Grid className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}>
          <div>{bidType === 'Cash' ? 'Total Bid' : 'Implied Price'}</div>
        </Grid>
      </div>
      {bidedWells.map(item => {
        const wellBid = getWellBidDetailById(item.id, bid);
        const acquiredPercentage = computeAcquiredRetainedInterest({
          bidCarryPercentage: wellBid.carryPercentage,
          sellerOriginalOwnershipPercentage:
            item.workingInterestPercentage,
        });
        return (
          <React.Fragment key={item.id}>
            <div
              className={classes.wellsContainer}
              style={{ gridTemplateColumns: 'repeat(13, 1fr)' }}
            >
              <Grid item className={classes.bottomSectionHeading}>
                <div className={classes.bottomSectionDescription}>
                  {item.wellName}
                </div>
              </Grid>
              <Grid item className={classes.bottomSectionHeading}>
                <div className={classes.bottomSectionDescription}>
                  {item.wellAPINumber || '-'}
                </div>
              </Grid>
              <Grid item className={classes.bottomSectionHeading}>
                <div className={classes.bottomSectionDescription}>
                  {item.landingZone.title}
                </div>
              </Grid>
              <Grid item className={classes.bottomSectionHeading}>
                <div className={classes.bottomSectionDescription}>
                  {item.workingInterestPercentage || 0}
                  %
                </div>
              </Grid>
              <Grid item className={classes.bottomSectionHeading}>
                <div className={classes.bottomSectionDescription}>
                  {`${eightsCalculations(item)}%`}
                </div>
              </Grid>
              <Grid item className={classes.bottomSectionHeading}>
                <div className={classes.bottomSectionDescription}>
                  {item.netRevenueInterestNumber || 0}
                  %
                </div>
              </Grid>
              <Grid item className={classes.bottomSectionHeading}>
                <div className={classes.bottomSectionDescription}>
                  {formatNumberToCurrency(item.wellGrossAfe)}
                </div>
              </Grid>
              <Grid item className={classes.bottomSectionHeading}>
                <div className={classes.bottomSectionDescription}>
                  {formatNumberToCurrency(item.wellNetAFE)}
                </div>
              </Grid>
              <Grid item className={`${classes.bottomSectionHeading} ${classes.darkerTile}`} style={{ borderLeft: 'solid 1px #000000' }}>
                <div className={classes.bottomSectionDescription}>
                  {bidType === 'Cash' ? `$ ${wellBid.amount}` : `${wellBid.carryPercentage}%`}
                </div>
              </Grid>
              <Grid item className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}>
                <div className={classes.bottomSectionDescription}>
                  {acquiredPercentage.buyerAcquiredPercentage.toFixed()}
                  %
                </div>
              </Grid>
              <Grid item className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}>
                <div className={classes.bottomSectionDescription}>
                  {acquiredPercentage.sellerRetainedPercentage.toFixed(
                    2,
                  )}
                  %
                </div>
              </Grid>
              <Grid item className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}>
                <div className={classes.bottomSectionDescription}>
                  {`$ ${wellBid.commission}`}
                </div>
              </Grid>
              <Grid item className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}>
                <div className={classes.bottomSectionDescription}>
                  {bidType === 'Cash' ? `$ ${wellBid.totalCost}`
                    : formatNumberToCurrency(computeImpliedPrice(
                      item.wellGrossAfe,
                      acquiredPercentage.sellerRetainedPercentage,
                    ).toFixed(0))}
                </div>
              </Grid>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default memo(WellListingTable);

WellListingTable.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
