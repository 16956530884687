import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, InputAdornment } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import SsnOrTinInput from 'now-frontend-shared/components/inputs/SsnOrTinInput';
import styles from './styles';
import { persistCompanyUpdates, updateCompany } from 'store/actions/companyActions';
import DatePicker from 'now-frontend-shared/components/DatePicker';
import { validateBeneficialOwnershipPercentage } from 'now-shared/validation/beneficial-ownership-percentage';
import { getAllStates } from 'store/actions/statesActions';
import {
  validateBeneficialOwnerDetails,
} from 'now-shared/validation/beneficial-owner-details';
import { formValidationHasErrors } from 'now-shared/validation/validation-rules';
import LockIcon from '@material-ui/icons/LockRounded';
import Onboarding from 'layouts/Onboarding';
import { navigateToNextPage } from 'constants/registrationFlow';
import { useHistory } from 'react-router-dom';

function BeneficialOwnerAccountView({
  classes, states, company, authUser,
}) {
  const dispatch = useDispatch();
  const [touched, setTouched] = React.useState({});
  const history = useHistory();
  const errors = validateBeneficialOwnerDetails(company);
  const formHasErrors = formValidationHasErrors(errors);
  const [isSecretVisible, setIsSecretVisible] = React.useState({});

  React.useEffect(() => {
    dispatch(getAllStates());
  }, [dispatch]);

  React.useEffect(() => {
    if (typeof company.BOstateId === 'number' && states?.states?.length) {
      dispatch(updateCompany({ value: states.states.find(state => state.id === company.BOstateId), key: 'BOstateId' }));
    }
  }, [dispatch, states.states, company.BOstateId]);

  const [percentTouched, setPercentTouched] = React.useState(false);

  const setTouchedDelay = 0;
  const setTouchedDelayed = (fieldName, newValue) => {
    setTimeout(() => setTouched(prev => ({
      ...prev,
      [fieldName]: newValue,
    })), setTouchedDelay);
  };

  const setIsSecretVisibleDelayed = (fieldName, newValue) => {
    setTimeout(() => setIsSecretVisible(prev => ({
      ...prev,
      [fieldName]: newValue,
    })), setTouchedDelay);
  };

  const invalidPercentage = percentTouched && !validateBeneficialOwnershipPercentage(company.BOPercentageOwnership);
  const businessFields = [
    { label: 'Title', value: 'BOTitle', dataCy: 'boTitle' },
    { label: 'Address', value: 'BOStreetAddress', dataCy: 'boAddress' },
    { label: 'Address (optional)', value: 'BOStreetAddress2', dataCy: 'boAddress2' },
  ];
  const createFields = (list, autoFocus = false) => (
    <>
      {list.map(({ label, value, dataCy }, i) => (
        <TextField
          value={company[value]}
          key={label}
          data-cy={dataCy}
          variant="outlined"
          onChange={e => {
            dispatch(updateCompany({ value: e.target.value || null, key: value }));
          }}
          label={label}
          placeholder={label}
          fullWidth
          margin="normal"
          autoFocus={autoFocus && i === 0}
        />
      ))}
    </>
  );

  const requiredCompany = {
    BOStreetAddress: company.BOStreetAddress,
    BOTitle: company.BOTitle,
    BOFirstName: company.BOFirstName,
    BOLastName: company.BOLastName,
    BOCity: company.BOCity,
    BOZipCode: company.BOZipCode,
    BOstateId: company.BOstateId,
  };

  const requiredBusinessFields = Object.values(requiredCompany).every(v => {
    if (!v) return false;
    if (typeof v === 'object' || v.length > 0) return true;
    return false;
  }) && company.BOBirthDate && validateBeneficialOwnershipPercentage(company.BOPercentageOwnership) && !formHasErrors;

  const saveCompany = async () => {
    const {
      BOStreetAddress,
      BOStreetAddress2,
      BOFirstName,
      BOLastName,
      BOTitle,
      BOPercentageOwnership,
      BOBirthDate,
      BOSSNorTin,
      BOCity,
      BOZipCode,
      BOstateId,
    } = company;

    const dataChanged
    = BOStreetAddress === authUser.company.BOStreetAddress
    && BOStreetAddress2 === authUser.company.BOStreetAddress2
    && BOFirstName === authUser.company.BOFirstName
    && BOLastName === authUser.company.BOLastName
    && BOTitle === authUser.company.BOTitle
    && BOPercentageOwnership === authUser.company.BOPercentageOwnership
    && BOBirthDate === authUser.company.BOBirthDate
    && BOSSNorTin === authUser.company.BOSSNorTin
    && BOstateId === authUser.company.BOstateId
    && BOZipCode === authUser.company.BOZipCode
    && BOCity === authUser.company.BOCity;

    if (company.companyId && !dataChanged) {
      try {
        await new Promise((resolve, reject) => dispatch(persistCompanyUpdates({
          companyId: company.companyId,
          updates: {
            BOStreetAddress,
            BOStreetAddress2,
            BOFirstName,
            BOLastName,
            BOTitle,
            BOPercentageOwnership,
            BOBirthDate,
            BOSSNorTin,
            BOCity,
            BOZipCode,
            BOstateId: BOstateId.id,
          },
          resolve,
          reject,
        })));
        history.push(navigateToNextPage(history.location.pathname));
      } catch (err) {
      // eslint-disable-next-line no-console
        console.log(err);
      }
    } else {
      history.push(navigateToNextPage(history.location.pathname));
    }
  };

  return (
    <Onboarding
      title="Beneficial Owner Detail"
      subTitle="Enter your details to proceed further"
      dotNavigationLegend={7}
      nextDisabled={!requiredBusinessFields}
      nextClick={saveCompany}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ marginRight: 'auto', maxWidth: '840px' }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              value={company.BOFirstName}
              key="firstName"
              data-cy="boFirstName"
              variant="outlined"
              onChange={e => {
                dispatch(
                  updateCompany({ value: e.target.value || null, key: 'BOFirstName' }),
                );
              }}
              label="First Name"
              placeholder="First Name"
              fullWidth
              margin="normal"
              autoFocus
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={company.BOLastName}
              key="lastName"
              data-cy="boLastName"
              variant="outlined"
              onChange={e => {
                dispatch(
                  updateCompany({ value: e.target.value || null, key: 'BOLastName' }),
                );
              }}
              label="Last Name"
              placeholder="Last Name"
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              error={invalidPercentage}
              helperText={invalidPercentage ? 'Ownership must be between 25 and 100' : '\u00a0'}
              value={company.BOPercentageOwnership}
              key="Percentage Ownership"
              data-cy="boPercentageOwnership"
              variant="outlined"
              type="number"
              onFocus={() => setPercentTouched(true)}
              onChange={e => {
                dispatch(
                  updateCompany({
                    value: e.target.value || null,
                    key: 'BOPercentageOwnership',
                  }),
                );
              }}
              label="Percentage of Ownership"
              placeholder="0"
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={6} className={classes.datePickerStyles}>
            <Typography
              variant="body1"
              component="div"
              className={classes.datePickerText}
            >
              Date of Birth
            </Typography>
            <DatePicker
              meta={{ touched: false, error: false }}
              maxDate={new Date()}
              classes={{ input: classes.datePicker }}
              fullWidth
              input={{
                name: 'BOBirthDate',
                value: company.BOBirthDate,
                onChange: e => dispatch(updateCompany({ value: e, key: 'BOBirthDate' })),
              }}
            />
          </Grid>
        </Grid>
        {(() => {
          const field = 'BOSSNorTin';
          const label = 'SSN/TIN';
          const isSecret = true;
          const error = touched[field] && errors[field];
          const value = company[field];
          return (
            <>
              <SsnOrTinInput
                classes={{ maskStyles: classes.ssnOrTinInput }}
                autoComplete="off"
                input={{
                  name: field,
                  value: value || '',
                  onChange: e => {
                    dispatch(updateCompany({ key: field, value: e.target.value || null }));
                  },
                  onFocus: () => {
                    setTouchedDelayed(field, false);
                    if (isSecret) {
                      setIsSecretVisibleDelayed(field, true);
                      if (document.getElementById('BOSSNorTin').getAttribute('type') === 'password') {
                        document.getElementById('BOSSNorTin').setAttribute('type', 'text');
                      }
                    }
                  },
                  onBlur: () => {
                    setTouchedDelayed(field, true);
                    if (isSecret) {
                      document.getElementById('BOSSNorTin').setAttribute('type', 'password');
                      setIsSecretVisibleDelayed(field, false);
                    }
                  },
                }}
                renderInput={() => (
                  <TextField
                    label={label}
                    id={field}
                    data-cy="boSSN"
                    type="text"
                    error={!!error}
                    helperText={error || '\u00a0'}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <LockIcon />
                      ),
                    }}
                  />
                )}
              />
              <Typography variant="body" component="div" gutterBottom className={classes.infoText}>
                If the user does not enter a Social Security Number during registration,
                it must be provided prior to closing any transaction on the Platform.
              </Typography>
            </>
          );
        })()}
        {createFields(businessFields)}
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              value={company.BOCity}
              key="city"
              data-cy="boCity"
              variant="outlined"
              onChange={e => {
                dispatch(updateCompany({ value: e.target.value || null, key: 'BOCity' }));
              }}
              label="City"
              placeholder="City"
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              value={company.BOZipCode}
              key="zip"
              data-cy="boZip"
              variant="outlined"
              onChange={e => {
                dispatch(
                  updateCompany({ value: e.target.value || null, key: 'BOZipCode' }),
                );
              }}
              label="Zip"
              placeholder="Zip"
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              margin="normal"
              fullWidth
              value={company.BOstateId}
              onChange={(_, newValue) => {
                dispatch(updateCompany({ value: newValue || null, key: 'BOstateId' }));
              }}
              id="state-select"
              data-cy="boState"
              options={states.states}
              style={{
                maxWidth: 629,
                marginTop: '16px',
                marginBottom: '8px',
              }}
              getOptionLabel={option => option.title || ''}
              renderInput={params => (
                <TextField {...params} label="State" variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Onboarding>
  );
}

BeneficialOwnerAccountView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  company: PropTypes.objectOf(PropTypes.string).isRequired,
  states: PropTypes.objectOf(PropTypes.string).isRequired,
  authUser: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  connect(({ company, states, auth }) => ({
    company,
    states,
    authUser: auth.user,
  })),
  withStyles(styles),
)(BeneficialOwnerAccountView);
