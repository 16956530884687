import { REQUIRED } from 'now-shared/validation/validation-rules';
import { formatNumberToCurrency, maskedAmountToNumber } from '../utils/helpers';

export default function validateFormattedCurrency(formattedCurrency, {
  required,
  positive,
  moreThan,
  atLeast,
}) {
  const amount = maskedAmountToNumber(formattedCurrency);
  if (amount !== undefined) {
    if (moreThan !== undefined && amount <= moreThan) {
      return `Must be more than ${formatNumberToCurrency(moreThan)}`;
    }
    if (atLeast !== undefined && amount < atLeast) {
      return `Must be at least ${formatNumberToCurrency(atLeast)}`;
    }
    if (positive !== undefined && amount <= 0) {
      return `Must be more than ${formatNumberToCurrency(0)}`;
    }
  } else if (required) {
    return REQUIRED;
  }
}
