import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, connect } from 'react-redux';

import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import Pagination from 'now-frontend-shared/components/Pagination';
import Search from 'now-frontend-shared/components/inputs/Search';
import ControlledSelect from 'now-frontend-shared/components/Select/ControlledSelect';
import Spinner from 'now-frontend-shared/components/Spinner';

// layouts
import PropertyLayout from 'layouts/PropertyLayout';

// helpers
import { createCustomQuery, DEFAULT_QUERY, removeQueryParam } from 'now-frontend-shared/utils/helpers';
import { userHasPermissionToEditListing } from 'now-shared/helpers/permissions';

// custom hooks

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Chip,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  getProperties,
  getSellerStatuses,
  getStates,
  clearState,
} from 'store/actions/myPropertiesActions';

import decompile from 'now-frontend-shared/helpers/structure';
import styles from './styles';
import { BooleanString, BooleanStringTitle } from 'now-shared/enums/boolean-string';

const archivedOptions = [
  {
    id: '',
    title: '-',
  },
  {
    id: BooleanString.True,
    title: BooleanStringTitle[BooleanString.True],
  },
  {
    id: BooleanString.False,
    title: BooleanStringTitle[BooleanString.False],
  },
];

const MyProperties = ({
  classes,
  history,
  propertiesList,
  propertiesMeta,
  statuses,
  states,
  pending,
  user,
}) => {
  const dispatch = useDispatch();

  const sideNavContentRef = useRef();
  const listingsFilterLabelRef = useRef();
  const listingsRef = useRef();
  const filterButtonRef = useRef();
  const topLevelContainerRef = useRef();
  const sideNavRef = useRef();
  const [navOpen, setNavOpen] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const selectedStatus = useRef();
  const selectedArchiveOption = useRef();
  const selectedState = useRef();
  const selectedBasin = useRef();
  const selectedCounty = useRef();
  const queryParams = new URLSearchParams(history.location.search);
  const decompiled = decompile(states || [], queryParams);

  useEffect(() => {
    dispatch(getSellerStatuses());
    dispatch(getStates());

    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    dispatch({ type: getProperties.type, payload: history.location.search || DEFAULT_QUERY });
  }, [dispatch, history, history.location.search]);

  const setQueryValue = (queryName, queryValue) => {
    if (queryName && queryValue) {
      const search = createCustomQuery(history, queryName, queryValue);
      history.push(search);
    } else if (queryName && !queryValue) {
      const search = removeQueryParam(history, queryName);
      history.push(search);
    } else {
      history.push(DEFAULT_QUERY);
    }
  };

  const goToCreatePage = () => {
    history.push({ pathname: 'create-listing' });
  };

  const openNav = () => {
    const sideNav = sideNavRef?.current;
    const sideNavContent = sideNavContentRef?.current;
    const filterButton = filterButtonRef?.current;
    const listingsFilterLabel = listingsFilterLabelRef?.current;
    if (!navOpen) {
      // TODO: [DRY] refactor these styles to a class.
      setNavOpen(true);
      listingsFilterLabel.style.setProperty('margin', '0px 0px 0px 400px');
      sideNav.style.setProperty('width', '400px');
      sideNavContent.style.setProperty('visibility', 'visible');
      sideNavContent.style.setProperty('opacity', 1);
      sideNavContent.style.setProperty('z-index', 3);
      filterButton.style.setProperty('opacity', 0);
    }
  };

  const closeNav = () => {
    const sideNav = sideNavRef?.current;
    const sideNavContent = sideNavContentRef?.current;
    const filterButton = filterButtonRef?.current;
    const listingsFilterLabel = listingsFilterLabelRef?.current;
    if (navOpen) {
      setNavOpen(false);
      listingsFilterLabel.style.setProperty('margin', '0px 0px 0px 0px');
      sideNav.style.setProperty('width', '0px');
      sideNavContent.style.setProperty('opacity', 0);
      setTimeout(() => {
        sideNavContent.style.setProperty('visibility', 'hidden');
      }, 500);
      sideNavContent.style.setProperty('z-index', 1);
      filterButton.style.setProperty('opacity', 1);
    }
  };

  const handleAccordionChange = panel => (event, isExpanded) => {
    setAccordionExpanded(isExpanded ? panel : false);
  };

  if (!propertiesList) return <Spinner wrapped />;

  return (
    <>
      <Box sx={{ display: 'grid', margin: '0px 0px 0px 0px', maxWidth: '100%' }}>
        {/* side nav content */}
        <Box
          ref={sideNavContentRef}
          className={classes.sideNavContent}
        >
          <Box sx={{
            justifySelf: 'end',
          }}
          >
            <CloseIcon style={{ cursor: 'hand', marginRight: '10px' }} onClick={closeNav} />
          </Box>
          <Box sx={{ paddingBottom: '14px' }}>Filters:</Box>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              Property Name/ID
            </AccordionSummary>
            <AccordionDetails>
              <Search setQueryValue={setQueryValue} placeholder="" />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} expanded={accordionExpanded === 'status'} onChange={handleAccordionChange('status')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              Status
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.statusFilter}>
                <FormControlLabel
                  label="All"
                  checked={!selectedStatus.current}
                  control={(
                    <Checkbox
                      onChange={() => { selectedStatus.current = undefined; setQueryValue('status'); }}
                      className={classes.checkbox}
                    />
                  )}
                />
                {statuses.map((status, index) => (
                  <FormControlLabel
                    key={index}
                    label={status.title}
                    checked={status.id === selectedStatus.current}
                    control={(
                      <Checkbox
                        onChange={() => { selectedStatus.current = status.id; setQueryValue('status', status.id); }}
                        className={classes.checkbox}
                      />
                    )}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} expanded={accordionExpanded === 'archived'} onChange={handleAccordionChange('archived')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              Archived
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.statusFilter}>
                {archivedOptions.map((archiveOption, index) => (
                  <FormControlLabel
                    key={index}
                    label={archiveOption.title}
                    checked={archiveOption.id === selectedArchiveOption.current}
                    control={(
                      <Checkbox
                        onChange={() => { selectedArchiveOption.current = archiveOption.id; setQueryValue('archived', archiveOption.id); }}
                        className={classes.checkbox}
                      />
                    )}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} expanded={accordionExpanded === 'states'} onChange={handleAccordionChange('states')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              State
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.filters}>
                <FormControlLabel
                  disabled={(selectedBasin.current || selectedCounty.current)}
                  label="All"
                  checked={!selectedState.current}
                  control={(
                    <Checkbox
                      onChange={() => { selectedState.current = undefined; setQueryValue('state'); }}
                      className={classes.checkbox}
                    />
                  )}
                />
                {decompiled.states.map((state, index) => (
                  <FormControlLabel
                    key={index}
                    label={state?.title}
                    checked={state?.id === selectedState.current}
                    control={(
                      <Checkbox
                        onChange={() => { selectedState.current = state?.id; setQueryValue('state', state?.id); }}
                        className={classes.checkbox}
                      />
                    )}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} expanded={accordionExpanded === 'basins'} onChange={handleAccordionChange('basins')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              Basin
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.filters}>
                <FormControlLabel
                  disabled={selectedCounty.current}
                  label="All"
                  checked={!selectedBasin.current}
                  control={(
                    <Checkbox
                      onChange={() => { selectedBasin.current = undefined; setQueryValue('basin'); }}
                      className={classes.checkbox}
                    />
                  )}
                />
                {decompiled.basins.map((basin, index) => (
                  <FormControlLabel
                    key={index}
                    label={basin.title}
                    checked={basin.id === selectedBasin.current}
                    control={(
                      <Checkbox
                        onChange={() => { selectedBasin.current = basin.id; setQueryValue('basin', basin.id); }}
                        className={classes.checkbox}
                      />
                    )}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} expanded={accordionExpanded === 'counties'} onChange={handleAccordionChange('counties')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              County
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.filters}>
                <FormControlLabel
                  label="All"
                  checked={!selectedCounty.current}
                  control={(
                    <Checkbox
                      onChange={() => { selectedCounty.current = undefined; setQueryValue('county', undefined); }}
                      className={classes.checkbox}
                    />
                  )}
                />
                {decompiled.counties.map((county, index) => (
                  <FormControlLabel
                    key={index}
                    label={county.title}
                    checked={county.id === selectedCounty.current}
                    control={(
                      <Checkbox
                        onChange={() => { selectedCounty.current = county.id; setQueryValue('county', county.id); }}
                        className={classes.checkbox}
                      />
                    )}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* listings label and filter chips */}
        <Box
          ref={listingsFilterLabelRef}
          className={classes.labelAndChips}
        >
          <div>
            <span style={{ fontSize: '24px', padding: '0px 10px 0px 0px' }}>My Listings</span>
            <Box sx={{ paddingTop: '5px' }}>
              {selectedStatus.current
              && (
                <Chip
                  label={
                  statuses.filter(status => status.id === selectedStatus.current)[0]?.title
                  }
                  variant="outlined"
                  onDelete={
                    () => {
                      selectedStatus.current = undefined;
                      setQueryValue('status');
                    }
                  }
                  style={{ margin: '0px 5px' }}
                />
              )}
              {selectedArchiveOption.current
              && (
                <Chip
                  label={
                    archivedOptions.filter(archive => archive.id === selectedArchiveOption.current)[0]?.title
                  }
                  variant="outlined"
                  onDelete={
                    () => {
                      selectedArchiveOption.current = undefined;
                      setQueryValue('archived');
                    }
                  }
                  style={{ margin: '0px 5px' }}
                />
              )}
            </Box>
          </div>
          {userHasPermissionToEditListing(user) && (
            <Button
              className={classes.createListingButton}
              data-cy="createListingButton"
              variant="contained"
              onClick={goToCreatePage}
            >
              Create new listing
            </Button>
          )}
        </Box>

        {/* listings and map */}
        <Box
          ref={topLevelContainerRef}
          className={classes.listingsAndMap}
        >
          <Box ref={sideNavRef} className={classes.sidenav} />
          <Box ref={listingsRef} sx={{ gridColumnStart: 2, gridColumnEnd: 3 }}>
            {propertiesList?.length === 0 ? (
              <Box sx={{ display: 'grid', padding: '0px 10px', width: '100%' }}>
                <span className={classes.message}>You don’t have any listings.</span>
                <Box sx={{
                  display: 'grid', gridAutoFlow: 'column', padding: '0px 10px', width: '100%',
                }}
                >
                  <Box
                    ref={filterButtonRef}
                    className={classes.filterButton}
                  >
                    {!navOpen && (
                      <Button
                        className={classes.filterButtonStyle}
                        variant="contained"
                        onClick={openNav}
                      >
                        Filter
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            ) : (
              <>
                <Box sx={{
                  display: 'grid', gridAutoFlow: 'column', padding: '0px 10px', width: '100%',
                }}
                >
                  <Box
                    ref={filterButtonRef}
                    className={classes.filterButton}
                  >
                    <Button
                      className={classes.filterButtonStyle}
                      variant="contained"
                      onClick={openNav}
                    >
                      Filter
                    </Button>
                  </Box>
                  <Box sx={{ width: '175px', justifySelf: 'end' }}>
                    <ControlledSelect
                      options={[
                        { title: 'Net AFE Amount', id: 'netAfe' },
                        { title: 'Minimum Bid', id: 'minimumBid' },
                        { title: 'Minimum Carry', id: 'minimumBidCarry' },
                        { title: 'Auction End', id: 'endTime' },
                      ]}
                      placeholder="Sort by"
                      name="order"
                      setQueryValue={setQueryValue}
                    />
                  </Box>
                </Box>
                <Box className={classes.listings}>
                  {propertiesList?.map(property => (
                    <PropertyLayout key={property.id} property={property} withoutHandleClick />
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>

      {propertiesMeta?.totalItems > 5 && (
        <Grid container justify="center">
          <Pagination count={propertiesMeta.totalPages} itemsPerPage={5} />
        </Grid>
      )}
    </>
  );
};

MyProperties.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  connect(({ auth, myProperties }) => ({
    propertiesList: myProperties.propertiesList?.items,
    propertiesMeta: myProperties.propertiesList?.meta,
    statuses: myProperties.statuses || [],
    states: myProperties.states,
    pending: myProperties.pending,
    user: auth.user,
  })),
  withStyles(styles),
)(MyProperties);
