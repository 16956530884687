import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// components
import LinkButton from 'now-frontend-shared/components/LinkButton';
import CloseInfoWindowButton from '../CloseInfoWindowButton';

// custom hooks
import useIsMobile from 'now-frontend-shared/hooks/useIsMobile';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

import { pluralize } from 'now-shared/helpers/text-helpers';

const InfoWindow = ({
  classes,
  isCluster,
  isEmbedded,
  pointCount,
  listings,
  handleToggleScrollwheel,
  handleCloseInfoWindow,
}) => {
  const isMobile = useIsMobile();

  const transform = useMemo(() => {
    if (isCluster && !isEmbedded) {
      return `${isMobile ? 'translateX(-50%)' : 'translateX(-8px)'} translateY(-${10 + pointCount * 2}px)`;
    }
  }, [isCluster, isMobile, isEmbedded, pointCount]);

  return (
    <div
      className={isEmbedded ? classes.infoWindowBase : classes.infoWindow}
      style={{ transform }}
      {...handleToggleScrollwheel && {
        onMouseEnter: handleToggleScrollwheel,
        onMouseLeave: handleToggleScrollwheel,
        onTouchStartCapture: handleToggleScrollwheel,
      }}
    >
      {!isEmbedded && handleCloseInfoWindow && (
        <CloseInfoWindowButton handleClick={handleCloseInfoWindow} />
      )}

      {isCluster && (
        <Grid container>
          <span className={classes.infoHeading}>
            Multiple listings in this location/county...
          </span>
        </Grid>
      )}

      <Grid
        container
        className={cx(classes.infoContainer, { [classes.infoContainerOverflow]: pointCount > 4 })}
      >
        {listings.map(listing => (
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.propertyInfo}
            key={listing.id}
          >
            <Grid item xs={5}>
              <span
                className={classes.propertyId}
              >
                {`${listing.projectName} (${listing.wellCount} well${pluralize('s', listing.wellCount)})`}
              </span>
            </Grid>

            <Grid item xs={6}>
              <LinkButton
                fullWidth
                buttonColor="green"
                label="Listing Details"
                path={`/listings/${listing.id}`}
                customStyles={{
                  height: '26px', margin: 'unset', borderRadius: '4px', fontWeight: 'normal',
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>

      {isCluster && (
        <Grid container direction="column">
          <span className={classes.infoCount}>
            There are
            {' '}
            <span className={classes.infoCountBold}>{`${pointCount} additional `}</span>
            listings at this location
          </span>

          <span className={classes.infoMessage}>Zoom in to see additional listings.</span>
        </Grid>
      )}
    </div>
  );
};

InfoWindow.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isCluster: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  pointCount: PropTypes.number,
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      projectName: PropTypes.string.isRequired,
      wellCount: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  handleToggleScrollwheel: PropTypes.func,
  handleCloseInfoWindow: PropTypes.func,
};

InfoWindow.defaultProps = {
  isCluster: false,
  isEmbedded: false,
  pointCount: 0,
  handleToggleScrollwheel: undefined,
  handleCloseInfoWindow: undefined,
};

export default compose(withStyles(styles), memo)(InfoWindow);
