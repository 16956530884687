import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';

import PropTypes from 'prop-types';
import { compose } from 'redux';

// components
import Pagination from 'now-frontend-shared/components/Pagination';
import SwitchInput from 'now-frontend-shared/components/Switch/SwitchInput';
import Button from 'now-frontend-shared/components/Button';
import Spinner from 'now-frontend-shared/components/Spinner';

// layouts
import HeadSection from 'now-frontend-shared/layouts/AuthSections/HeadSection';
import BidInfoLayout from 'layouts/BidInfoLayout';

// helpers
import { createQuery, DEFAULT_QUERY } from 'now-frontend-shared/utils/helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

// styles
import { getPropertyBids, clearState } from 'store/actions/propertyBidsListActions';
import styles from './styles';

import { SortDirection } from 'now-shared/enums/sort-direction';

/**
 * Display the bids placed on a particular listing
 */
const PropertyBidsList = ({
  classes,
  match,
  history,
  bidsList,
  propertiesMeta,
  commonInfo,
  pending,
  user,
}) => {
  const dispatch = useDispatch();

  const listingId = match.params.id;
  const defaultQuery = `${DEFAULT_QUERY}&order=amount&sort=${SortDirection.Descending}`;
  const query = history.location.search || defaultQuery;

  useEffect(() => {
    dispatch(getPropertyBids({
      id: listingId,
      query,
    }));

    return () => dispatch(clearState());
  }, [dispatch, listingId, query]);

  const setQueryValue = (queryName, queryValue) => {
    if (queryName && queryValue) {
      const search = createQuery(history, queryName, queryValue);
      history.push(search);
    } else {
      history.push(DEFAULT_QUERY);
    }
  };

  const resetFilters = () => {
    history.push(DEFAULT_QUERY);
  };

  if (!commonInfo || !bidsList) {
    return <Spinner wrapped />;
  }

  return (
    <>
      <Grid container className={classes.relativeContainer}>
        {pending && <Spinner backdrop />}

        <Grid container direction="column" className={classes.wrapper}>
          <HeadSection
            heading={(
              <span>
                <span style={{ color: '#BDBDBD', fontSize: 20 }}>Bids Recieved</span>
                <br />
                {`${commonInfo.projectName}`}
              </span>
            )}
            ignorePrevPath
            path={`/listings/${listingId}`}
          />
          <Grid spacing={3} container className={classes.topPanel} style={{ marginTop: 20, borderTop: '1px solid #EBEBEB' }}>
            <Typography variant="h6" style={{ marginLeft: 15 }}>{`Bids(${propertiesMeta.totalItems})`}</Typography>
            <Grid container spacing={3} className={classes.topPanel} style={{ margin: 0, padding: 0 }}>
              <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                <SwitchInput name="createdAt" placeholder="Bid Date" setQueryValue={setQueryValue} />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                <SwitchInput name="amount" placeholder="Bid Amount" setQueryValue={setQueryValue} />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                <SwitchInput name="carryPercentage" placeholder="Bid Carry" setQueryValue={setQueryValue} />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                <Button
                  label="Reset filters"
                  buttonColor="primaryMain"
                  fullWidth
                  style={{ width: '100%', height: '48px', margin: 'unset' }}
                  onClick={resetFilters}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row">
            {bidsList?.length === 0 ? (
              <Grid container justify="center">
                <span className={classes.message}>No bids available at this time</span>
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  className={classes.container}
                >
                  {bidsList.map(bid => (
                    <BidInfoLayout
                      key={bid.id}
                      canSelectWinner={commonInfo.status.title === 'closed'}
                      hasPermissionToSelectWinner={
                        !!user.isAdmin
                        || (
                          user.company?.id === commonInfo.user.company.id
                          && !!user.isAuthorizedSigner
                        )
                      }
                      bid={bid}
                      property={commonInfo}
                      query={query}
                      data-cy={`bidInfoContainer-${bid.id}`}
                    />
                  ))}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      {propertiesMeta?.totalItems > 5 && (
        <Grid container justify="center">
          <Pagination count={propertiesMeta.totalPages} itemsPerPage={5} />
        </Grid>
      )}
    </>
  );
};

PropertyBidsList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  bidsList: PropTypes.array,
  propertiesMeta: PropTypes.object,
  commonInfo: PropTypes.object,
  pending: PropTypes.bool,
  user: PropTypes.shape({
    // TODO: [TYPE] add user type props
  }).isRequired,
};

export default compose(
  connect(({ propertyBidsList, auth }) => ({
    bidsList: propertyBidsList.bidsList?.items,
    propertiesMeta: propertyBidsList.bidsList?.meta,
    commonInfo: propertyBidsList.commonInfo,
    pending: propertyBidsList.pending,
    user: auth.user,
  })),
  withStyles(styles),
)(PropertyBidsList);
