import React, {
  memo, useMemo, useRef, useState, useEffect, useCallback,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';

// components
import StyledLink from '../../pages/Profile/components/StyledLink';

// helpers
import { formatNumberToCurrency } from 'now-frontend-shared/utils/helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// styles
import styles from './styles';
import './styles.css';

const BidLayout = ({
  classes,
  propertyStatus,
  bid: {
    amount,
    property,
    carryPercentage,
    commission,
    wellBids,
  },
}) => {
  const bidAmount = useMemo(() => (amount ? formatNumberToCurrency(amount, true) : '-'), [amount]);
  const bidCarryAmount = carryPercentage ? `${carryPercentage}%` : '-';
  const closingFees = commission;

  const closingFeesFormatted = useMemo(() => (
    closingFees === undefined ? '-' : formatNumberToCurrency(closingFees)
  ), [closingFees]);

  const location = useLocation();

  // second grid-column contents width must be dynamically set
  // in order to clip non wrapping text when grid width changes dynamically
  const [gridItemTwoWidth, setGridItemTwoWidth] = useState();
  const topLevelContainerRef = useRef();
  const WIDTH_BREAKPOINT = 625;
  const widthBreakPoint = WIDTH_BREAKPOINT;

  const resizeGridItemTwo = useCallback(() => {
    const gridComputedStyle = getComputedStyle(topLevelContainerRef?.current);
    let gridTemplateColumns = gridComputedStyle.getPropertyValue('grid-template-columns').split(' ');
    setGridItemTwoWidth(gridTemplateColumns[1]);

    // decide if the total width is wide enough to show view link in grid-template-column five
    // dynamically query and update the css property via css variable
    const componentWidth = topLevelContainerRef?.current?.clientWidth;
    const viewLinkDisplayValue = getComputedStyle(topLevelContainerRef?.current).getPropertyValue('--show-view-link').trim();

    // if wide enough show view link by setting display css property to initial
    // add the grid column (and by association its gap) back as well
    if (viewLinkDisplayValue === 'none'
      && componentWidth > widthBreakPoint) {
      const element = topLevelContainerRef?.current;
      element.style.setProperty('--show-view-link', 'initial');
      const gridColumns = 'max-content minmax(0px, 1fr) max-content max-content max-content 0px';
      element.style.setProperty('--container-grid-columns', gridColumns);

      // following layout change reset gridTwoWidth to cut overflow at correct width
      gridTemplateColumns = gridComputedStyle.getPropertyValue('grid-template-columns').split(' ');
      setGridItemTwoWidth(gridTemplateColumns[1]);
    } else if (viewLinkDisplayValue === ''
      && componentWidth <= widthBreakPoint) {
      // else if narrow enough hide view link by setting display css property to none
      // remove the grid column (and by association its gap) as well
      const element = topLevelContainerRef?.current;
      element.style.setProperty('--show-view-link', 'none');
      const gridColumns = 'max-content minmax(0px, 1fr) max-content max-content max-content';
      element.style.setProperty('--container-grid-columns', gridColumns);

      // following layout change reset gridTwoWidth to cut overflow at correct width
      gridTemplateColumns = gridComputedStyle.getPropertyValue('grid-template-columns').split(' ');
      setGridItemTwoWidth(gridTemplateColumns[1]);
    }
  }, [widthBreakPoint]);

  useEffect(() => {
    resizeGridItemTwo();
    window.addEventListener('resize', resizeGridItemTwo);

    return () => window.removeEventListener('resize', resizeGridItemTwo);
  }, [resizeGridItemTwo]);

  return (
    <>
      {
        property
        && (
          <Box
            ref={topLevelContainerRef}
            className={cx('container', classes.card)}
            sx={{ width: '100%' }}
          >
            <Box
              className={cx(classes.status, {
                [classes.active]: ['active'].includes(property.status.title),
                [classes.closed]: ['closed'].includes(property.status.title) || !['active'].includes(property.status.title),
              })}
              sx={{ gridColumnStart: 1, gridColumnEnd: 2 }}
            >
              {propertyStatus}
            </Box>
            <Box sx={{
              gridColumnStart: 2, gridColumnEnd: 3, justifySelf: 'start', width: `${gridItemTwoWidth}`,
            }}
            >
              <Box
                className={classes.topRow}
                sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
              >
                <Link
                  to={{ pathname: `/listings/${property.id}`, state: { prevPath: location } }}
                  className={classes.titleLink}
                >
                  {property.projectName}
                  {' '}
                  (
                  {property.wells}
                  {' '}
                  {property.wells > 1 ? 'wells' : 'well'}
                  )
                </Link>
              </Box>
              <Box
                className={classes.bottomRow}
                sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
              >
                {property.county.title}
                {', '}
                {property.state.title}
                {', '}
                {property.basin.title}
              </Box>
            </Box>
            {wellBids.map((well, index) => (
              <div key={well.id}>
                <Box className={classes.topRow}>
                  {well.amount ? `$${well.amount}` : '-'}
                </Box>
                <Box className={classes.bottomRow}>
                  {`Well ${index + 1} Bid Amount, USD`}
                </Box>
              </div>
            ))}
            {wellBids.map((well, index) => (
              <div key={well.id}>
                <Box className={classes.topRow}>
                  {well.carryPercentage ? `${well.carryPercentage}%` : '-'}
                </Box>
                <Box className={classes.bottomRow}>
                  {`Well ${index + 1} Bid Amount, Carry`}
                </Box>
              </div>
            ))}
            <Box sx={{ gridColumnStart: 5, gridColumnEnd: 6, textAlign: 'center' }}>
              <Box className={classes.topRow}>
                {closingFeesFormatted}
              </Box>
              <Box className={classes.bottomRow}>
                Closing Fees
              </Box>
            </Box>
            <Box className="linkClass">
              <StyledLink
                path={`/listings/${property.id}`}
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '15px',
                  lineHeight: '26px',
                  letterSpacing: '0.46px',
                  textDecorationLine: 'underline',
                  textTransform: 'uppercase',
                  // TODO: [DRY] use a color palette constant instead of string literal here.
                  // See `frontend-shared/theme/colors.js`
                  color: '#7890A6',
                }}
              >
                view
              </StyledLink>
            </Box>
          </Box>
        )
      }
    </>
  );
};

BidLayout.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  bid: PropTypes.shape({
    amount: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    property: PropTypes.shape({
      id: PropTypes.number.isRequired,
      projectName: PropTypes.string.isRequired,
      status: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
      wells: PropTypes.number.isRequired,
      county: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
      state: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
      basin: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
      wellBids: PropTypes.arrayOf(PropTypes.object),
    }),
    carryPercentage: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    commission: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  }).isRequired,
};

export default compose(withStyles(styles), memo)(BidLayout);
