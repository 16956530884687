import { LOCATION_CHANGE } from 'connected-react-router';

// actions
import * as A from '../actions/mapActions';

const initialState = {
  pins: undefined,
  historicalListings: undefined,
};

export const reducer = {
  map(state = initialState, action) {
    switch (action.type) {
      case LOCATION_CHANGE:
      case A.clearState.type:
        return initialState;

      case A.getPropertiesMarkers.success:
        return { ...state, pins: action.payload };

      case A.getHistoricalListings.success:
        return { ...state, historicalListings: action.payload };

      default:
        return state;
    }
  },
};
