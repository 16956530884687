import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const AboutUs = ({ classes }) => (
  <Grid container justify="center">
    <Grid container alignItems="center" justify="center" className={classes.heading}>
      <span>About Us</span>
    </Grid>

    <Grid container item xs={12} sm={12} md={8} lg={8} justify="center" className={classes.textContainer}>
      <span className={classes.text}>
        Selling non-operated interests in oil & gas wells has historically been extremely inefficient. Clogged email inboxes, attachment file size restrictions, preparing data rooms or ftp sites to share documents, and complex structuring requirements have prohibited many interested parties from executing the purchase and sale of oil & gas well AFE’s.
      </span>

      <span className={classes.text}>
        As an operator, non-operated properties can be burdensome and damaging to budgeting and forecasting. Yet at the same time, many investors search for but can’t find direct well-level investment opportunities in a timely manner.
      </span>

      <span className={classes.text}>
        That is exactly why we created non-op wells (NOW). With so many E&P companies looking to avoid the dreaded non-consent option on well participation, we figured there must be a simpler, digital solution to attract direct investment without divesting the entire upside associated with an asset. NOW was created to connect investors seeking well-level investments via AFE purchases with E&P working interest owners. NOW is a digital marketplace that provides an efficient, simple and standardized platform to buy & sell AFE’s.
      </span>
    </Grid>
  </Grid>
);

AboutUs.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), memo)(AboutUs);
