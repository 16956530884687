import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import {
  Field,
  FieldArray,
  reduxForm,
  getFormValues,
  change,
  untouch,
} from 'redux-form';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { isUsingEsriMaps } from 'now-frontend-shared/features/feature-flags';

// components
import SubmitConfirmationModal from 'now-frontend-shared/components/modals/SubmitConfirmationModal';
import GeneralInformation, { generalInformationTypes } from './components/GeneralInformation';
import AmountInput from 'now-frontend-shared/components/inputs/AmountInput';
import BaseInput from 'now-frontend-shared/components/inputs/BaseInput';
import CompaniesInput from 'now-frontend-shared/components/inputs/CompaniesInput';
import LandingZone from './components/LandingZone';
import DatePicker from 'now-frontend-shared/components/DatePicker';
import GoogleMap from 'now-frontend-shared/components/GoogleMap';
import EsriMap from 'now-frontend-shared/components/EsriMap';
import Button from 'now-frontend-shared/components/Button';
import Spinner from 'now-frontend-shared/components/Spinner';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { cyan } from '@material-ui/core/colors';

import { warnAboutUnsavedForm } from 'now-frontend-shared/hoc/warnAboutUnsavedForm';

// layouts
import LabelLayout from 'now-frontend-shared/components/inputs/layouts/LabelLayout';
import MainSection from 'now-frontend-shared/layouts/AuthSections/MainSection';
import FormLayout from 'now-frontend-shared/layouts/FormLayout';

// validate
import {
  defaultStartTimeForListing,
  defaultEndTimeForListing,
  minEndTimeForListing,
  minStartTimeForListing,
  listingHasBeenApproved,
} from 'now-shared/validation/listing-validation';

import { createPropertyValidate } from 'now-frontend-shared/validation/listing-validation';

// helper functions
import {
  NONOPWELLS_TIME_ZONE,
  computeAuctionDurationInDays,
  toAuctionEventTime,
} from 'now-shared/helpers/time-helpers';
import {
  coordinatesGenerator,
  existingPropertyDateRangeGenerator,
  maskedAmountToNumber,
} from 'now-frontend-shared/utils/helpers';
import { ListingDocumentType } from 'now-shared/enums/listing-document-type';
import {
  computeWellsNetAfe,
  computeWellsGrossAfe,
} from 'now-shared/helpers/listing-helpers';

// custom hooks
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';
import useWindowWidth from 'now-frontend-shared/hooks/useWindowWidth';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
} from '@material-ui/core';

// styles
import styles from './styles';

const ListingForm = ({
  AWSData,
  children,
  classes,
  clearAWSData,
  companies,
  currentProperty,
  formName,
  formValues,
  getBasins,
  getCounties,
  getLandingZones,
  getPreSignedUrls,
  handleSubmit,
  isBasedOnExisting,
  isForCreate,
  isLoadingCompanies,
  landingZones,
  onSubmit,
  preSignedUrls,
  removeAWSDataFile,
  setAWSData,
  setBasinId,
  setStateId,
  setUnloadedFilesExist,
  states,
  unloadedFilesExist,
  listingOwner,
  keepSessionAlive,
}) => {
  const dispatch = useDispatch();

  const [filesAreProcessing, setFilesAreProcessing] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadsStarted, setUploadsStarted] = useState(false);
  const [uploadsStopped, setUploadsStopped] = useState(undefined);
  const [newDocsExist, setNewDocsExist] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [transactionType, setTransactionType] = useState('cash'); // cash - carry - either

  const hasBeenApproved = !isForCreate && currentProperty && listingHasBeenApproved(currentProperty);

  const totalWellNetAFE = computeWellsNetAfe(
    formValues.wells?.map(well => ({ wellNetAFE: maskedAmountToNumber(well.wellNetAFE) })),
  ) ?? formValues.netAfe
    ?? 0;

  useEffect(() => {
    setTransactionType(formValues.transactionType ?? 'cash');
  }, [formValues.transactionType]);

  useEffect(
    () => setNewDocsExist(unloadedFilesExist || AWSData.some(document => !document.id)),
    [unloadedFilesExist, AWSData],
  );

  useEffect(
    () => {
      if (uploading && !uploadsStarted) {
        setUploadsStarted(true);
      }
    },
    [uploading, uploadsStarted],
  );

  useEffect(
    () => {
      if (uploadsStarted) {
        setUploadsStopped(uploading ? undefined : (new Date()).getTime());
      }
    },
    [uploadsStarted, uploading],
  );

  useEffect(() => {
    // Keep the session alive if files have been uploaded
    let intervalHandle;

    const clear = () => {
      if (intervalHandle) {
        clearInterval(intervalHandle);
        intervalHandle = undefined;
      }
    };

    // allow 12 hours of session keep-alive after downloads have stopped or finished
    const inactiveThreshold = 12 * 60 * 60 * 1000;

    const keepAlive = async () => {
      if (filesAreProcessing || !uploadsStopped || ((new Date()).getTime() - uploadsStopped) <= inactiveThreshold) {
        try {
          await keepSessionAlive();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      } else {
        clear();
      }
    };

    if (
      keepSessionAlive
      && (filesAreProcessing || (newDocsExist && uploadsStarted))
    ) {
      // every 10 minutes
      const interval = 10 * 60 * 1000;
      intervalHandle = setInterval(keepAlive, interval);
      keepAlive();
    }
    return clear;
  }, [
    filesAreProcessing,
    keepSessionAlive,
    newDocsExist,
    uploadsStarted,
    uploadsStopped,
  ]);

  const renderTransactionTypeControl = useCallback(() => (
    <LabelLayout label="Transaction Type" space="small" isRequired>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        row
        name="controlled-radio-buttons-group"
        style={{ justifyContent: 'flex-end', gap: 50 }}
        value={transactionType}
        onChange={e => {
          const transactionTypeValue = e.target.value;

          setTransactionType(transactionTypeValue);
          dispatch(change(formName, 'transactionType', transactionTypeValue));

          if (transactionTypeValue === 'cash') {
            dispatch(change(formName, 'minimumBidCarry', ''));
            dispatch(untouch(formName, 'minimumBidCarry'));
          }

          if (transactionTypeValue === 'carry') {
            dispatch(change(formName, 'minimumBid', ''));
            dispatch(untouch(formName, 'minimumBid'));
          }
        }}
      >
        <FormControlLabel
          value="cash"
          control={(
            <Radio style={{
              color: cyan[800],
              '&.MuiChecked': {
                color: cyan[600],
              },
            }}
            />
          )}
          label="Cash"
        />
        <FormControlLabel
          value="carry"
          control={(
            <Radio style={{
              color: cyan[800],
              '&.MuiChecked': {
                color: cyan[600],
              },
            }}
            />
          )}
          label="Carry"
        />
        <FormControlLabel
          value="either"
          control={(
            <Radio style={{
              color: cyan[800],
              '&.MuiChecked': {
                color: cyan[600],
              },
            }}
            />
          )}
          label="Either"
        />
      </RadioGroup>
    </LabelLayout>
  ), [transactionType, formName, dispatch]);

  const { setModal } = useModalSetter();
  const windowWidth = useWindowWidth();
  const isLaptop = useMemo(() => windowWidth < 1130, [windowWidth]);
  const hasRequiredUpload = AWSData.some(document => document.type === ListingDocumentType.NEW_LISTING_CLOSING_DOCUMENT);

  useEffect(
    () =>
      // TODO: [UX][INTEGRITY] also handle clearing previous data when changing core props but not unmounting
      () => dispatch(clearAWSData()),
    [clearAWSData, dispatch],
  );

  useEffect(() => {
    if (isBasedOnExisting && !isForCreate && currentProperty) {
      const { startTime, endTime } = existingPropertyDateRangeGenerator(
        currentProperty.startTime,
        currentProperty.endTime,
      );
      dispatch(change(formName, 'startTime', startTime));
      dispatch(change(formName, 'endTime', endTime));
    }
  }, [currentProperty, formName, isBasedOnExisting, isForCreate, dispatch]);

  useEffect(() => {
    setIsAnonymous(!!isBasedOnExisting && !!currentProperty?.isAnonymous);
  }, [currentProperty, isBasedOnExisting]);

  const handleToggleCheckbox = () => {
    setIsAnonymous(prevChecked => !prevChecked);
  };

  const createOnSubmitHandler = ({ createWithPendingUpdate = false }) => async ({
    startTime,
    endTime,
    minimumBid,
    minimumBidCarry,
    wells,
    ...data
  }) => {
    const netAfe = computeWellsNetAfe(
      wells.map(well => ({ wellNetAFE: maskedAmountToNumber(well.wellNetAFE) })),
    ) ?? currentProperty?.netAfe;

    if (!hasRequiredUpload) {
      handleOpenErrorModal();
      return;
    }

    if (unloadedFilesExist && !createWithPendingUpdate) {
      handleOpenConfirmModal();
      return;
    }

    let formData = {};
    const documents = AWSData.map(({
      id,
      filename,
      key,
      type,
    }, index) => {
      const doc = {
        id,
        filename,
        key,
        type,
      };
      const comment = data.documents?.[index]?.comment;
      if (comment !== undefined) {
        doc.comment = comment;
      }
      return doc;
    });

    if (hasBeenApproved) {
      formData = {
        documents: documents.filter(document => !document.id),
      };
    } else {
      formData = {
        ...data,
        id: isForCreate ? undefined : data.id,
        netAfe,
        startTime: toAuctionEventTime(new Date(startTime), 'start').toJSDate().toISOString(),
        endTime: toAuctionEventTime(new Date(endTime), 'end').toJSDate().toISOString(),
        transactionType,
        documents,
        isAnonymous,
        wells: wells.map(item => ({
          ...item,
          id: isForCreate ? undefined : item.id,
          workingInterestPercentage: maskedAmountToNumber(item.workingInterestPercentage),
          netRevenueInterestNumber: maskedAmountToNumber(item.netRevenueInterestNumber),
          wellTotalVerticalDepth: maskedAmountToNumber(item.wellTotalVerticalDepth),
          wellNetAFE: maskedAmountToNumber(item.wellNetAFE),
          wellGrossAfe: maskedAmountToNumber(item.wellGrossAfe),
          minimumBid: item.minimumBid ? maskedAmountToNumber(item.minimumBid) : 0,
          minimumBidCarry: item.minimumBidCarry ? maskedAmountToNumber(item.minimumBidCarry) : 0,
        })),
      };
    }

    await onSubmit(formData);
  };

  const wells = formValues?.wells;
  const wellsCoordinates = useMemo(() => coordinatesGenerator(wells), [wells]);

  const [wellsAugmented, setWellsAugmented] = useState([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // TODO: add property data to well
      setWellsAugmented(wells?.map(well => ({ ...well })));
    }, 1000);
    return () => clearTimeout(timeout);
  }, [wells]);

  /*
  const wellsAverageWorkingInterestPercentage = useMemo(
    () => computeWellsAverageWorkingInterestPercentage(
      (wells || []).filter(well => maskedAmountToNumber(well.workingInterestPercentage) !== undefined)
        .map(well => ({
          workingInterestPercentage: maskedAmountToNumber(well.workingInterestPercentage),
        })),
    ),
    [wells],
  );
  */

  const totalWellGrossAfe = useMemo(
    () => computeWellsGrossAfe(wells?.map(well => ({ wellGrossAfe: maskedAmountToNumber(well.wellGrossAfe) }))),
    [wells],
  );

  /*
  const minimumBidCarryPercentage = maskedAmountToNumber(formValues?.minimumBidCarry) ?? 0;

  const minimumBidWorkingInterest = useMemo(
    () => computeAcquiredRetainedInterest({
      bidCarryPercentage: minimumBidCarryPercentage,
      sellerOriginalOwnershipPercentage: wellsAverageWorkingInterestPercentage,
    }),
    [minimumBidCarryPercentage, wellsAverageWorkingInterestPercentage],
  );
  */

  // TODO: [UX][INTEGRITY] update the minStartDate when the current day changes in the Non-Op Wells timezone
  const minStartDate = useMemo(() => {
    if (isForCreate) {
      return minStartTimeForListing();
    }
    return existingPropertyDateRangeGenerator(
      currentProperty?.startTime,
      currentProperty?.endTime,
    ).startTime;
  }, [currentProperty, isForCreate]);
  const maxStartDate = new Date(9999, 11, 31);

  const startTime = formValues?.startTime;
  const minEndDate = useMemo(
    () => minEndTimeForListing(startTime || minStartDate),
    [startTime, minStartDate],
  );
  const maxEndDate = new Date(9999, 11, 31);

  const endTime = formValues?.endTime;
  const auctionDuration
    = startTime
    && endTime
    && computeAuctionDurationInDays(new Date(startTime), new Date(endTime), true);

  const handleOpenConfirmModal = () => setModal(
    <SubmitConfirmationModal
      heading="Are you sure you want save, discarding one or more documents that have not been uploaded?"
      handleSubmit={handleSubmit(
        createOnSubmitHandler({ createWithPendingUpdate: true }),
      )}
    />,
  );

  const handleOpenErrorModal = () => setModal(
    <SubmitConfirmationModal
      heading="Missing Closing Document"
      message="Please upload the closing document in the required upload before saving again."
      submitButtonOnly
      confirmLabel="Close"
      handleSubmit={() => undefined}
    />,
  );

  if (isBasedOnExisting && !currentProperty) return <Spinner wrapped />;
  return (
    <FormLayout onSubmit={handleSubmit(createOnSubmitHandler({}))}>
      {children}

      <GeneralInformation
        isAnonymous={isAnonymous}
        handleToggleCheckbox={handleToggleCheckbox}
        AWSData={AWSData}
        currentPropertyHasBeenApproved={hasBeenApproved}
        currentPropertyDocuments={currentProperty?.documents}
        formValues={formValues}
        getBasins={getBasins}
        getCounties={getCounties}
        getLandingZones={getLandingZones}
        getPreSignedUrls={getPreSignedUrls}
        preSignedUrls={preSignedUrls}
        removeAWSDataFile={removeAWSDataFile}
        setAWSData={setAWSData}
        setBasinId={setBasinId}
        setStateId={setStateId}
        setUnloadedFilesExist={setUnloadedFilesExist}
        setUploading={setUploading}
        states={states}
        onSetAreFilesProcessing={setFilesAreProcessing}
      >
        <>
          {renderTransactionTypeControl()}

          <Grid className={classes.dateContainer}>
            <div style={{ width: '60%' }}>
              <div>
                <label htmlFor="startTime" className={classes.label}>
                  Start of Bidding
                  {' '}
                  <span>*</span>
                </label>
              </div>
              <label htmlFor="startTime" className={classes.description}>
                Bidding starts at
                {' '}
                {toAuctionEventTime(new Date(), 'start').toFormat('t ZZZZ')}
                {' '}
                on the selected date (or as soon as the listing is approved
                if that time has already passed)
              </label>
            </div>

            <div style={{ width: '20%' }}>
              <Field
                name="startTime"
                component={DatePicker}
                props={{
                  minDate: minStartDate,
                  maxDate: maxStartDate,
                  timeZone: NONOPWELLS_TIME_ZONE,
                }}
              />
            </div>
          </Grid>

          <Grid className={classes.dateContainer}>
            <div style={{ width: '60%' }}>
              <div>
                <label htmlFor="endTime" className={classes.label}>
                  End of Bidding
                  {' '}
                  <span>*</span>
                </label>
              </div>
              <label htmlFor="endTime" className={classes.description}>
                Bidding ends at
                {' '}
                {toAuctionEventTime(new Date(), 'end').toFormat('t ZZZZ')}
                {' '}
                on the selected date
              </label>
            </div>
            <div style={{ width: '20%' }}>
              <Field
                style={{ width: '17%' }}
                name="endTime"
                component={DatePicker}
                props={{
                  minDate: minEndDate,
                  maxDate: maxEndDate,
                  timeZone: NONOPWELLS_TIME_ZONE,
                }}
              />
            </div>
          </Grid>

          {auctionDuration && (
            <Grid
              container
              alignItems="flex-start"
              className={classes.wrapper}
            >
              <Grid
                container
                item
                xs={false}
                sm={false}
                md={false}
                lg={4}
                xl={4}
              />
              <Grid container item xs={12} sm={10} md={10} lg={8} xl={8}>
                <label className={classes.label}>
                  Duration:
                  {' '}
                  {auctionDuration.toFormat("d'D' hh'H' mm'M'")}
                </label>
              </Grid>
            </Grid>
          )}
        </>
      </GeneralInformation>

      {!hasBeenApproved && (
        <div className={classes.detailsContainer} style={{ minWidth: '' }}>
          <MainSection heading="Well Details" fullWidth>
            <hr className={classes.hr} />
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className={classes.container}
              >
                <LabelLayout label="Operator Name" space="small" isRequired>
                  <Field
                    name="operatorName"
                    component={BaseInput}
                    props={{ placeholder: 'Operator Name' }}
                  />
                </LabelLayout>
              </Grid>
            </Grid>
            <Grid container>
              <div style={{ width: '50%', minHeight: '600px' }}>
                <Grid className={classes.container}>
                  <FieldArray
                    transactionType={transactionType}
                    component={LandingZone}
                    formValues={formValues}
                    landingZones={landingZones}
                    name="wells"
                    rerenderOnEveryChange={false}
                  />
                </Grid>
              </div>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className={classes.container}
              >
                <Grid item className={classes.map}>
                  {isUsingEsriMaps() ? (
                    <EsriMap wells={wellsAugmented} />
                  ) : (
                    <GoogleMap wells={wellsCoordinates} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </MainSection>
        </div>
      )}

      {!hasBeenApproved && (
        <div className={classes.detailsContainer}>
          <MainSection heading="Listing Details" fullWidth>
            <hr className={classes.hr} />
            <Grid style={{ width: '50%' }}>
              <LabelLayout
                label="Total Listing Gross AFE"
                space="small"
                multiple={isLaptop}
              >
                <AmountInput
                  disabled
                  input={{
                    value: totalWellGrossAfe ?? 0,
                    disabled: true,
                  }}
                />
              </LabelLayout>

              <LabelLayout
                label="Total Listing Net AFE"
                space="small"
                multiple={isLaptop}
                disabled
              >
                <Field
                  name="netAfe"
                  component={AmountInput}
                  disabled
                  input={{
                    disabled: true,
                    value: totalWellNetAFE,
                  }}
                />
              </LabelLayout>

              <LabelLayout
                label="Restricted Companies"
                space="small"
                multiple={isLaptop}
              >
                <Field
                  name="restrictedCompanyIds"
                  component={CompaniesInput}
                  props={{
                    companies: listingOwner
                      ? companies.filter(
                        company => company.id !== listingOwner.companyId,
                      )
                      : companies,
                    showDeactivated: true,
                    loading: isLoadingCompanies,
                    placeholder: 'restrict a company...',
                  }}
                />
              </LabelLayout>
            </Grid>
            <Grid className={classes.buttonContainer}>
              <Button
                type="submit"
                fullWidth
                data-cy="listingSubmitButton"
                label={
                    uploading
                      ? 'Uploading, please wait'
                      : `${
                        isForCreate
                          ? 'Create'
                          : 'Update'
                      } Listing`
                  }
                buttonColor="green"
                disabled={uploading}
              />
            </Grid>
            <span className={classes.requiredText}>* Required field</span>
          </MainSection>
        </div>
      )}

      {hasBeenApproved && (
        <Grid
          container
          alignItems="flex-start"
          className={classes.buttonContainer}
        >
          <Grid container item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Button
              type="submit"
              data-cy="listingSubmitButton"
              label={uploading ? 'Uploading, please wait' : `${isForCreate ? 'Create' : 'Update'} Listing`}
              buttonColor="green"
              fullWidth
              disabled={uploading}
            />
          </Grid>
        </Grid>
      )}
    </FormLayout>

  );
};

ListingForm.propTypes = {
  listingOwner: PropTypes.objectOf(PropTypes.any),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      fullLegalCompanyName: PropTypes.string.isRequired,
    }),
  ),
  currentProperty: PropTypes.object,
  formName: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
  getBasins: PropTypes.func.isRequired,
  getCounties: PropTypes.func.isRequired,
  getLandingZones: PropTypes.func.isRequired,
  /**
   * Injected by reduxForm for signaling form submission
   */
  handleSubmit: PropTypes.func,
  isBasedOnExisting: PropTypes.bool,
  isForCreate: PropTypes.bool,
  isLoadingCompanies: PropTypes.bool,
  landingZones: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, title: PropTypes.string }),
  ),
  setBasinId: generalInformationTypes.setBasinId.isRequired,
  setStateId: generalInformationTypes.setStateId.isRequired,
  states: generalInformationTypes.states.isRequired,
  AWSData: generalInformationTypes.AWSData.isRequired,
  getPreSignedUrls: generalInformationTypes.getPreSignedUrls.isRequired,
  preSignedUrls: generalInformationTypes.preSignedUrls,
  removeAWSDataFile: generalInformationTypes.removeAWSDataFile.isRequired,
  setAWSData: generalInformationTypes.setAWSData.isRequired,
  setUnloadedFilesExist:
    generalInformationTypes.setUnloadedFilesExist.isRequired,
  unloadedFilesExist: PropTypes.bool,
  clearAWSData: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  keepSessionAlive: PropTypes.func,
};

ListingForm.defaultProps = {
  preSignedUrls: undefined,
  listingOwner: undefined,
  keepSessionAlive: undefined,
};

const withForm = Component => props => {
  const {
    currentProperty, formName, isBasedOnExisting, isForCreate,
  } = props;
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    let initialValues = {};
    if (isBasedOnExisting) {
      if (currentProperty) {
        const {
          wells,
          startTime,
          endTime,
          restrictedCompanies,
          ...propertyData
        } = currentProperty;

        let formattedWells = [];
        const formatWell = ({ landingZone, ...wellData }) => ({
          landingZone: landingZone.id,
          ...wellData,
        });
        if (isForCreate) {
          // omit the well id
          formattedWells = wells.map(({ id, ...wellData }) => formatWell(wellData));
        } else {
          formattedWells = wells.map(formatWell);
        }

        initialValues = {
          ...propertyData,
          wells: formattedWells,
          startTime: toAuctionEventTime(
            new Date(startTime),
            'start',
          ).toJSDate(),
          endTime: toAuctionEventTime(new Date(endTime), 'end').toJSDate(),
          restrictedCompanyIds:
            restrictedCompanies
            && restrictedCompanies.map(company => company.id),
        };
      }
    }
    if (isForCreate) {
      const startTime = defaultStartTimeForListing();
      const endTime = defaultEndTimeForListing(startTime);

      initialValues = {
        ...initialValues,
        transactionType: 'cash',
        startTime,
        endTime,
      };
    }
    setInitialValues(initialValues);
  }, [isBasedOnExisting, isForCreate, currentProperty]);

  return <Component form={formName} initialValues={initialValues} {...props} />;
};

const Component = compose(
  withForm,
  reduxForm({
    enableReinitialize: true,
    validate: createPropertyValidate,
  }),
  warnAboutUnsavedForm,
  connect((state, { formName }) => ({
    formValues: getFormValues(formName)(state),
  })),
  withStyles(styles),
)(ListingForm);

export default Component;
