export const PERMISSION_ROUTE = '/permission';
export const REGISTRATION_ROUTE = '/registration';
export const BUSINESS_ADDRESS_ROUTE = '/business';
export const ENTITY_DESCRIPTION_ROUTE = '/business/description';
export const NEW_BUSINESS_ACCOUNT_ROUTE = '/business/new-account';
export const PUBLIC_COMPANY_QUESTIONNAIRE_ROUTE = '/business/public-company';
export const BENEFICIAL_OWNER_QUESTIONNAIRE_ROUTE = '/business/beneficial-owner-questionnaire';
export const BENEFICIAL_OWNER_ACCOUNT_INFORMATION_ROUTE = '/business/beneficial-owner-account';
export const UPLOAD_COMPANY_FILES_ROUTE = '/business/upload-files';
export const LIST_OFFICERS_ROUTE = '/business/list-officers';
export const BUYER_AGREEMENT_ROUTE = '/business/buyer-agreement';
export const FINANCING_INFORMATION_ROUTE = '/business/financing-information';
export const DASHBOARD = '/dashboard';
export const COMPANY_DASHBOARD = '/company-dashboard';
export const BUYER_DASHBOARD = '/buyer-dashboard';
export const SELLER_DASHBOARD = '/seller-dashboard';
export const SELLER_AGREEMENT_ROUTE = '/business/seller-agreement';
export const CERTIFY_INFO_ROUTE = '/business/certify-info';
export const BENEFICIAL_OWNER_UPLOADS = '/business/upload-beneficial-owner-files';
export const APPROVAL_STATUS_ROUTE = '/approval-status';

// Pages are positioned in the order they are displayed in the registration flow.

export const registrationFlow = [
  {
    description: 'userAccountManagerPermission',
    route: PERMISSION_ROUTE,
  },
  {
    description: 'userRegistration',
    route: REGISTRATION_ROUTE,
  },
  {
    description: 'newBusinessAccountInfo',
    route: NEW_BUSINESS_ACCOUNT_ROUTE,
  },
  {
    description: 'businessAddress',
    route: BUSINESS_ADDRESS_ROUTE,
  },
  {
    description: 'entityDescription',
    route: ENTITY_DESCRIPTION_ROUTE,
  },
  {
    description: 'publicCompanyQuestionnaire',
    route: PUBLIC_COMPANY_QUESTIONNAIRE_ROUTE,
  },
  {
    description: 'beneficialOwnerQuestionnaire',
    route: BENEFICIAL_OWNER_QUESTIONNAIRE_ROUTE,
  },
  {
    description: 'beneficialOwnerAccountInformation',
    route: BENEFICIAL_OWNER_ACCOUNT_INFORMATION_ROUTE,
  },
  {
    description: 'approvalStatus',
    route: APPROVAL_STATUS_ROUTE,
  },
];

/**
 * TODO: [CLARIFY][REFACTOR] change `state` from a boolean to an optional string called `submitOption` that is
 * one of a set of constants indicating which "submit option" was submitted on a page which can branch into
 * different registration sub-flows. Some examples of valid values for `submitOption` could be 'yes', 'no', and 'skip'.
 */
export const navigateToNextPage = (currentRoute, state) => {
  const currentRouteIndex = registrationFlow.findIndex(page => page.route === currentRoute);

  if (currentRoute === PUBLIC_COMPANY_QUESTIONNAIRE_ROUTE) {
    if (state) {
      // If the company is public, jump to upload files page.
      return APPROVAL_STATUS_ROUTE;
    }
    return BENEFICIAL_OWNER_QUESTIONNAIRE_ROUTE;
  }
  if (currentRoute === BENEFICIAL_OWNER_QUESTIONNAIRE_ROUTE) {
    if (state) {
      // If company has an Ultimate Beneficial Owner that owns 25% or more of the company, go to BO account information page.
      return BENEFICIAL_OWNER_ACCOUNT_INFORMATION_ROUTE;
    }
    return APPROVAL_STATUS_ROUTE;
  }
  const nextPageIndex = currentRouteIndex + 1;
  const nextPage = registrationFlow[nextPageIndex];
  if (nextPage) {
    return nextPage.route;
  }
  return null;
};

export const navigateToPreviousPage = currentRoute => {
  const currentRouteIndex = registrationFlow.findIndex(page => page.route === currentRoute);
  const previousPageIndex = currentRouteIndex - 1;
  const nextPage = registrationFlow[previousPageIndex];
  if (nextPage) {
    return nextPage.route;
  }
  return '';
};
