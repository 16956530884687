import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import styles from './styles';

const currentYear = new Date().getFullYear();

const PrivacyPolicy = ({ classes }) => (
  <Grid container justify="center">
    <Grid container alignItems="center" justify="center" className={classes.heading}>
      <span>Privacy Policy (as of March 2022)</span>
    </Grid>

    <Grid container item xs={12} sm={12} md={8} lg={8} justify="left" className={classes.textContainer}>
      <p className={classes.text}>
        <b>Our commitment to privacy</b>
        <br />
        <br />
        NonOpWells, Inc. and its broker-dealer partner PEP Advisory, LLC (collectively referred to herein as “NOW”, “NonOpWells”, “we”, “us”, “our”, or the “Company”) are committed to maintaining the confidentiality, integrity, and security of personal information about our current and prospective users. In this policy, personal information means information about an individual and/or entity that is collected or maintained for business purposes and by which the individual and/or entity can be identified.
        <br />
        <br />
        The privacy policy of NOW is reviewed annually. Our notices are then updated to reflect any changes.
      </p>

      <p className={classes.text}>
        <b>NonOpWells privacy policy</b>
        <br />
        <br />
        <b>How and why we obtain personal information</b>
        <br />
        <br />
        NOW takes great pride in our efforts to protect your personal information and will only use it with respect for your privacy. We may use personal information about you to service, maintain, and protect your account; review, approve, and oversee transactions on the platform; respond to inquiries from you; develop, offer, and deliver products and services; or to fulfill legal and regulatory requirements. NOW may collect public and non-public personal information about you from any of the following sources:
        <br />
        <br />
        <li className={classes.list}>You or your entity on applications or forms (for example, name, address, Social Security number, birth date, assets and income)</li>
        <li className={classes.list}>Transactional activity in your account (for example, listing and bidding history and available funds)</li>
        <li className={classes.list}>Other interactions with NOW (for example, discussions with our customer service staff or sales representatives)</li>
        <li className={classes.list}>Information from government and regulatory agencies (for example, to assess your eligibility under the Bank Secrecy Act, USA Patriot Act, AML programs, customer identification programs, beneficial ownership requirements, Office of Foreign Asset Control, and other watchlists required for compliance with SEC laws and FINRA guidelines)</li>
        <li className={classes.list}>Information from other third-party data services (for example, to verify your identity)</li>
        <li className={classes.list}>You regarding your preferences (for example, your areas of interest or communication options)</li>
        <li className={classes.list}>Other sources with your consent (for example, from other institutions which you allow us to verify available funding source)</li>
        <br />
        Please note that information by which you cannot be identified (for example, anonymous or aggregated information) is not considered personal information and therefore is not subject to this privacy policy.
      </p>

      <p className={classes.text}>
        <b>How we protect information about you</b>
        <br />
        <br />
        NOW considers the protection of personal information to be a foundation of user trust and a sound business practice. We employ physical, electronic and procedural controls and we regularly adapt these controls to respond to changing requirements and advances in technology. At NOW, we restrict access to personal information to those who require it to develop, support, offer and deliver products and services to you.
      </p>

      <p className={classes.text}>
        <b>How we share information about you with third parties</b>
        <br />
        <br />
        NOW does not share or sell personal information about our users with unaffiliated third parties for use in marketing their products and services. We may share personal information with the following entities:
        <br />
        <br />
        <li className={classes.list}>Third-party service providers with your consent (for example, escrow.com to expedite the closing process)</li>
        <li className={classes.list}>Government agencies, other regulatory bodies and law enforcement officials (for example, for tax purposes, for entity verification, or for reporting suspicious transactions)</li>
        <li className={classes.list}>Other organizations as permitted or required by law (for example for fraud prevention or to respond to a subpoena)</li>
        <br />
        Our service providers are obligated to keep the personal information we share with them confidential and use it only to provide services specified by NOW.
      </p>

      <p className={classes.text}>
        <b>Your digital privacy</b>
        <br />
        <br />
        Privacy, security, and service in NOW's online operations are critical to the success of our business. We use firewall barriers, encryption techniques, and authentication procedures, among other controls, to maintain the security of your online session and to protect NOW accounts and systems from unauthorized access.
        <br />
        <br />
        When you interact with us by using our website, NOW manages personal information in accordance with all of the practices and safeguards described previously.
        <br />
        <br />
        When you use our platform, we may collect technical and navigational and location information, such as device type, browser type, Internet protocol address, pages visited, and average time spent on our platform. We use this information for a variety of purposes, such as maintaining the security of your session, facilitating site navigation, improving NOW website design and functionality, and personalizing your experience. Additionally, the following policies and practices apply when you are online.
      </p>

      <p className={classes.text}>
        <b>Cookies and similar technologies</b>
        <br />
        <br />
        NOW and our third-party service providers may use cookies and similar technologies to support the operation of our platform. Cookies are small amounts of data that a website or online service exchanges with a web browser or application on a visitor's device (for example, computer, tablet, or mobile phone). Cookies help us to collect information about users of our platform, including date and time of visits, pages viewed, amount of time spent using our platform, or general information about the device used to access our platform. NOW cookies are also used for security purposes and to personalize your experience.
        <br />
        <br />
        You can refuse or delete cookies. Most browsers and mobile devices offer their own settings to manage cookies. If you refuse a cookie, or if you delete cookies from your device, you may experience some inconvenience in your use of our platform. For example, you may not be able to sign in and access your account, or we may not be able to recognize you, your device, or your online preferences. Both NOW and third-party service providers we hire may use cookies and other technologies, such as web beacons, pixel tags, or mobile device ID, in online advertising as described below.
      </p>

      <p className={classes.text}>
        <b>Protecting children's privacy online</b>
        <br />
        <br />
        The NOW platform is not directed to individuals under the age of eighteen (18). Access to minors is strictly prohibited. NOW does not intentionally collect information from those we know are under 18, and we request that these individuals do not provide personal information through the platform.
      </p>

      <p className={classes.text}>
        <b>Additional information</b>
        <br />
        <br />
        If you are a former user, these policies also apply to you; we treat your information with the same care as we do information about current users.
        <br />
        <br />
        NOW offers several options for accessing and, if necessary, correcting your account information. You can review your information using your profile page, by emailing at contact@nonopwells.com, by calling 832-900-3055, or through our
        {' '}
        <a href="/contact-us">contact us</a>
        {' '}
        portal. You may also write to us with your request for information at the below address:
        <br />
        <br />
        NonOpWells, Inc.
        <br />
        <br />
        Attn: Customer Service
        <br />
        <br />
        1334 Brittmoore Road
        <br />
        <br />
        Suite 2603
        <br />
        <br />
        Houston, TX 77043
      </p>

      <p className={classes.text}>
        <b>Additional Information for California Residents</b>
        <br />
        <br />
        Please view our additional information for California residents. This additional information is provided for purposes related to the California Consumer Privacy Act of 2018, as amended (the “CCPA”) and applies to the personal information and NOW which are subject to CCPA.
        <br />
        <br />
        The NonOpWells privacy policy is provided on behalf of NonOpWells, Inc.
        <br />
        <br />
        Effective March 2022
        <br />
        <br />
        ©2020-
        {currentYear}
        {' '}
        NonOpWells, Inc. All Rights Reserved.
      </p>
    </Grid>
  </Grid>
);

PrivacyPolicy.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), memo)(PrivacyPolicy);
