import styles from '../ImpliedClosingFees/styles';

export default {
  header: styles.header,
  description: {
    ...styles.descriptionContainer,
    fontWeight: '500',
    fontSize: '24px',
  },
};
