import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import styles from './styles';
import { updateUser, updateUserInfo } from 'store/actions/userActions';
import Onboarding from 'layouts/Onboarding';
import { useHistory } from 'react-router-dom';
import { navigateToNextPage } from 'constants/registrationFlow';

function PermissionsView({ classes, user }) {
  const [permission, setPermission] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = async () => {
    await new Promise((resolve, reject) => dispatch(updateUserInfo({ resolve, reject })));
    history.push(navigateToNextPage(history.location.pathname));
  };

  return (
    <Onboarding
      title="Register Account"
      subTitle="Enter your details to proceed further"
      nextDisabled={!user.isAccountManager}
      dotNavigationLegend={1}
      nextClick={handleClick}
    >
      <div className={classes.root}>
        <Grid
          container
          justifyContent="center"
          direction="column"
        >
          <Typography variant="h5" component="div" className={classes.subTitle}>
            Will you be the account manager responsible for your entity?
          </Typography>
          <div className={classes.optionContainer}>
            <Button
              className={classes.button}
              data-cy="permissionButtonNo"
              variant="outlined"
              onClick={() => {
                setPermission(true);
                dispatch(updateUser({ value: false, key: 'isAccountManager' }));
              }}
            >
              No
            </Button>
            <Button
              className={classes.button}
              data-cy="permissionButtonYes"
              variant="outlined"
              style={user.isAccountManager ? { backgroundColor: '#7890A6', color: '#fff' } : {}}
              onClick={() => {
                setPermission(false);
                dispatch(updateUser({ value: true, key: 'isAccountManager' }));
              }}
            >
              Yes
            </Button>
          </div>
          {permission && (
          <div className={classes.errorContainer}>
            <Typography
              variant="h5"
              component="div"
              className={classes.subTitle}
            >
              Looks like you are not authorized
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={classes.subText}
            >
              Please coordinate with your team as only authorized signers are
              allowed to complete the initial registration of your entity.
            </Typography>
            <Button
              className={classes.button}
              variant="contained"
              href="/contact-us"
            >
              Contact Us
            </Button>
          </div>
          )}
        </Grid>
      </div>
    </Onboarding>
  );
}

PermissionsView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  user: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(connect(({ user }) => ({ user })), withStyles(styles))(PermissionsView);
