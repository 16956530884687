import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React, {
  memo, useEffect, useState, useMemo, useCallback, useRef,
} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryPie,
} from 'victory';
import ReactContainerDimensions from 'react-container-dimensions';
import queryString from 'query-string';
import cx from 'classnames';
import Api from '../../api/onboarding';
import { isUsingRegistrationToggles } from 'now-frontend-shared/features/feature-flags';

// components
import ProfileSection from 'layouts/ProfileSection';
import Spinner from 'now-frontend-shared/components/Spinner';
import StyledLink from 'pages/Profile/components/StyledLink';
import Text from 'pages/Profile/components/Text';
import SellerDashboard from './components/SellerDashboard';
import Switch from 'now-frontend-shared/components/Switch';

// styles and components from material-ui
import { withStyles, useTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Divider from '@material-ui/core/Divider';
import {
  User as UserIcon,
  TrendingUp as TrendingUpIcon,
  Briefcase as BriefcaseIcon,
  Settings as SettingsIcon,
} from 'react-feather';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Pagination from '@material-ui/lab/Pagination';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';
import RejectedIcon from '@material-ui/icons/Block';
import AddIcon from '@material-ui/icons/Add';
import StarIcon from '@material-ui/icons/Star';
import { documentTypes } from 'now-shared/helpers/document-types';
import ClickOrHoverTooltip from 'now-frontend-shared/components/Tooltips/ClickOrHoverTooltip';

// styles
import { red } from '../../themes/colors';
import styles from './styles';
import { lightGrey } from 'themes/colors';
import newTheme from 'themes/theme';
import {
  desktopContentMinWidthExclusive,
  mainPageContentBackgroundColor,
} from 'layouts/styles';

// helpers
import { formatNumberToCurrency } from 'now-frontend-shared/utils/helpers';
import {
  hasPaymentNotInitiatedTransaction,
  doesListingHaveTransactionInProgress,
  isListingTransactionComplete,
} from 'now-shared/helpers/escrow-transaction-helpers';
import { pluralize } from 'now-shared/helpers/text-helpers';
import { getUserDisplayName } from 'utils/user-helpers';
import {
  getCompanyName,
  buyerAgreementName,
  sellerAgreementName,
} from 'now-shared/helpers/company-helpers';
import { isAdminOrCompliancePerson, isViewOnlyUser } from 'now-shared/validation/admin-upsert-user';
import { computeWellsGrossAfe } from 'now-shared/helpers/listing-helpers';

// NOTE: uncomment for testing
// import { computeCommission, computeTotalListingCost } from 'now-shared/helpers/bid-helpers';

// store
import { getMyBidAllowanceDetails } from 'store/actions/myBidAllowanceActions';
import { getProperties, getStatuses } from 'store/actions/propertiesActions';
import {
  clearUsersInCompany,
  findUsersByCompany,
} from 'store/actions/companiesActions';
import { SortDirection } from 'now-shared/enums/sort-direction';
import {
  viewOnlyListingEditorRoleTitle,
  viewOnlyRoleTitle,
} from 'now-shared/config/role-constants';
import UnfinishedBanner from './components/SellerDashboard/components/UnfinishedBanner';
import {
  BENEFICIAL_OWNER_UPLOADS,
  BUYER_AGREEMENT_ROUTE,
  BUYER_DASHBOARD,
  CERTIFY_INFO_ROUTE,
  COMPANY_DASHBOARD,
  DASHBOARD,
  FINANCING_INFORMATION_ROUTE,
  LIST_OFFICERS_ROUTE,
  SELLER_AGREEMENT_ROUTE,
  SELLER_DASHBOARD,
  UPLOAD_COMPANY_FILES_ROUTE,
} from 'constants/registrationFlow';
import WelcomeModal from './components/SellerDashboard/components/WelcomeModal';
import OnboardingModal from './components/SellerDashboard/components/OnboardingModal';
import { canCompanyInfoBeCertified } from 'now-shared/validation/company-certification';
import { updateEnableTraderRegistration } from 'store/actions/companyActions';

// constants
import { ApprovalStatus } from 'now-shared/validation/approval-status';
import { EnableDisable, BuyerSeller } from 'now-shared/validation/companyEnableTraderRegistration';
/** TODO:
1. Create a consolidated constants folder and file in shared
2. Make constants' variables all caps (ApprovalStatus)
3. Change ApprovalStatus constants in this file to use updated constants
*/

const minSpaceBetweenNavigationCards = 4;
const minSpaceBetweenNavigationCardsHalf = minSpaceBetweenNavigationCards / 2;
const cardPadding = 20;
export const cardRadius = 4;
export const cardElevation = 1;

export const firstMainRowMarginSides = 32;
export const firstMainRowHeight = 297;
export const firstMainRowGap = 28;
export const firstMainRowGapHalf = firstMainRowGap / 2;

const listingsBoxHeight = 572;
const listingsBoxMarginTop = 30;

const increaseBidAllowancePlusIconSize = 30;

/**
 * @param {number} plusSize width/height of plus sign
 * @returns {number}
 */
function calculatePlusIconFontSize(plusSize) {
  return plusSize * (52 / 30);
}

const disabledColorLightGrey = lightGrey;

export const loadingMessage = 'loading...';
export const notApplicableMessage = 'n/a';

export const Subtitle1 = props => (
  <Typography
    // TODO: [DRY][REFACTOR] change subtitle1 style to match the following custom styles?
    variant="subtitle1"
    style={{
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '28px',
      letterSpacing: 0.15,
    }}
    {...props}
  />
);

export const Subtitle2 = props => (
  <Typography
    // TODO: [DRY][REFACTOR] change subtitle2 style to match the following custom styles?
    variant="subtitle2"
    style={{
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '28px',
      letterSpacing: 0.1,
    }}
    {...props}
  />
);

const NavigationCard = props => {
  const {
    Icon, title, selected, onClick,
  } = props;

  const theme = useTheme();

  const fontColor = selected
    ? theme.palette.primary.contrastText
    : theme.palette.primary.dark;
  const iconSize = 20;

  return (
    <Card
      elevation={1}
      style={{
        backgroundColor: selected
          ? theme.palette.primary.main
          : theme.palette.primary.contrastText,
        color: fontColor,
        width: 400,
        height: 72,
        borderRadius: 30,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: minSpaceBetweenNavigationCardsHalf,
        marginRight: minSpaceBetweenNavigationCardsHalf,
      }}
    >
      <CardActionArea
        onClick={onClick}
        // TODO: [UX] when selected, disable hover effect but keep click ripple effect?
        disabled={selected}
        style={{
          height: '100%',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{
            margin: 0,
            textAlign: 'center',
            padding: 8,
          }}
        >
          <Icon
            style={{
              width: iconSize,
              height: iconSize,
              fontSize: iconSize,
            }}
          />
          <Typography
            variant="h6"
            style={{
              textTransform: 'uppercase',
            }}
          >
            {title}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};

const BidSummary = ({ bid }) => (
  <Text>
    {formatNumberToCurrency(bid.bidAmount)}
    {' '}
    +
    {' '}
    {formatNumberToCurrency(bid.closingFees)}
    {' '}
    closing fees :
    {' '}
    <StyledLink path={`/listings/${bid.property.id}`}>
      {bid.property.projectName}
    </StyledLink>
    {bid.status.title !== 'winner' ? ` (${bid.status.title})` : ''}
  </Text>
);

BidSummary.propTypes = {
  bid: PropTypes.shape({
    bidAmount: PropTypes.number.isRequired,
    netAfe: PropTypes.number.isRequired,
    closingFees: PropTypes.number.isRequired,
    property: PropTypes.shape({
      id: PropTypes.number.isRequired,
      projectName: PropTypes.string.isRequired,
      wells: PropTypes.number.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const BidsSection = ({ bids, heading, loadingOrNotApplicableMessage }) => (
  <ProfileSection heading={heading}>
    {(() => {
      let result;
      if (bids) {
        if (bids.length) {
          result = bids.map(bid => <BidSummary key={bid.id} bid={bid} />);
        } else {
          result = <Text>-</Text>;
        }
      } else {
        result = <Text>{loadingOrNotApplicableMessage}</Text>;
      }
      return result;
    })()}
  </ProfileSection>
);

BidsSection.propTypes = {
  bids: PropTypes.arrayOf(BidSummary.propTypes.bid).isRequired,
  heading: PropTypes.string.isRequired,
  loadingOrNotApplicableMessage: PropTypes.string.isRequired,
};

const bidSorts = [
  {
    id: 'auctionEnd',
    title: 'Auction End',
    sort: (a, b) => a.property.endTime.localeCompare(b.property.endTime),
    defaultDirection: 'desc',
  },
  {
    id: 'bidDate',
    title: 'Bid Date',
    sort: (a, b) => a.createdAt.localeCompare(b.createdAt),
    defaultDirection: 'desc',
  },
  {
    id: 'projectName',
    title: 'Project Name',
    sort: (a, b) => a.property.projectName.localeCompare(b.property.projectName),
    defaultDirection: 'asc',
  },
  {
    id: 'state',
    title: 'State',
    sort: (a, b) => a.property.state.title.localeCompare(b.property.state.title),
    defaultDirection: 'asc',
  },
  {
    id: 'operator',
    title: 'Operator',
    sort: (a, b) => a.property.operatorName.localeCompare(b.property.operatorName),
    defaultDirection: 'asc',
  },
  {
    id: 'totalCost',
    title: 'Total Cost',
    sort: (a, b) => a.total - b.total,
    defaultDirection: 'desc',
  },
  {
    id: 'netAfe',
    title: 'Net AFE Amount',
    sort: (a, b) => a.netAfe - b.netAfe,
    defaultDirection: 'desc',
  },
];

function getBidSortOptionById(id) {
  return bidSorts.find(option => option.id === id);
}

const TabStyled = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    '&$selected': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  selected: {},
}))(props => <Tab {...props} />);

const bidsTabs = [
  {
    id: 'winnerBidsCompleted',
    label: (
      <span>
        Listings Won
        <br />
        (Escrow Complete)
      </span>
    ),
  },
  {
    id: 'winnerBidsInProgress',
    label: (
      <span>
        Listings Won
        <br />
        (In Progress)
      </span>
    ),
  },
  {
    id: 'outstandingBids',
    label: 'Outstanding Bids',
  },
];

const BidsDetailsLists = props => {
  const {
    isApplicable,
    outstandingBids,
    winnerBidsInProgress,
    winnerBidsCompleted,
  } = props;

  const theme = useTheme();
  const history = useHistory();

  const [selectedBidsTab, setSelectedBidsTab] = useState(0);
  const [selectedBidsTabList, setSelectedBidsTabList] = useState(undefined);
  const [sortedBidsTabList, setSortedBidsTabList] = useState(undefined);
  const [bidsPageNumber, setBidsPageNumber] = useState(1);

  const bidsTabListVisibleRowCount = 4;
  const totalBids = selectedBidsTabList?.length || 0;
  const totalBidPages = Math.ceil(totalBids / bidsTabListVisibleRowCount);
  const bidsPageIndex = bidsPageNumber - 1;
  const firstVisibleBidIndex = bidsPageIndex * bidsTabListVisibleRowCount;
  const endVisibleBidIndex = Math.min(
    firstVisibleBidIndex + bidsTabListVisibleRowCount,
    totalBids,
  );

  const handleChangeBidsTab = (_event, value) => {
    setSelectedBidsTab(value);
  };

  const defaultSortOption = getBidSortOptionById('auctionEnd');
  const [bidsSortValue, setBidsSortValue] = useState(defaultSortOption.id);
  const [bidsSortDirection, setBidsSortDirection] = useState(
    defaultSortOption.defaultDirection,
  );

  useEffect(() => {
    let bids;
    const bidsTab = bidsTabs[selectedBidsTab];
    if (bidsTab) {
      switch (bidsTab.id) {
        case 'winnerBidsCompleted':
          bids = winnerBidsCompleted;
          break;
        case 'winnerBidsInProgress':
          bids = winnerBidsInProgress;
          break;
        case 'outstandingBids':
          bids = outstandingBids;
          break;
        default:
          break;
      }
    }
    setSelectedBidsTabList(bids);
  }, [
    selectedBidsTab,
    winnerBidsInProgress,
    winnerBidsCompleted,
    outstandingBids,
  ]);

  useEffect(() => {
    setBidsPageNumber(1);
    let bids = selectedBidsTabList;
    if (bids) {
      const sortOption = getBidSortOptionById(bidsSortValue);
      bids = [...bids];
      bids.sort(sortOption.sort);
      if (bidsSortDirection === 'desc') {
        bids.reverse();
      }
    }
    setSortedBidsTabList(bids);
  }, [selectedBidsTabList, bidsSortValue, bidsSortDirection]);

  const loadingOrNotApplicableMessage = isApplicable
    ? loadingMessage
    : notApplicableMessage;

  return (
    <Grid
      item
      xs={12}
      style={{
        paddingTop: listingsBoxMarginTop,
        paddingLeft: firstMainRowGapHalf,
        paddingRight: firstMainRowGapHalf,
      }}
    >
      <Card
        raised
        elevation={cardElevation}
        style={{
          borderRadius: cardRadius,
          height: listingsBoxHeight,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            display="flex"
            style={{
              width: '100%',
            }}
          >
            <Tabs
              value={selectedBidsTab}
              onChange={handleChangeBidsTab}
              variant="fullWidth"
              style={{
                width: '100%',
              }}
              TabIndicatorProps={{
                style: {
                  background: theme.palette.primary.dark,
                },
              }}
            >
              {bidsTabs.map(bidsTab => (
                <TabStyled key={bidsTab.id} label={bidsTab.label} />
              ))}
            </Tabs>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            style={{
              marginLeft: cardPadding,
              marginRight: cardPadding,
              marginBottom: 9,
              overflow: 'hidden',
              width: '100%',
            }}
          >
            <Box
              display="flex"
              justifyContent="flex-end"
              style={{
                width: '100%',
              }}
            >
              <Box
                display="flex"
                style={{
                  paddingRight: cardPadding,
                  paddingTop: 12,
                }}
              >
                <FormControl>
                  <Select
                    id="bids-sort-select"
                    value={bidsSortValue}
                    onChange={event => {
                      const sortValue = event.target.value;
                      const sortOption = getBidSortOptionById(sortValue);
                      setBidsSortDirection(sortOption.defaultDirection);
                      setBidsSortValue(sortValue);
                    }}
                  >
                    {bidSorts.map(sortOption => (
                      <MenuItem key={sortOption.id} value={sortOption.id}>
                        {sortOption.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              flexGrow={1}
              style={{
                width: '100%',
                paddingLeft: cardPadding,
                paddingRight: cardPadding,
                paddingTop: 12,
                paddingBottom: 12,
                overflow: 'hidden',
              }}
            >
              {(() => {
                let result;
                if (sortedBidsTabList) {
                  if (sortedBidsTabList.length) {
                    result = sortedBidsTabList
                      .slice(firstVisibleBidIndex, endVisibleBidIndex)
                      .map((bid, i) => (
                        <Card
                          key={bid.id}
                          raised
                          elevation={cardElevation}
                          style={{
                            width: '100%',
                            /**
                             * minHeight must be set to height, otherwise height will be ignored in some cases
                             */
                            minHeight: 90,
                            height: 90,
                            boxSizing: 'border-box',
                            marginTop: i && 13,
                            borderRadius: cardRadius,
                          }}
                        >
                          <CardActionArea
                            onClick={() => history.push(`/listings/${bid.property.id}`)}
                            style={{
                              width: '100%',
                              height: '100%',
                            }}
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              style={{
                                padding: 20,
                                width: '100%',
                                height: '100%',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <Box
                                display="flex"
                                flexGrow={1}
                                flexDirection="column"
                                justifyContent="center"
                                style={{
                                  height: '100%',
                                  boxSizing: 'border-box',
                                  overflow: 'hidden',
                                }}
                              >
                                <Box
                                  style={{
                                    overflow: 'hidden',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {bid.property.projectName}
                                    {' '}
                                    (
                                    {`${bid.property.wells} well${pluralize(
                                      's',
                                      bid.property.wells,
                                    )}`}
                                    )
                                  </Typography>
                                </Box>
                                <Box
                                  style={{
                                    overlow: 'hidden',
                                  }}
                                >
                                  <Typography
                                    color="textSecondary"
                                    style={{
                                      fontSize: 13.5,
                                      fontWeight: 400,
                                      lineHeight: '24px',
                                      letterSpacing: 0.08,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {bid.property.county.title}
                                    {', '}
                                    {bid.property.state.title}
                                    {' - '}
                                    {bid.property.operatorName}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                style={{
                                  /**
                                   * minWidth must be set to width, otherwise width will be ignored in some cases
                                   */
                                  minWidth: 130,
                                  width: 130,
                                  height: '100%',
                                  boxSizing: 'border-box',
                                  overflow: 'hidden',
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6">
                                  {formatNumberToCurrency(
                                    Math.round(bid.total),
                                  )}
                                </Typography>
                                <Subtitle2>Total Cost</Subtitle2>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                style={{
                                  width: 137,
                                  minWidth: 137,
                                  height: '100%',
                                  marginLeft: 17,
                                  boxSizing: 'border-box',
                                  overflow: 'hidden',
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6">
                                  {formatNumberToCurrency(
                                    Math.round(bid.netAfe),
                                  )}
                                </Typography>
                                <Subtitle2>Net AFE Amount</Subtitle2>
                              </Box>
                            </Box>
                          </CardActionArea>
                        </Card>
                      ));
                  } else {
                    result = 'none';
                  }
                } else {
                  result = loadingOrNotApplicableMessage;
                }
                return result;
              })()}
            </Box>
            <Box display="flex" justifyContent="center">
              <Pagination
                count={totalBidPages}
                page={bidsPageNumber}
                onChange={(_event, page) => setBidsPageNumber(page)}
              />
            </Box>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

const ActionsRequired = props => {
  const { actionsRequired, isApplicable } = props;

  const theme = useTheme();
  const history = useHistory();

  const [actionsRequiredPageNumber, setActionsRequiredPageNumber] = useState(1);

  useEffect(() => {
    if (actionsRequired) {
      setActionsRequiredPageNumber(1);
    }
  }, [actionsRequired]);

  const loadingOrNotApplicableMessage = isApplicable
    ? loadingMessage
    : notApplicableMessage;

  const actionsRequiredVisibleRowCount = 3;
  const totalActionsRequired = actionsRequired?.length || 0;
  const totalActionsRequiredPages = Math.ceil(
    totalActionsRequired / actionsRequiredVisibleRowCount,
  );
  const actionsRequiredPageIndex = actionsRequiredPageNumber - 1;
  const firstVisibleActionsRequiredIndex
    = actionsRequiredPageIndex * actionsRequiredVisibleRowCount;
  const endVisibleActionsRequiredIndex = Math.min(
    firstVisibleActionsRequiredIndex + actionsRequiredVisibleRowCount,
    totalActionsRequired,
  );

  return (
    <Card
      raised
      elevation={cardElevation}
      style={{
        marginTop: 18,
        borderRadius: cardRadius,
        marginLeft: firstMainRowGapHalf,
        marginRight: firstMainRowGapHalf,
        height: 375,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{
            width: '100%',
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
            padding: 12,
          }}
        >
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 500,
              letterSpacing: 0.4,
              textTransform: 'uppercase',
            }}
          >
            Action Required
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          style={{
            marginLeft: cardPadding,
            marginRight: cardPadding,
            marginBottom: 9,
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            flexGrow={1}
            style={{
              width: '100%',
              paddingLeft: cardPadding,
              paddingRight: cardPadding,
              paddingTop: 12,
              paddingBottom: 12,
              overflow: 'hidden',
            }}
          >
            {(() => {
              let result;
              // TODO: [SIMPLIFY] `if (actionsRequired?.length) {`
              if (actionsRequired) {
                if (actionsRequired.length) {
                  result = actionsRequired
                    .slice(
                      firstVisibleActionsRequiredIndex,
                      endVisibleActionsRequiredIndex,
                    )
                    .map((bid, i) => (
                      <Card
                        key={bid.id}
                        raised
                        elevation={cardElevation}
                        style={{
                          width: '100%',
                          /**
                           * minHeight must be set to height, otherwise height will be ignored in some cases
                           */
                          minHeight: 80,
                          height: 80,
                          boxSizing: 'border-box',
                          marginTop: i && 12,
                          borderRadius: cardRadius,
                        }}
                      >
                        <CardActionArea
                          onClick={() => history.push(`/listings/${bid.property.id}`)}
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            style={{
                              paddingTop: 14,
                              paddingBottom: 14,
                              paddingLeft: 20,
                              paddingRight: 20,
                              width: '100%',
                              height: '100%',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Box
                              display="flex"
                              flexGrow={1}
                              flexDirection="column"
                              justifyContent="center"
                              style={{
                                height: '100%',
                                boxSizing: 'border-box',
                                overflow: 'hidden',
                              }}
                            >
                              <Box
                                style={{
                                  overflow: 'hidden',
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {bid.property.projectName}
                                  {' '}
                                  (
                                  {`${bid.property.wells} well${pluralize(
                                    's',
                                    bid.property.wells,
                                  )}`}
                                  )
                                </Typography>
                              </Box>
                              <Box
                                style={{
                                  overlow: 'hidden',
                                }}
                              >
                                <Typography
                                  color="textSecondary"
                                  style={{
                                    fontSize: 13.5,
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                    letterSpacing: 0.08,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {bid.property.county.title}
                                  {', '}
                                  {bid.property.state.title}
                                  {' - '}
                                  {bid.property.operatorName}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                /**
                                 * minWidth must be set to width, otherwise width will be ignored in some cases
                                 */
                                minWidth: 130,
                                width: 130,
                                height: '100%',
                                boxSizing: 'border-box',
                                overflow: 'hidden',
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6">
                                {formatNumberToCurrency(Math.round(bid.total))}
                              </Typography>
                              <Subtitle2>Total Cost</Subtitle2>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                width: 137,
                                minWidth: 137,
                                height: '100%',
                                marginLeft: 17,
                                boxSizing: 'border-box',
                                textAlign: 'center',
                                color: theme.palette.primary.main,
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: 12,
                                  fontWeight: 700,
                                  lineHeight: '22px',
                                  letterSpacing: 0.46,
                                  textTransform: 'uppercase',
                                }}
                              >
                                {bid.actionRequired}
                              </Typography>
                            </Box>
                          </Box>
                        </CardActionArea>
                      </Card>
                    ));
                } else {
                  result = 'none';
                }
              } else {
                result = loadingOrNotApplicableMessage;
              }
              return result;
            })()}
          </Box>
          <Box display="flex" justifyContent="center">
            <Pagination
              count={totalActionsRequiredPages}
              page={actionsRequiredPageNumber}
              onChange={(_event, page) => setActionsRequiredPageNumber(page)}
            />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

const NewListings = props => {
  const {
    basinsOfInterest,
    buyerAgreementApproved,
    companyIsApprovedAndActive,
    isLoadingProperties,
    propertiesList,
    propertiesMeta,
    statuses,
  } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const [pageNumber, setPageNumber] = useState(1);

  const pageItemCount = 2;

  const isApplicable
    = buyerAgreementApproved
    && !!basinsOfInterest?.length
    && companyIsApprovedAndActive;

  useEffect(() => {
    if (isApplicable) {
      dispatch(getStatuses());
    }
  }, [dispatch, isApplicable]);

  useEffect(() => {
    if (isApplicable && statuses && basinsOfInterest?.length) {
      const paramsString = queryString.stringify(
        {
          order: 'startTime',
          sort: SortDirection.Descending,
          page: pageNumber,
          limit: pageItemCount,
          basinIds: basinsOfInterest.map(basin => basin.id),
          status: statuses.find(status => status.title === 'active').id,
          // TODO: [UX] filter out listings that the user has already bid on
        },
        { arrayFormat: 'bracket' },
      );
      dispatch(getProperties(`?${paramsString}`));
    }
  }, [dispatch, basinsOfInterest, isApplicable, pageNumber, statuses]);

  const loadingOrNotApplicableMessage = isApplicable
    ? loadingMessage
    : notApplicableMessage;

  return (
    <Card
      raised
      elevation={cardElevation}
      style={{
        marginTop: 18,
        borderRadius: cardRadius,
        marginLeft: firstMainRowGapHalf,
        marginRight: firstMainRowGapHalf,
        height: 390,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{
            width: '100%',
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
            padding: 12,
          }}
        >
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 500,
              letterSpacing: 0.4,
              textTransform: 'uppercase',
            }}
          >
            New Listings in AOI
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          style={{
            marginLeft: cardPadding,
            marginRight: cardPadding,
            marginBottom: 9,
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            flexGrow={1}
            style={{
              width: '100%',
              paddingLeft: cardPadding,
              paddingRight: cardPadding,
              paddingTop: 12,
              paddingBottom: 12,
              overflow: 'hidden',
            }}
          >
            {(() => {
              let result;
              if (isApplicable && propertiesList && !isLoadingProperties) {
                if (propertiesList.length) {
                  result = (
                    <Grid
                      container
                      style={{
                        height: '100%',
                      }}
                    >
                      {propertiesList.map((property, i) => (
                        <Grid
                          item
                          xs={6}
                          key={property.id}
                          style={{
                            height: '100%',
                            paddingRight: i || 12,
                            paddingLeft: i && 12,
                          }}
                        >
                          <Card
                            raised
                            elevation={cardElevation}
                            style={{
                              width: '100%',
                              /**
                               * minHeight must be set to height, otherwise height will be ignored in some cases
                               */
                              minHeight: '100%',
                              height: '100%',
                              boxSizing: 'border-box',
                              borderRadius: cardRadius,
                            }}
                          >
                            <CardActionArea
                              onClick={() => history.push(`/listings/${property.id}`)}
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                            >
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                style={{
                                  padding: 18,
                                  width: '100%',
                                  height: '100%',
                                  whiteSpace: 'nowrap',
                                  textAlign: 'center',
                                  overflow: 'hidden',
                                }}
                              >
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  style={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    overflow: 'hidden',
                                  }}
                                >
                                  <Typography
                                    variant="h5"
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {property.projectName}
                                    <br />
                                    (
                                    {`${property.wells.length} well${pluralize(
                                      's',
                                      property.wells.length,
                                    )}`}
                                    )
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  style={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    overflow: 'hidden',
                                    marginTop: 7,
                                  }}
                                >
                                  <Typography
                                    color="textSecondary"
                                    style={{
                                      fontSize: 13.5,
                                      fontWeight: 400,
                                      lineHeight: '18px',
                                      letterSpacing: 0.08,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {property.county.title}
                                    {', '}
                                    {property.state.title}
                                    <br />
                                    {property.operatorName}
                                  </Typography>
                                </Box>
                                <Divider
                                  style={{
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    marginTop: 10,
                                    marginBottom: 10,
                                  }}
                                />
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  style={{
                                    minWidth: 169,
                                    width: '100%',
                                  }}
                                >
                                  {[
                                    {
                                      title: 'Net AFE',
                                      amount: +property.netAfe,
                                    },
                                    {
                                      title: 'Gross AFE',
                                      amount: computeWellsGrossAfe(
                                        Array.isArray(property?.wells)
                                          ? property?.wells.filter(well => !!well)
                                          : undefined,
                                      ),
                                    },
                                  ].map(datum => (
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      style={{
                                        width: '100%',
                                      }}
                                      key={datum.title}
                                    >
                                      <Box
                                        display="flex"
                                        style={{
                                          width: 60,
                                        }}
                                      >
                                        <Subtitle1>{datum.title}</Subtitle1>
                                      </Box>
                                      <Box display="flex">
                                        <Subtitle2>
                                          {formatNumberToCurrency(
                                            Math.round(datum?.amount),
                                          )}
                                        </Subtitle2>
                                      </Box>
                                    </Box>
                                  ))}
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    style={{
                                      width: '100%',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  );
                } else {
                  result = 'none';
                }
              } else {
                result = loadingOrNotApplicableMessage;
              }
              return result;
            })()}
          </Box>
          <Box display="flex" justifyContent="center">
            <Pagination
              count={propertiesMeta?.totalPages || 0}
              page={pageNumber}
              onChange={(_event, page) => setPageNumber(page)}
            />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

const NewListingsConnected = compose(
  connect(({ properties, settings }) => ({
    propertiesList: properties.propertiesList?.items,
    propertiesMeta: properties.propertiesList?.meta,
    basinsOfInterest: settings.basins,
    isLoadingProperties: properties.pending,
    statuses: properties.statuses,
  })),
)(NewListings);

const BuyerDashboard = ({
  allowanceDetails,
  allowanceDetailsLoading,
  classes,
  bankInformation,
  buyerAgreement,
  user,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();

  const companyIsApproved = !!user.company?.approved;
  const companyIsApprovedAndActive
    = companyIsApproved && !!user.company.active;

  const buyerAgreementApproved = buyerAgreement?.status.title === ApprovalStatus.Approved;

  const bankInformationApproved = bankInformation?.status.title === ApprovalStatus.Approved;

  const isApplicable
    = buyerAgreementApproved
    && bankInformationApproved
    && companyIsApprovedAndActive;

  useEffect(() => {
    if (isApplicable) {
      dispatch(getMyBidAllowanceDetails());
    }
  }, [dispatch, isApplicable]);

  const enrichedBids = allowanceDetails?.bids;

  // NOTE: uncomment for testing
  // let enrichedBids = allowanceDetails?.bids;
  // enrichedBids = useMemo(() => {
  //   let bids = enrichedBids;
  //   if (bids?.length) {
  //     const firstBid = bids[0];
  //     bids = [];
  //     const states = ['Kansas', 'Texas', 'Alabama', 'Nebraska'];
  //     const operators = ['Exxon', 'Chevron', 'Texaco'];
  //     const types = ['won', 'closed', 'outstanding'];
  //     for (let i = 1; i <= 100; i += 1) {
  //       const stateIndex = Math.floor(Math.random() * (states.length - 0.000001));
  //       const state = states[stateIndex];
  //       const operatorIndex = Math.floor(Math.random() * (operators.length - 0.000001));
  //       const operator = operators[operatorIndex];
  //       const typeIndex = Math.floor(Math.random() * (types.length - 0.000001));
  //       const type = types[typeIndex];
  //       const bidAmount = 99999999 * Math.random();
  //       const netAfe = 99999999 * Math.random();
  //       const closingFees = computeCommission({
  //         bidAmount,
  //         netAfe,
  //       });
  //       const total = computeTotalListingCost({
  //         bidAmount,
  //         closingFees,
  //       });
  //       const daysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
  //       const bidDate = new Date(Date.now() - Math.round(Math.random() * daysInMilliseconds)).toISOString();
  //       const endDate = new Date(Date.now() - Math.round(Math.random() * daysInMilliseconds)).toISOString();
  //       const testBid = {
  //         ...firstBid,
  //         id: i,
  //         bidAmount,
  //         netAfe,
  //         closingFees,
  //         total,
  //         amount: `${bidAmount}`,
  //         createdAt: bidDate,
  //         status: {
  //           /* eslint-disable no-nested-ternary */
  //           title: ['won', 'closed'].includes(type)
  //             ? 'winner'
  //             : firstBid.property.status.title === 'active'
  //               ? 'active'
  //               : 'closed',
  //           /* eslint-enable no-nested-ternary */
  //         },
  //         property: {
  //           ...firstBid.property,
  //           projectName: `Test project ${i}`,
  //           operatorName: operator,
  //           netAfe: `${netAfe}`,
  //           state: {
  //             title: state,
  //           },
  //           endTime: endDate,
  //           /* eslint-disable no-nested-ternary */
  //           transactions: type === 'won'
  //             ? [
  //               // won
  //               {
  //                 isPaymentInitiated: Math.random() > 0.5,
  //               },
  //             ]
  //             : (
  //               type === 'outstanding'
  //                 ? [
  //                   // no transactions
  //                 ]
  //                 : [
  //                   // completed
  //                   {
  //                     isPaymentInitiated: true,
  //                     completedDate: (new Date()).toISOString(),
  //                   },
  //                 ]
  //             ),
  //           /* eslint-enable no-nested-ternary */
  //         },
  //       };
  //       bids.push(testBid);
  //     }
  //   }
  //   return bids;
  // }, [enrichedBids]);

  const winnerBidsInProgress = useMemo(
    () => enrichedBids?.filter(bid => doesListingHaveTransactionInProgress(bid.property)),
    [enrichedBids],
  );

  const closedBids = useMemo(
    () => enrichedBids?.filter(
      bid => bid.status.title === 'closed' && !bid.archivedAt,
    ),
    [enrichedBids],
  );

  const activeBids = useMemo(
    () => enrichedBids?.filter(
      bid => bid.status.title === 'active' && !bid.archivedAt,
    ),
    [enrichedBids],
  );

  const outstandingBids = useMemo(() => {
    let result;
    if (activeBids && closedBids) {
      result = [...closedBids, ...activeBids];
    }
    return result;
  }, [activeBids, closedBids]);

  const winnerBidsCompleted = useMemo(
    () => enrichedBids?.filter(bid => isListingTransactionComplete(bid.property)),
    [enrichedBids],
  );

  const paymentNotInitiatedBids = useMemo(
    () => winnerBidsInProgress?.filter(bid => hasPaymentNotInitiatedTransaction(bid.property.transactions)),
    [winnerBidsInProgress],
  );

  const otherTransactionsInProgress = useMemo(
    () => winnerBidsInProgress?.filter(
      bid => !paymentNotInitiatedBids?.includes(bid),
    ),
    [winnerBidsInProgress, paymentNotInitiatedBids],
  );

  const actionsRequired = useMemo(
    () => [
      ...(paymentNotInitiatedBids || []).map(bid => ({
        ...bid,
        actionRequired: 'Pay Now',
      })),
      ...(otherTransactionsInProgress || []).map(bid => ({
        ...bid,
        // TODO: [UX][WORDING] if this a "No Escrow" transaction, change label to "Complete Transaction"
        // and make enough space in the UI layout for this longer label.
        actionRequired: 'Complete Escrow',
      })),
    ]
      .sort(getBidSortOptionById('auctionEnd').sort)
      .reverse(),
    [paymentNotInitiatedBids, otherTransactionsInProgress],
  );

  const bidAllowance = allowanceDetails?.bidAllowance;
  const bidAllowanceAvailable = allowanceDetails?.bidAllowanceAvailable;
  const loadingOrNotApplicableMessage = isApplicable
    ? loadingMessage
    : notApplicableMessage;

  const bidAllowanceFormatted = useMemo(() => {
    if (bidAllowance !== undefined) {
      return formatNumberToCurrency(bidAllowance);
    }
    return loadingOrNotApplicableMessage;
  }, [bidAllowance, loadingOrNotApplicableMessage]);

  const bidAllowanceAvailableFormatted = useMemo(() => {
    if (bidAllowanceAvailable !== undefined) {
      return formatNumberToCurrency(bidAllowanceAvailable);
    }
    return loadingOrNotApplicableMessage;
  }, [bidAllowanceAvailable, loadingOrNotApplicableMessage]);

  const availableAllowancePieData = useMemo(() => {
    const colorScale = [];
    const data = [];
    if (allowanceDetails) {
      if (
        allowanceDetails.bidAllowance
        && allowanceDetails.bidAllowanceAvailable
      ) {
        {
          const label = ['Available'];
          const total = allowanceDetails.bidAllowanceAvailable;
          const color = theme.palette.primary.light;
          data.push({
            x: label,
            y: total,
            fill: color,
          });
          colorScale.push(color);
        }
        {
          const label = ['Unavailable'];
          const total
            = allowanceDetails.bidAllowance
            - allowanceDetails.bidAllowanceAvailable;
          const color = 'rgba(0, 0, 0, 0)';
          data.push({
            x: label,
            y: total,
            fill: color,
          });
          colorScale.push(color);
        }
      }
    } else {
      const label = [loadingOrNotApplicableMessage];
      const total = 100;
      data.push({
        x: label,
        y: total,
        label: label.join('\n'),
        fill: disabledColorLightGrey,
      });
      colorScale.push(disabledColorLightGrey);
    }

    return {
      colorScale,
      data,
    };
  }, [allowanceDetails, loadingOrNotApplicableMessage, theme]);

  return (
    <Grid
      item
      xs={12}
      container
      style={{
        paddingTop: 0,
        paddingBottom: firstMainRowMarginSides,
        paddingLeft: firstMainRowMarginSides - firstMainRowGapHalf,
        paddingRight: firstMainRowMarginSides - firstMainRowGapHalf,
      }}
    >
      {allowanceDetailsLoading && <Spinner backdrop />}
      {/*
        left column
      */}
      <Grid item xs={6} container>
        {/*
          Available Allowance
        */}
        <Grid item xs={5}>
          <Card
            raised
            elevation={cardElevation}
            style={{
              borderRadius: cardRadius,
              marginLeft: firstMainRowGapHalf,
              marginRight: firstMainRowGapHalf,
              height: firstMainRowHeight,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              style={{
                padding: cardPadding,
                textAlign: 'center',
                height: '100%',
              }}
            >
              <Box display="flex">
                <Typography variant="h5">Available Allowance</Typography>
              </Box>
              <Box display="flex" flexGrow={1} overflow="hidden">
                <ReactContainerDimensions>
                  {({ height }) => (
                    <VictoryPie
                      animate={{
                        easing: 'exp',
                      }}
                      colorScale={availableAllowancePieData.colorScale}
                      data={availableAllowancePieData.data}
                      labelComponent={<span />}
                      innerRadius={65}
                      padAngle={1.5}
                      height={height}
                    />
                  )}
                </ReactContainerDimensions>
              </Box>
              <Box display="flex">
                <Typography
                  style={{
                    fontSize: 24,
                    fontWeight: 700,
                  }}
                >
                  {bidAllowanceAvailableFormatted}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>
        {/*
          Total Bids
        */}
        <Grid item xs={7}>
          <Card
            raised
            elevation={cardElevation}
            style={{
              borderRadius: cardRadius,
              marginLeft: firstMainRowGapHalf,
              marginRight: firstMainRowGapHalf,
              height: firstMainRowHeight,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              style={{
                padding: cardPadding,
                textAlign: 'center',
                height: '100%',
              }}
            >
              <Box display="flex">
                <Typography variant="h5">Total Bids</Typography>
              </Box>
              <Box
                display="flex"
                flexGrow={1}
                overflow="hidden"
                style={{
                  paddingBottom: 5,
                }}
              >
                <ReactContainerDimensions>
                  {({ width }) => (
                    // TODO: [UX] change orientation: make the bars horizontal instead of vertical
                    <VictoryChart domainPadding={width * 0.08}>
                      <VictoryAxis
                        style={{
                          axis: {
                            stroke: 'transparent',
                          },
                          ticks: {
                            stroke: 'transparent',
                          },
                          tickLabels: {
                            fill: theme.palette.text.secondary,
                            fontFamily: theme.typography.fontFamily,
                            fontSize: 16,
                            fontWeight: 400,
                          },
                        }}
                        tickLabelComponent={
                          <VictoryLabel lineHeight={1.3} renderInPortal />
                        }
                      />
                      <VictoryAxis
                        style={{
                          axis: {
                            stroke: 'transparent',
                          },
                          ticks: {
                            stroke: 'transparent',
                          },
                          tickLabels: {
                            fill: 'transparent',
                          },
                        }}
                      />
                      <VictoryBar
                        animate={{
                          easing: 'exp',
                        }}
                        style={{
                          data: {
                            fill: ({ datum }) => datum.fill,
                            width: width * 0.17,
                          },
                        }}
                        labelComponent={(
                          <VictoryLabel
                            renderInPortal
                            style={{
                              fill: theme.palette.text.secondary,
                              fontFamily: theme.typography.fontFamily,
                              fontSize: 35,
                              fontWeight: 500,
                            }}
                          />
                        )}
                        labels={({ datum }) => Math.round(datum.y).toString()}
                        data={[
                          {
                            x: 'Won\n(escrow complete)',
                            y: winnerBidsCompleted?.length || 0,
                            fill: theme.palette.primary.light,
                          },
                          {
                            x: 'Won\n(escrow in progress)',
                            y: winnerBidsInProgress?.length || 0,
                            fill: theme.palette.primary.main,
                          },
                          {
                            x: 'Outstanding',
                            y: outstandingBids?.length || 0,
                            fill: theme.palette.primary.dark,
                          },
                        ]}
                      />
                    </VictoryChart>
                  )}
                </ReactContainerDimensions>
              </Box>
            </Box>
          </Card>
        </Grid>
        {/*
          bids details lists
        */}
        <BidsDetailsLists
          isApplicable={isApplicable}
          outstandingBids={outstandingBids}
          winnerBidsInProgress={winnerBidsInProgress}
          winnerBidsCompleted={winnerBidsCompleted}
        />
      </Grid>
      {/*
        right column
      */}
      <Grid item xs={6}>
        <Box display="flex" flexDirection="column">
          {/*
            Bid Allowance
          */}
          <Card
            raised
            elevation={cardElevation}
            style={{
              height: 99,
              borderRadius: cardRadius,
              marginLeft: firstMainRowGapHalf,
              marginRight: firstMainRowGapHalf,
            }}
          >
            <Box
              style={{
                paddingLeft: cardPadding,
                paddingRight: cardPadding,
                paddingTop: 9,
                paddingBottom: 9,
                width: '100%',
                height: '100%',
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{
                  textAlign: 'center',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <Typography variant="h5">Bid Allowance</Typography>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Box display="flex">
                    <Typography
                      style={{
                        fontSize: 34,
                        fontWeight: 700,
                      }}
                    >
                      {bidAllowanceFormatted}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    style={{
                      marginLeft: 16,
                    }}
                  >
                    <IconButton
                      color="secondary"
                      aria-label="increase-bid-allowance"
                      size="small"
                      disabled={!isApplicable}
                      onClick={() => history.push('/increase-bid-allowance')}
                      style={{
                        padding: 0,
                      }}
                    >
                      <AddIcon
                        style={{
                          fontSize: calculatePlusIconFontSize(
                            increaseBidAllowancePlusIconSize,
                          ),
                          margin:
                            -(
                              calculatePlusIconFontSize(
                                increaseBidAllowancePlusIconSize,
                              ) - increaseBidAllowancePlusIconSize
                            ) / 2,
                        }}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Card>
          {/*
            Action Required
          */}
          <ActionsRequired
            actionsRequired={actionsRequired}
            isApplicable={isApplicable}
          />
          {/*
            new listings in area of interest
          */}
          <NewListingsConnected
            buyerAgreementApproved={buyerAgreementApproved}
            companyIsApprovedAndActive={companyIsApprovedAndActive}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

BuyerDashboard.propTypes = {
  allowanceDetails: PropTypes.shape({
    bids: BidsSection.propTypes.bids,
    bidAllowance: PropTypes.number.isRequired,
    bidAllowanceAvailable: PropTypes.number.isRequired,
  }),
  allowanceDetailsLoading: PropTypes.bool.isRequired,
  bankInformation: PropTypes.shape({
    status: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }),
  buyerAgreement: PropTypes.shape({
    status: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }),
  user: PropTypes.shape({
    // TODO: [TYPE] fill this out
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

BuyerDashboard.defaultProps = {
  allowanceDetails: undefined,
  bankInformation: undefined,
  buyerAgreement: undefined,
};

const BuyerDashboardConnected = compose(
  connect(({ auth, myBidAllowance }) => ({
    allowanceDetails: myBidAllowance.allowanceDetails,
    allowanceDetailsLoading: myBidAllowance.allowanceDetailsLoading,
    bankInformation: auth?.user?.company?.bankInformation,
    buyerAgreement: auth?.user?.company?.buyerAgreement,
    sellerAgreement: auth?.user?.company?.sellerAgreement,
    user: auth?.user,
  })),
  withStyles(styles),
  memo,
)(BuyerDashboard);

// Company Dashboard

const MyTooltip = props => <Tooltip placement="top" arrow {...props} />;
const MyStyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.primary.contrastText,
    // TODO: [UX][CONSISTENCY] text.primary ?
    color: theme.palette.common.black,
    boxShadow: theme.shadows[17],
    fontSize: 13,
    textAlign: 'center',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    lineHeight: '18px',
    letterSpacing: 0.16,
  },
  arrow: {
    color: theme.palette.primary.contrastText,
  },
}))(MyTooltip);
const MyWideStyledTooltip = withStyles(({
  tooltip: { maxWidth: '315px' },
}))(MyStyledTooltip);

const noJobTitleText = <em>(no job title)</em>;

const DocumentListItem = props => {
  const {
    label,
    description,
    status,
    path,
    canEdit,
    canView,
    classes,
    disabledField,
    disabledRegistration,
    ...propsRest
  } = props;

  const theme = useTheme();

  const canEditActual
    = canEdit !== undefined ? canEdit : [ApprovalStatus.Pending, ApprovalStatus.Rejected].includes(status);

  let Icon = ReportProblemOutlinedIcon;
  let iconColor = red;
  if (status === ApprovalStatus.Pending) {
    Icon = PauseCircleOutlineOutlinedIcon;
    iconColor = '#ED6C02';
  } else if (status === ApprovalStatus.Approved) {
    Icon = CheckCircleIcon;
    iconColor = theme.palette.success.main;
  } else if (status === ApprovalStatus.Rejected) {
    Icon = RejectedIcon;
    iconColor = theme.palette.error.main;
  }

  const documentSubmitted = !!status;

  const greyOutColor38 = theme.palette.text.disabled;
  const greyOutColor26 = 'rgba(0, 0, 0, 0.26)';
  const greyOutBox = {
    borderColor: 'rgba(0, 0, 0, 0.23)',
    background: 'rgba(0, 0, 0, 0.12)',
  };
  const greyOutBorder = { borderColor: disabledField ? greyOutColor38 : iconColor };

  return (
    <ClickOrHoverTooltip
      changeToClick={documentSubmitted}
      title={(
        documentSubmitted
          ? 'Please contact us to request changes to these documents'
          : (description || '')
      )}
    >
      <ListItem
        className={classes.listItem}
        style={disabledRegistration ? greyOutBox : greyOutBorder}
        {...propsRest}
      >
        <MyStyledTooltip
          {...(propsRest['data-cy'] && {
            'data-cy': `${propsRest['data-cy']}Status`,
          })}
          title={status || 'missing'}
        >
          <ListItemIcon className={classes.listItemIcon}>
            {/* No icon displayed for disabled registration */}
            {!disabledRegistration && (
              <Icon
                htmlColor={disabledField ? greyOutColor38 : iconColor}
                style={{
                  fontSize: 20,
                }}
              />
            )}
          </ListItemIcon>
        </MyStyledTooltip>
        <ListItemText
          style={{
            ...(disabledField || disabledRegistration)
            && { color: greyOutColor38 },
          }}
        >
          {label}
          <br />
          {(!documentSubmitted || canView) && (
          <StyledLink
            path={path}
            style={{
              ...(disabledField || disabledRegistration)
              && { fontSize: 16, color: greyOutColor26 },
            }}
            disabled={disabledField || disabledRegistration}
          >
            {documentSubmitted ? ((canEditActual && 'edit') || 'view') : 'submit'}
            {' '}
            document
          </StyledLink>
          )}
        </ListItemText>
      </ListItem>
    </ClickOrHoverTooltip>
  );
};

DocumentListItem.propTypes = {
  canView: PropTypes.bool,
};

DocumentListItem.defaultProps = {
  canView: true,
};

const OrganizationUserCard = prop => {
  const { user, theme } = prop;

  const { isAccountManager, isAuthorizedSigner, isViewOnlyListingEditor }
    = user;

  return (
    <Box
      display="flex"
      style={{
        width: 272,
        height: 72,
      }}
    >
      <ListItem
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.action.hover,
          borderRadius: 8,
        }}
      >
        {isAuthorizedSigner && (
          <MyStyledTooltip title="Authorized Signer">
            <ListItemIcon>
              <StarIcon
                style={{
                  color: theme.palette.primary.main,
                }}
              />
            </ListItemIcon>
          </MyStyledTooltip>
        )}
        <MyStyledTooltip
          title={
            (isAccountManager && 'Account Manager')
            || (isAuthorizedSigner && 'Authorized Signer')
            || (isViewOnlyListingEditor && viewOnlyListingEditorRoleTitle)
            || (isViewOnlyUser(user) && viewOnlyRoleTitle)
            || 'Unknown Role'
          }
        >
          <ListItemText
            inset={!isAuthorizedSigner}
            primary={getUserDisplayName(user)}
            secondary={user.jobTitle || noJobTitleText}
          />
        </MyStyledTooltip>
      </ListItem>
    </Box>
  );
};

const accountManagerCardMargin = 50;
const authorizedSignerCardMargin = accountManagerCardMargin;
const organizationUserCardMargin = accountManagerCardMargin;
const organizationUserRowMargin = 17;
const organizationSectionMargin = 52;

const CompanyDashboard = ({
  classes,
  sellerAgreement,
  buyerAgreement,
  bankInformation,
  user,
  allUsersInCompany,
  isLoadingAllUsersInCompany,
  company,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [onboardingStep, setOnboardingStep] = useState(null);
  const onBoardingStepsLegend = ['general', 'seller', 'buyer'];
  const [onboardingStatus, setOnboardingStatus] = useState(false);

  const userIsAdminOrCompliancePerson = isAdminOrCompliancePerson(user);
  const companyIsApproved = !!user.company?.approved;

  // The condition below is TRUE once the seller agreement status is defined (pending, approved, rejected)
  const sellerAgreementSubmitted = !!sellerAgreement?.status.title;
  const buyerAgreementOrBankSubmitted = !!buyerAgreement?.status.title || !!bankInformation?.status.title;
  const sellerAgreementIsApproved = sellerAgreement?.status.title === ApprovalStatus.Approved;
  const buyerAgreementAndBankApproved = (buyerAgreement?.status.title === ApprovalStatus.Approved)
    && (bankInformation?.status.title === ApprovalStatus.Approved);
  const [sellerToggleDisabled, setSellerToggleDisabled] = useState(sellerAgreementSubmitted);
  const [sellerToggleOn, setSellerToggleOn] = useState(user?.company?.enableSellerRegistration);
  const [buyerToggleDisabled, setBuyerToggleDisabled] = useState(buyerAgreementOrBankSubmitted);
  const [buyerToggleOn, setBuyerToggleOn] = useState(user?.company?.enableBuyerRegistration);

  useEffect(() => {
    if (!open) {
      setOnboardingStep(0);
    }
  }, [open]);

  const handleToggleTraderRegistration = (booleanValue, registrationType) => {
    const enableDisable = booleanValue ? EnableDisable.ENABLE : EnableDisable.DISABLE;
    const sellerRegistration = registrationType === BuyerSeller.SELLER;
    const buyerRegistration = registrationType === BuyerSeller.BUYER;
    // Only one registration type can be toggled off at a time
    if (sellerRegistration && !buyerToggleOn) return;
    if (buyerRegistration && !sellerToggleOn) return;

    if (!company?.toggleRequestPending.enableSellerRegistration
      && !company?.toggleRequestPending.enableBuyerRegistration) {
      dispatch(updateEnableTraderRegistration({
        companyId: user?.companyId,
        enableOrDisable: enableDisable,
        buyerOrSeller: registrationType,
      }));
      // optimistically update the toggle state
      if (sellerRegistration) setSellerToggleOn(booleanValue);
      if (buyerRegistration) setBuyerToggleOn(booleanValue);
    }
  };

  useEffect(() => {
    if (sellerToggleOn !== user?.company?.enableSellerRegistration) {
      setSellerToggleOn(user?.company?.enableSellerRegistration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.company?.enableSellerRegistration]);

  useEffect(() => {
    if (sellerAgreementSubmitted) {
      setSellerToggleDisabled(true);
      if (!user?.company?.enableSellerRegistration) {
        handleToggleTraderRegistration(true, BuyerSeller.SELLER);
      }
    } else setSellerToggleDisabled(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerAgreementSubmitted]);

  useEffect(() => {
    if (buyerToggleOn !== user?.company?.enableBuyerRegistration) {
      setBuyerToggleOn(user?.company?.enableBuyerRegistration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.company?.enableBuyerRegistration]);

  useEffect(() => {
    if (buyerAgreementOrBankSubmitted) {
      setBuyerToggleDisabled(true);
      if (!user?.company?.enableBuyerRegistration) {
        handleToggleTraderRegistration(true, BuyerSeller.BUYER);
      }
    } else setBuyerToggleDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerAgreementOrBankSubmitted]);

  const fontColor = theme.palette.primary.dark;
  const iconSize = 20;

  const incorporationFile = user?.company?.documents?.find(
    v => v.type === documentTypes.CERTIFIED_ARTICLES_OF_INCORPORATION,
  );
  const formationFile = user?.company?.documents?.find(
    v => v.type === documentTypes.FORM_W9,
  );
  const officerAgreement = user?.company?.documents?.find(
    v => v.type === documentTypes.OFFICER_CORPORATE_AGREEMENT,
  );
  const beneficialOwnerAgreement = user?.company?.documents?.find(
    v => v.type === documentTypes.BENEFICIAL_OWNER_FILES,
  );

  const canCertifyInfoComplete = canCompanyInfoBeCertified(user?.company);

  const companyApprovalStatus = companyIsApproved ? ApprovalStatus.Approved : ApprovalStatus.Pending;

  const certifiedArticlesOfIncorporationStatus
    = incorporationFile && formationFile && companyApprovalStatus;

  const isApplicable = !!user.company;

  const isLoading = isLoadingAllUsersInCompany;

  const loadingOrNotApplicableMessage = isApplicable
    ? loadingMessage
    : notApplicableMessage;

  const topCardsHeight = 347;

  // NOTE: remove except for testing
  // allUsersInCompany = useMemo(() => {
  //   let result = [];
  //   const count = 8;
  //   for (let i = 0; i < count; ++i) {
  //     result.push({
  //       id: i,
  //       firstName: 'Ross',
  //       lastName: 'Adamson',
  //       isAccountManager: i % (Math.round(count / 1)) === 0,
  //       isAuthorizedSigner: i % 3 === 0,
  //       jobTitle: i % 4 ? 'My Job Title' : null,
  //     });
  //   }
  //   return result;
  // }, []);

  const GeneralSection = ({
    height,
    width,
    x,
    y,
    isForWalkthrough,
  }) => (
    <div
      {...height !== undefined && {
        style: {
          height,
          width,
          top: y,
          left: x,
          paddingLeft: '10px',
        },
      }}
      className={cx({ [classes.onBoardingGeneral]: isForWalkthrough })}
    >
      <Typography
        variant="body1"
        className={classes.listItemTitle}
      >
        General
      </Typography>
      <List className={classes.list}>
        <DocumentListItem
          classes={classes}
          data-cy="certifiedArticlesDocumentListItem"
          label="Formation Document & Executed W-9"
          description="Please submit the two listed files."
          status={certifiedArticlesOfIncorporationStatus}
          path={UPLOAD_COMPANY_FILES_ROUTE}
          canEdit={false}
        />
        <DocumentListItem
          classes={classes}
          data-cy="accountManagerDocumentListItem"
          label="Identity of Account Manager (or Corporate Officer) & Authorization Letter"
          description="Please submit identity of the account manager and list any corporate officers."
          status={officerAgreement && companyApprovalStatus}
          path={LIST_OFFICERS_ROUTE}
          canEdit={false}
          canView={false}
        />
        <DocumentListItem
          classes={classes}
          data-cy="certificationOfCompleteDocumentListItem"
          label="Certification of Complete and Correct Information"
          description="You must sign off on all documents for your company."
          status={
            user.company.certification && companyApprovalStatus
          }
          path={CERTIFY_INFO_ROUTE}
          canEdit={false}
          canView={false}
          disabledField={!canCertifyInfoComplete}
        />
        {company.BO25PercentOfCompanyOrMore && (
          <DocumentListItem
            classes={classes}
            data-cy="beneficialOwnerDocumentListItem"
            label="Ultimate Beneficial Owner Details"
            description="You must submit "
            status={
              beneficialOwnerAgreement && companyApprovalStatus
            }
            path={BENEFICIAL_OWNER_UPLOADS}
            canEdit={false}
          />
        )}
      </List>
    </div>
  );

  const SellerSection = ({
    height,
    width,
    x,
    y,
    isForWalkthrough,
  }) => {
    const disabledSellerRegistration = !sellerToggleOn && !sellerToggleDisabled;
    // The seller registration toggle feature is currently hidden.
    // To show the feature, add the flag "?useRegistrationToggles=true" add the end of the URL.
    const showSellerRegistrationToggle = !sellerAgreementSubmitted && buyerToggleOn && isUsingRegistrationToggles();

    return (
      <div
        {...height !== undefined && {
          style: {
            height,
            width,
            top: y,
            left: x,
            paddingLeft: '10px',
          },
        }}
        className={cx({ [classes.onBoardingGeneral]: isForWalkthrough })}
      >
        <Grid
          container
          display="flex"
          flexDirection="row"
          alignItems="center"
          height="100%"
        >
          <Typography
            variant="body1"
            className={classes.listItemTitle}
          >
            Seller
          </Typography>
          { showSellerRegistrationToggle && (
            <MyWideStyledTooltip
              title="If you do not intend to register your entity as a seller account type, you may disable these fields"
            >
              <Box className={classes.switchContainer}>
                <Switch
                  key="sellerRegistrationSwitch"
                  name="sellerRegistrationSwitch"
                  label=""
                  disabled={sellerToggleDisabled}
                  checked={sellerToggleOn}
                  onChangeNotificationsValue={() => handleToggleTraderRegistration(!sellerToggleOn, BuyerSeller.SELLER)}
                />
              </Box>
            </MyWideStyledTooltip>
          )}
        </Grid>
        <List className={classes.list}>
          <DocumentListItem
            classes={classes}
            data-cy="sellerAgreementDocumentListItem"
            label={sellerAgreementName}
            description="You must submit the Seller's Agreement before you can sell your listings"
            status={sellerAgreement?.status.title}
            disabledRegistration={disabledSellerRegistration}
            path={SELLER_AGREEMENT_ROUTE}
            canEdit={false}
          />
        </List>
      </div>
    );
  };

  const BuyerSection = ({
    height,
    width,
    x,
    y,
    isForWalkthrough,
  }) => {
    const disabledBuyerRegistration = !buyerToggleOn && !buyerToggleDisabled;
    // The buyer registration toggle feature is currently hidden.
    // To show the feature, add the flag "?useRegistrationToggles=true" add the end of the URL.
    const showBuyerRegistrationToggle = !buyerAgreementOrBankSubmitted && sellerToggleOn && isUsingRegistrationToggles();

    return (
      <div
        {...height !== undefined && {
          style: {
            height,
            width,
            top: y,
            left: x,
            paddingLeft: '10px',
          },
        }}
        className={cx({ [classes.onBoardingGeneral]: isForWalkthrough })}
      >
        <Grid
          container
          display="flex"
          flexDirection="row"
          alignItems="center"
          height="100%"
        >
          <Typography
            variant="body1"
            className={classes.listItemTitle}
          >
            Buyer
          </Typography>
          { showBuyerRegistrationToggle && (
            <MyWideStyledTooltip
              title="If you do not intend to register your entity as a buyer account type, you may disable these fields"
            >
              <Box className={classes.switchContainer}>
                <Switch
                  key="buyerRegistrationSwitch"
                  name="buyerRegistrationSwitch"
                  label=""
                  disabled={buyerToggleDisabled}
                  checked={buyerToggleOn}
                  onChangeNotificationsValue={() => handleToggleTraderRegistration(!buyerToggleOn, BuyerSeller.BUYER)}
                />
              </Box>
            </MyWideStyledTooltip>
          )}
        </Grid>
        <List className={classes.list}>
          <DocumentListItem
            classes={classes}
            data-cy="buyerAgreementDocumentListItem"
            label={buyerAgreementName}
            description="You must agree to the Buyer's Agreement before you can bid on listings"
            status={buyerAgreement?.status.title}
            disabledRegistration={disabledBuyerRegistration}
            path={BUYER_AGREEMENT_ROUTE}
            canEdit={false}
          />
          <DocumentListItem
            classes={classes}
            data-cy="financingInformationDocumentListItem"
            label="Financing Information"
            description="You must submit your financial information and request a bid allowance before you can bid on listings"
            status={bankInformation?.status.title}
            disabledRegistration={disabledBuyerRegistration}
            path={FINANCING_INFORMATION_ROUTE}
            canEdit={false}
          />
          {/* TODO: [FEATURE] increase-bid-allowance request */}
        </List>
      </div>
    );
  };

  useEffect(() => {
    if (isApplicable) {
      dispatch(findUsersByCompany());
    } else {
      dispatch(clearUsersInCompany());
    }
  }, [dispatch, isApplicable]);

  const generalRef = useRef(null);
  const sellerRef = useRef(null);
  const buyerRef = useRef(null);

  const headerElement = document.getElementById('header')?.getBoundingClientRect()?.height || 0;
  const stepsData = [{
    right: generalRef?.current?.getBoundingClientRect().right || 0,
    y: (generalRef?.current?.getBoundingClientRect().y || 0) + window.scrollY,
    height: generalRef?.current?.clientHeight || 0,
    x: generalRef?.current?.getBoundingClientRect().x - 10 || 0,
    width: generalRef?.current?.clientWidth + 10 || 0,
    title: 'General',
    content: `The fields outlined in red in the “General” column are required prior to
    accessing the rest of the platform. These fields will turn orange while
    we are reviewing the documents and green once approved.`,
  },
  {
    right: sellerRef?.current?.getBoundingClientRect().right || 0,
    y: (sellerRef?.current?.getBoundingClientRect().y || 0) + window.scrollY,
    height: sellerRef?.current?.clientHeight || 0,
    x: sellerRef?.current?.getBoundingClientRect().x - 10 || 0,
    width: sellerRef?.current?.clientWidth + 10 || 0,
    title: 'Seller',
    content: `The fields outlined in red in the “Seller” column are required prior to
    listing and selling on the platform. These fields will turn orange while
    we are reviewing your documents and green once approved. If you
    intend to participate as a buyer only, please adjust the toggle to
    disabled.`,
  },
  {
    right: buyerRef?.current?.getBoundingClientRect().right || 0,
    y: (buyerRef?.current?.getBoundingClientRect().y || 0) + window.scrollY,
    height: buyerRef?.current?.clientHeight || 0,
    x: buyerRef?.current?.getBoundingClientRect().x - 10 || 0,
    width: buyerRef?.current?.clientWidth + 10 || 0,
    title: 'Buyer',
    content: `The fields outlined in red in the “Buyer” column are required before
    submitting bids on the platform. These fields will turn orange while we
    are reviewing your documents and green once approved. If you intend
    to participate as a seller only, please adjust the toggle to disabled.`,
  },
  ];
  const currentStep = stepsData[onboardingStep];

  useEffect(() => {
    if (onboardingStep === 3) {
      Api.updateCompanyOnboarding({ url: `/onboarding/${user.companyId}` });
    }
  }, [onboardingStep, user.companyId]);

  useEffect(() => {
    const fetchOnboarding = async () => {
      if (user.companyId) {
        const data = await Api.getCompanyOnboarding({ url: `/onboarding/${user.companyId}` });
        if (!data.data) {
          setOnboardingStatus(true);
        }
      }
    };
    fetchOnboarding();
  }, [user.companyId]);

  const generalRegistrationComplete = canCertifyInfoComplete && user?.company?.certification;

  const buyerAndSellerRegistrationComplete = (
    // Company is an approved buyer and seller, only seller, or only buyer
    // If either buyer or seller are toggled off, the other must be approved
    (sellerAgreementIsApproved && buyerAgreementAndBankApproved)
    || (sellerAgreementIsApproved && !buyerToggleOn)
    || (buyerAgreementAndBankApproved && !sellerToggleOn)
  );

  const sellerAgreementApprovalPending = sellerAgreement?.status.title === ApprovalStatus.Pending;
  const buyerAndOrBankApprovalPending = (buyerAgreement?.status.title === ApprovalStatus.Pending
    && [
      ApprovalStatus.Pending,
      ApprovalStatus.Approved,
    ].includes(bankInformation?.status.title))
    || (buyerAgreement?.status.title === ApprovalStatus.Approved
      && bankInformation?.status.title === ApprovalStatus.Pending);

  let registrationApprovalPending = false;

  const showUnfinishedBanner = () => {
    if (userIsAdminOrCompliancePerson) {
      return false;
    }

    if (!generalRegistrationComplete) return true;

    // Company has approval pending for both buyer and seller, only seller, or only buyer
    // Pending status is only shown if there is no other incomplete registration
    if (
      (sellerAgreementApprovalPending && buyerAndOrBankApprovalPending)
      || (sellerAgreementApprovalPending && (buyerAgreementAndBankApproved || !buyerToggleOn))
      || (buyerAndOrBankApprovalPending && (sellerAgreementIsApproved || !sellerToggleOn))
    ) registrationApprovalPending = true;

    if (!buyerAndSellerRegistrationComplete) return true;

    return false;
  };

  return (
    <Grid item xs={12} container>

      {showUnfinishedBanner() && (<UnfinishedBanner pending={registrationApprovalPending} />)}
      {showUnfinishedBanner() && onboardingStatus && <WelcomeModal open={open} setOpen={setOpen} />}
      {Number.isInteger(onboardingStep) && onboardingStep < onBoardingStepsLegend.length && onboardingStatus ? (
        <OnboardingModal
          open={onboardingStep}
          title={currentStep.title}
          content={currentStep.content}
          setOpen={() => setOnboardingStep(onboardingStep + 1)}
          right={currentStep.right}
          y={currentStep.y}
        >
          {onboardingStep === 0 && (
          <GeneralSection
            isForWalkthrough
            width={currentStep.width}
            height={currentStep.height}
            x={currentStep.x}
            y={currentStep.y - headerElement || 0}
          />
          )}

          {onboardingStep === 1 && (
          <SellerSection
            isForWalkthrough
            width={currentStep.width}
            height={currentStep.height}
            x={currentStep.x}
            y={currentStep.y - headerElement || 0}
          />
          )}

          {onboardingStep === 2 && (
          <BuyerSection
            isForWalkthrough
            width={currentStep.width}
            height={currentStep.height}
            x={currentStep.x}
            y={currentStep.y - headerElement || 0}
          />
          )}

        </OnboardingModal>
      ) : null}
      {isLoading && <Spinner backdrop />}
      {/*
        Documents and Agreements
      */}
      <Grid item xs={9}>
        <Card raised elevation={cardElevation}>
          <Box
            style={{
              paddingTop: cardPadding,
              paddingBottom: cardPadding,
              paddingLeft: 34,
              paddingRight: 34,
              width: '100%',
              height: '100%',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              style={{
                textAlign: 'left',
                height: '100%',
                overflow: 'auto',
              }}
            >
              <Typography
                variant="h5"
                style={{
                  fontWeight: 700,
                }}
              >
                Documents &amp; Agreements
              </Typography>
              {/*
                TODO: [LAYOUT] convert to Grid with 3 columns and 3 rows
              */}
              {(() => {
                if (!user.company) {
                  return notApplicableMessage;
                }
                if (user.isAccountManager) {
                  return (
                    <>
                      <div ref={generalRef}>
                        <GeneralSection />
                      </div>
                      <div ref={sellerRef}>
                        <SellerSection />
                      </div>
                      <div ref={buyerRef}>
                        <BuyerSection />
                      </div>
                    </>
                  );
                }
                return (
                  <Typography
                    className={classes.noDocumentsText}
                    variant="body1"
                  >
                    Only Account Managers can update company documents and
                    agreements.
                  </Typography>
                );
              })()}
            </Box>
          </Box>
        </Card>
      </Grid>
      {/*
        person name and company
      */}
      <Grid item xs={3}>
        <Box
          display="flex"
          style={{
            height: topCardsHeight,
            marginLeft: firstMainRowGapHalf,
            marginRight: firstMainRowGapHalf,
          }}
        >
          <Card
            raised
            elevation={cardElevation}
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              style={{
                padding: cardPadding,
                width: '100%',
                height: '100%',
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
                style={{
                  textAlign: 'center',
                  overflow: 'hidden',
                }}
              >
                <Link to="/settings">
                  <SettingsIcon
                    style={{
                      color: fontColor,
                      width: iconSize,
                      height: iconSize,
                      fontSize: iconSize,
                    }}
                  />
                </Link>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{
                  textAlign: 'center',
                  overflow: 'hidden',
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    fontSize: 34,
                    fontWeight: 400,
                    lineHeight: '42px',
                    letterSpacing: 0.25,
                    marginBottom: 18,
                    // TODO: [UX][CONSISTENCY] text.primary ?
                    color: theme.palette.common.black,
                  }}
                >
                  {getUserDisplayName(user)}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize={16}
                  color="textSecondary"
                  style={{
                    marginTop: 7.5,
                  }}
                >
                  {user.jobTitle || noJobTitleText}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize={16}
                  color="textSecondary"
                  style={{
                    marginTop: 7.5,
                  }}
                >
                  {getCompanyName(user.company) || <em>(No Company)</em>}
                </Typography>
              </Box>
              <Box
                style={{
                  textAlign: 'center',
                  overflow: 'hidden',
                }}
              >
                <StyledLink
                  style={{
                    fontSize: 16,
                  }}
                  path="/update-account"
                >
                  Update Account Information
                </StyledLink>
              </Box>
            </Box>
          </Card>
        </Box>
      </Grid>
      {/*
        Organization chart
      */}
      <Grid
        item
        xs={12}
        style={{
          marginTop: firstMainRowGap,
        }}
      >
        <Card
          raised
          elevation={cardElevation}
          style={{
            marginLeft: firstMainRowGapHalf,
            marginRight: firstMainRowGapHalf,
          }}
        >
          <Box
            style={{
              paddingTop: 45,
              paddingBottom: 45,
              paddingLeft: 49,
              paddingRight: 49,
              width: '100%',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              style={{
                textAlign: 'left',
                width: '100%',
                boxSizing: 'border-box',
              }}
            >
              <Typography
                variant="h5"
                style={{
                  fontWeight: 700,
                }}
              >
                Organizational Chart
              </Typography>
              <Box
                display="flex"
                flexGrow={1}
                flexWrap="wrap"
                justifyContent="center"
                style={{
                  marginLeft: -accountManagerCardMargin / 2,
                  marginRight: -accountManagerCardMargin / 2,
                  marginTop: 20 - organizationUserRowMargin / 2,
                  marginBottom: -organizationUserRowMargin / 2,
                }}
              >
                {(!isApplicable || isLoading) && loadingOrNotApplicableMessage}
                {allUsersInCompany
                  .filter(user => user.isAccountManager)
                  .map(user => (
                    <Box
                      key={user.id}
                      display="flex"
                      style={{
                        marginLeft: accountManagerCardMargin / 2,
                        marginRight: accountManagerCardMargin / 2,
                        marginTop: organizationUserRowMargin / 2,
                        marginBottom: organizationUserRowMargin / 2,
                      }}
                    >
                      <OrganizationUserCard user={user} theme={theme} />
                    </Box>
                  ))}
              </Box>
              {allUsersInCompany.filter(
                user => !user.isAccountManager && user.isAuthorizedSigner,
              ).length > 0 && (
                <Box
                  display="flex"
                  flexGrow={1}
                  flexWrap="wrap"
                  justifyContent="center"
                  style={{
                    marginLeft: -authorizedSignerCardMargin / 2,
                    marginRight: -authorizedSignerCardMargin / 2,
                    marginTop:
                      organizationSectionMargin - organizationUserRowMargin / 2,
                    marginBottom: -organizationUserRowMargin / 2,
                  }}
                >
                  {allUsersInCompany
                    .filter(
                      user => !user.isAccountManager && user.isAuthorizedSigner,
                    )
                    .map(user => (
                      <Box
                        key={user.id}
                        display="flex"
                        style={{
                          marginLeft: authorizedSignerCardMargin / 2,
                          marginRight: authorizedSignerCardMargin / 2,
                          marginTop: organizationUserRowMargin / 2,
                          marginBottom: organizationUserRowMargin / 2,
                        }}
                      >
                        <OrganizationUserCard user={user} theme={theme} />
                      </Box>
                    ))}
                </Box>
              )}
              {allUsersInCompany.filter(user => !user.isAuthorizedSigner)
                .length > 0 && (
                <Box
                  display="flex"
                  flexGrow={1}
                  flexWrap="wrap"
                  justifyContent="center"
                  style={{
                    marginLeft: -organizationUserCardMargin / 2,
                    marginRight: -organizationUserCardMargin / 2,
                    marginTop:
                      organizationSectionMargin - organizationUserRowMargin / 2,
                    marginBottom: -organizationUserRowMargin / 2,
                  }}
                >
                  {allUsersInCompany
                    .filter(user => user.isViewOnlyListingEditor)
                    .map(user => (
                      <Box
                        key={user.id}
                        display="flex"
                        style={{
                          marginLeft: organizationUserCardMargin / 2,
                          marginRight: organizationUserCardMargin / 2,
                          marginTop: organizationUserRowMargin / 2,
                          marginBottom: organizationUserRowMargin / 2,
                        }}
                      >
                        <OrganizationUserCard user={user} theme={theme} />
                      </Box>
                    ))}
                  {allUsersInCompany.filter(isViewOnlyUser).map(user => (
                    <Box
                      key={user.id}
                      display="flex"
                      style={{
                        marginLeft: organizationUserCardMargin / 2,
                        marginRight: organizationUserCardMargin / 2,
                        marginTop: organizationUserRowMargin / 2,
                        marginBottom: organizationUserRowMargin / 2,
                      }}
                    >
                      <OrganizationUserCard user={user} theme={theme} />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

CompanyDashboard.propTypes = {
  bankInformation: PropTypes.shape({
    status: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }),
  buyerAgreement: PropTypes.shape({
    status: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }),
  sellerAgreement: PropTypes.shape({
    status: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    isAccountManager: PropTypes.bool,
    isAuthorizedSigner: PropTypes.bool,
    isViewOnlyListingEditor: PropTypes.bool,
    companyId: PropTypes.number,
    company: PropTypes.shape({
      fullLegalCompanyName: PropTypes.string.isRequired,
      operatingName: PropTypes.string,
      businessStreetAddress: PropTypes.string,
      businessStreetAddress2: PropTypes.string,
      certification: PropTypes.string,
      city: PropTypes.string,
      zipCode: PropTypes.string,
      stateId: PropTypes.number,
      enableBuyerRegistration: PropTypes.bool,
      enableSellerRegistration: PropTypes.bool,
      toggleRequestPending: PropTypes.shape({
        enableBuyerRegistration: PropTypes.bool,
        enableSellerRegistration: PropTypes.bool,
      }),
    }),
    jobTitle: PropTypes.string,
  }).isRequired,
  allUsersInCompany: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      isAccountManager: PropTypes.bool,
      isAuthorizedSigner: PropTypes.bool,
      isViewOnlyListingEditor: PropTypes.bool,
      jobTitle: PropTypes.string,
    }).isRequired,
  ).isRequired,
  company: PropTypes.objectOf(PropTypes.string).isRequired,
};

CompanyDashboard.defaultProps = {
  sellerAgreement: undefined,
  buyerAgreement: undefined,
  bankInformation: undefined,
};

const CompanyDashboardConnected = compose(
  connect(({ auth, companies, company }) => ({
    sellerAgreement: auth?.user?.company?.sellerAgreement,
    buyerAgreement: auth?.user?.company?.buyerAgreement,
    bankInformation: auth?.user?.company?.bankInformation,
    user: auth?.user,
    allUsersInCompany: companies.allUsersInCompany,
    isLoadingAllUsersInCompany: companies.isLoadingAllUsersInCompany,
    company,
  })),
  withStyles(styles),
  memo,
)(CompanyDashboard);

// Dashboard

const Dashboard = ({
  classes,
  buyerAgreement,
  sellerAgreement,
  bankInformation,
  user,
}) => {
  const location = useLocation();
  const theme = newTheme;
  const history = useHistory();

  const companyIsApproved = !!user.company?.approved;
  const companyIsApprovedAndActive
    = companyIsApproved && !!user.company.active;

  const sellerAgreementApproved = sellerAgreement?.status.title === ApprovalStatus.Approved;

  const buyerAgreementApproved = buyerAgreement?.status.title === ApprovalStatus.Approved;

  const bankInformationApproved = bankInformation?.status.title === ApprovalStatus.Approved;

  const selectedDashboard = (() => {
    let result;
    switch (location.pathname) {
      case BUYER_DASHBOARD:
        result = 'buyer';
        break;
      case SELLER_DASHBOARD:
        result = 'seller';
        break;
      case COMPANY_DASHBOARD:
      // fall through
      default:
        result = 'company';
        break;
    }
    return result;
  })();

  useEffect(() => {
    if (location.pathname === DASHBOARD) {
      if (buyerAgreementApproved
        && bankInformationApproved
        && companyIsApprovedAndActive) {
        history.replace(BUYER_DASHBOARD);
      } else if (sellerAgreementApproved && companyIsApprovedAndActive) {
        history.replace(SELLER_DASHBOARD);
      } else {
        history.replace(COMPANY_DASHBOARD);
      }
    }
  }, [
    history,
    location,
    companyIsApprovedAndActive,
    buyerAgreementApproved,
    sellerAgreementApproved,
    bankInformationApproved,
  ]);

  const onNavigationCardClick = useCallback(
    newDashboard => {
      switch (newDashboard) {
        case 'buyer':
          history.push(BUYER_DASHBOARD);
          break;
        case 'seller':
          history.push(SELLER_DASHBOARD);
          break;
        case 'company':
          history.push(COMPANY_DASHBOARD);
          break;
        default:
          // do nothing
          break;
      }
    },
    [history],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/*
        page layout
      */}
      <Grid
        container
        className={classes.wrapper}
        style={{
          minWidth: desktopContentMinWidthExclusive,
          backgroundColor: mainPageContentBackgroundColor,
        }}
      >
        {/*
          navigation cards
        */}
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            style={{
              paddingTop: firstMainRowMarginSides,
              paddingBottom: firstMainRowMarginSides,
            }}
          >
            <NavigationCard
              Icon={BriefcaseIcon}
              title="Company Dashboard"
              selected={selectedDashboard === 'company'}
              onClick={() => onNavigationCardClick('company')}
            />
            <NavigationCard
              Icon={UserIcon}
              title="Buyer Dashboard"
              selected={selectedDashboard === 'buyer'}
              onClick={() => onNavigationCardClick('buyer')}
            />
            <NavigationCard
              Icon={TrendingUpIcon}
              title="Seller Dashboard"
              selected={selectedDashboard === 'seller'}
              onClick={() => onNavigationCardClick('seller')}
            />
          </Box>
        </Grid>
        {/*
          dashboard content
        */}
        {(() => {
          let content;
          switch (selectedDashboard) {
            case 'buyer':
              content = <BuyerDashboardConnected />;
              break;
            case 'seller':
              content = <SellerDashboard />;
              break;
            case 'company':
              content = <CompanyDashboardConnected />;
              break;
            default:
              break;
          }
          return content;
        })()}
      </Grid>
    </ThemeProvider>
  );
};

Dashboard.propTypes = {
  buyerAgreement: PropTypes.shape({
    status: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }),
  sellerAgreement: PropTypes.shape({
    status: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }),
  bankInformation: PropTypes.shape({
    status: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }),
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    companyId: PropTypes.number,
    company: PropTypes.shape({
      active: PropTypes.bool,
      approved: PropTypes.bool,
    }),
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

Dashboard.defaultProps = {
  buyerAgreement: undefined,
  sellerAgreement: undefined,
  bankInformation: undefined,
};

export default compose(
  connect(({ auth }) => ({
    buyerAgreement: auth?.user?.company?.buyerAgreement,
    sellerAgreement: auth?.user?.company?.sellerAgreement,
    user: auth.user,
  })),
  withStyles(styles),
  memo,
)(Dashboard);
