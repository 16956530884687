import {
  all,
  fork,
  take,
  takeLatest,
  call,
  put,
  select,
} from 'redux-saga/effects';

// actions
import { getAllCompanies } from '../actions/companiesActions';

// api methods
import Api from 'api/companies';

import {
  createNewCompany,
  persistCompanyUpdates,
  createFinancialInfo,
  signSellerAgreement,
  signBuyerAgreement,
  saveFileUpload,
  updateEnableTraderRegistration,
} from 'store/actions/companyActions';
import { updateUserInfo } from 'store/actions/userActions';

import { getAuthorizationHeader } from 'auth/auth-helpers';
import { getStorageItem } from 'now-frontend-shared/utils/storage';
import { checkAuth, getUserDataFromAccessToken } from './auth';
import { findUsersByCompany, getNonOpWellsSignatureDetails } from 'store/actions/companiesActions';
import { formattedCurrencyToNumberString, formattedPhoneToNumberString } from 'now-frontend-shared/utils/helpers';

// constants
import { EnableDisable, BuyerSeller } from 'now-shared/validation/companyEnableTraderRegistration';

function* ensureGetAllCompanies() {
  try {
    const { data } = yield call(Api.getAllCompanies);
    yield put({ type: getAllCompanies.success, payload: data });
  } catch (err) {
    yield put({ type: getAllCompanies.failure, err });
  }
}

function* watchGetAllCompany() {
  yield takeLatest(getAllCompanies.type, ensureGetAllCompanies);
  yield take(getAllCompanies.success);
}

function* ensurefindUsersByCompany() {
  const companyId = yield select(({ auth }) => auth.user.companyId);
  try {
    if (!companyId) {
      const e = new Error();
      e.response = {
        data: {
          message: "You don't have a company",
        },
      };
      throw e;
    }
    const { data } = yield call(Api.findUsersByCompany(companyId));
    yield put({ type: findUsersByCompany.success, payload: data });
  } catch (err) {
    yield put({ type: findUsersByCompany.failure, err });
  }
}

function* watchGetAllUsersInCompany() {
  yield takeLatest(findUsersByCompany.type, ensurefindUsersByCompany);
  yield take(findUsersByCompany.success);
}

function* ensureCreateNewCompany({ payload }) {
  try {
    const { companyId, ...companyState } = yield select(
      ({ company }) => company,
    );
    const companyValidState = {
      ...companyState,
      role: companyState.role.value,
      ...(companyState?.BOstateId?.id && {
        BOstateId: String(companyState?.BOstateId?.id),
      }),
      stateId: companyState?.stateId?.id ? String(companyState.stateId.id) : null,
    };
    const data = yield call(Api.addNewCompany, {
      data: JSON.stringify(companyValidState),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
    });
    yield put({ type: createNewCompany.success, payload: data });
    yield put(updateUserInfo());
    if (payload.resolve) {
      payload.resolve();
    }
  } catch (err) {
    yield put({ type: createNewCompany.failure, err });
    console.log(err);
    if (payload.reject) {
      payload.reject(err);
    }
  }
}

function* saveCompanyUploadSaga({ payload }) {
  try {
    const { companyId } = yield select(
      ({ company }) => company,
    );

    const { id } = yield select(
      ({ auth }) => auth.user.company,
    );

    const documents = payload.AWSData.map(({ key, filename, type }) => ({ key, filename, type }));

    yield call(Api.saveFileUpload, {
      data: JSON.stringify({ companyId: companyId || id, documents }),
    });
    yield call(checkAuth);
    if (payload.resolve) {
      payload.resolve();
    }
    yield put({ type: saveFileUpload.success });
  } catch (err) {
    yield put({ type: saveFileUpload.failure, err });
    if (payload.reject) {
      payload.reject(err);
    }
  }
}

function* ensureUpdateCompany({
  payload: {
    companyId,
    signature,
    updates,
    resolve,
    reject,
  },
}) {
  try {
    const data = yield call(Api.updateCompany(companyId), {
      data: JSON.stringify(updates, { signature }),
    });
    yield put({ type: persistCompanyUpdates.success, payload: data });
    const accessToken = JSON.parse(getStorageItem('accessToken', '{}'));
    const isAuthorized = yield select(({ auth }) => auth.authInfo.isAuthorized);
    if (isAuthorized) yield call(getUserDataFromAccessToken, { accessToken });
    if (resolve) {
      resolve();
    }
  } catch (err) {
    yield put({ type: createFinancialInfo.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* ensureUpdateEnableTraderRegistration({
  payload: {
    companyId,
    enableOrDisable,
    buyerOrSeller,
  },
}) {
  // Currently the toggleSwitches states from the company reducer are not being used by the frontend.
  // TODO: Add a setToggleSwitches (use appropriate name) action to the company reducer and replace the
  // buyer and seller toggleSwitch useStates in the company dashboard with these redux toggleSwitches
  // states from the company reducer.
  const enableRegistrationType = (buyerOrSeller === BuyerSeller.BUYER)
    ? 'enableBuyerRegistration' : 'enableSellerRegistration';
  yield put({
    type: updateEnableTraderRegistration.request,
    payload: { toggleRequestPending: { [enableRegistrationType]: true } },
  });
  try {
    yield call(Api.updateEnableTraderRegistration({ companyId, enableOrDisable, buyerOrSeller }));
    yield put({
      type: updateEnableTraderRegistration.success,
      payload: {
        toggleSwitches: { [enableRegistrationType]: (enableOrDisable === EnableDisable.ENABLE) },
        toggleRequestPending: { [enableRegistrationType]: false },
      },
    });
    const accessToken = JSON.parse(getStorageItem('accessToken', '{}'));
    const isAuthorized = yield select(({ auth }) => auth.authInfo.isAuthorized);
    if (isAuthorized) yield call(getUserDataFromAccessToken, { accessToken });
  } catch (err) {
    yield put({
      type: updateEnableTraderRegistration.failure,
      payload: {
        toggleRequestPending: { [enableRegistrationType]: false },
      },
      err,
    });
  }
}

function* createFinancialInfoSaga({ payload: { financial, resolve, reject } }) {
  try {
    const { companyId } = yield select(({ company }) => company);
    const { user } = yield select(({ auth }) => auth);
    const { isAuthorized } = yield select(({ auth }) => auth.authInfo);
    const accessToken = JSON.parse(getStorageItem('accessToken', '{}'));
    yield call(Api.addNewFinancialCompany, {
      data: JSON.stringify({
        ...financial,
        bankerPhoneNumber: formattedPhoneToNumberString(financial.bankerPhoneNumber),
        companyId: companyId || user.companyId,
        requestedBidAllowance: formattedCurrencyToNumberString(financial.requestedBidAllowance),
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
    });
    if (isAuthorized) yield call(getUserDataFromAccessToken, { accessToken });
    yield call(checkAuth);
    yield put({ type: createFinancialInfo.success });
    if (resolve) {
      resolve();
    }
  } catch (err) {
    yield put({ type: createFinancialInfo.failure, err });
    if (reject) {
      reject(err);
    }
    console.log(err);
  }
}

function* createSellerAgreementSaga({
  payload: {
    signature,
    docVersion,
    resolve,
    reject,
  },
}) {
  try {
    const { companyId } = yield select(({ company }) => company);
    const { user } = yield select(({ auth }) => auth);
    const { isAuthorized } = yield select(({ auth }) => auth.authInfo);
    const accessToken = JSON.parse(getStorageItem('accessToken', '{}'));
    yield call(Api.sendSellerAgreementForRegistration(companyId || user.companyId), {
      data: JSON.stringify({ signature, docVersion }),
    });
    if (isAuthorized) yield call(getUserDataFromAccessToken, { accessToken });
    yield put({ type: createFinancialInfo.success });
    yield call(checkAuth);
    if (resolve) {
      resolve();
    }
  } catch (err) {
    yield put({ type: createFinancialInfo.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* createBuyerAgreementSaga({
  payload: {
    signature,
    docVersion,
    resolve,
    reject,
  },
}) {
  try {
    const { companyId } = yield select(({ company }) => company);
    const { user } = yield select(({ auth }) => auth);
    const { isAuthorized } = yield select(({ auth }) => auth.authInfo);
    const accessToken = JSON.parse(getStorageItem('accessToken', '{}'));
    yield call(Api.sendBuyerAgreementForRegistration(companyId || user.companyId), {
      data: JSON.stringify({ signature, docVersion }),
    });
    if (isAuthorized) yield call(getUserDataFromAccessToken, { accessToken });
    yield put({ type: createFinancialInfo.success });
    if (resolve) {
      resolve();
    }
  } catch (err) {
    yield put({ type: createFinancialInfo.failure, err });
    if (reject) {
      reject(err);
    }
  }
}

function* watchCreateNewCompany() {
  yield takeLatest(createNewCompany.type, ensureCreateNewCompany);
  yield take(createNewCompany.success);
}

function* watchUpdateCompany() {
  yield takeLatest(persistCompanyUpdates.type, ensureUpdateCompany);
  yield take(persistCompanyUpdates.success);
}

function* watchFinancialInfo() {
  yield takeLatest(createFinancialInfo.type, createFinancialInfoSaga);
  yield take(createFinancialInfo.success);
}

function* watchSellerAgreement() {
  yield takeLatest(signSellerAgreement.type, createSellerAgreementSaga);
  yield take(signSellerAgreement.success);
}

function* watchBuyerAgreement() {
  yield takeLatest(signBuyerAgreement.type, createBuyerAgreementSaga);
  yield take(signBuyerAgreement.success);
}

function* watchFileUpload() {
  yield takeLatest(saveFileUpload.type, saveCompanyUploadSaga);
  yield take(saveFileUpload.success);
}

function* ensureSignatureDetailsRequested() {
  try {
    const { data } = yield call(Api.getNonOpWellsSignatureDetails);
    yield put({ type: getNonOpWellsSignatureDetails.success, payload: data });
  } catch (err) {
    yield put({ type: getNonOpWellsSignatureDetails.failure, err });
  }
}

function* watchSignatureDetailsRequest() {
  yield takeLatest(getNonOpWellsSignatureDetails.type, ensureSignatureDetailsRequested);
}

function* watchUpdateEnableTraderRegistration() {
  yield takeLatest(updateEnableTraderRegistration.type, ensureUpdateEnableTraderRegistration);
  yield take(updateEnableTraderRegistration.success);
}

export default function* companiesSagas() {
  yield all([
    fork(watchGetAllCompany),
    fork(watchGetAllUsersInCompany),
    fork(watchCreateNewCompany),
    fork(watchUpdateCompany),
    fork(watchFinancialInfo),
    fork(watchSellerAgreement),
    fork(watchBuyerAgreement),
    fork(watchFileUpload),
    fork(watchSignatureDetailsRequest),
    fork(watchUpdateEnableTraderRegistration),
  ]);
}
