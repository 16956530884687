import { makeAxiosRequest } from 'utils/apiMethods';

export default {
  getActivePropertiesCoordinates: makeAxiosRequest(
    '/get-active-properties-coordinates',
    { method: 'get' },
    true,
  ),
  getHistoricalListings: makeAxiosRequest(
    '/historical-transactions',
    { method: 'get' },
    true,
  ),
};
