import React from 'react';
import { IconButton } from '@material-ui/core';
import { styled } from '@material-ui/styles';

const ExpandMore = styled(props => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  borderRadius: 5,
  border: '1px solid rgba(0, 0, 0, 0.3)',
  height: 35,
}));
export default ExpandMore;
