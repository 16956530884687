// check icon
import checkIcon from 'now-frontend-shared/assets/icons/check.svg';

// themes
import { primaryDark } from '../../../themes/colors';

export default {
  icon: {
    width: '16px',
    height: '16px',
    borderRadius: '2px',
    backgroundColor: 0,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,0.2), inset 0 -1px 0 rgba(16,22,26,0.1)',
  },
  squareIcon: {
    '&:before': {
      content: '""',
      display: 'block',
      width: '8px',
      height: '8px',
      margin: '4px',
      borderRadius: '2px',
      background: primaryDark,
    },
  },
  checkIcon: {
    '&:before': {
      content: '""',
      display: 'block',
      width: '16px',
      height: '16px',
      background: `no-repeat center / 70% url(${checkIcon})`,
    },
  },
};
