import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

// components

// helpers
import { formatNumberToCurrency } from 'now-frontend-shared/utils/helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const TotalListingCost = ({ classes, totalCost }) => {
  const totalCostFormatted = useMemo(
    () => (totalCost === undefined ? '-' : formatNumberToCurrency(totalCost, true)),
    [totalCost],
  );

  return (
    <span className={classes.description}>
      {`Total: ${totalCostFormatted}`}
    </span>
  );
};

TotalListingCost.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  totalCost: PropTypes.number,
};

TotalListingCost.defaultProps = {
  totalCost: undefined,
};

export default compose(memo, withStyles(styles))(TotalListingCost);
