import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import useSupercluster from 'use-supercluster';
import GoogleMapReact from 'google-map-react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import * as shapefile from '@mickeyjohn/shapefile';

import shape from 'assets/shape/shape.shp';

import { isUsingEsriMaps } from 'now-frontend-shared/features/feature-flags';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';
import EsriMap from 'now-frontend-shared/components/EsriMap';

// custom hooks
import useIsMobile from 'now-frontend-shared/hooks/useIsMobile';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// styles
import { getHistoricalListings, getPropertiesMarkers } from 'store/actions/mapActions';
import styles from './styles';

// store
import InfoWindow from 'now-frontend-shared/components/Map/components/InfoWindow';
import Marker from './components/Marker';
import {
  mapAreaOfInterestFillRgba,
  mapAreaOfInterestOutline,
  toRgbString,
} from 'now-frontend-shared/themes/colors';

const Map = ({
  classes,
  pins,
  historicalListings,
}) => {
  const [mapRef, setMapRef] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [infoWindowData, setInfoWindowData] = useState(null);
  const [mapScrollwheel, setMapScrollwheel] = useState(true);
  const [draggable, setDraggable] = useState(true);
  const [areasOfInterestPolygons, setAreaOfInterestPolygons] = useState(undefined);

  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const getAreasOfInterestPolygons = useCallback(async () => {
    const source = await shapefile.openShp(shape);
    const polygons = [];
    let result = await source.read();
    while (!result.done) {
      const coords = [];
      polygons.push(coords);
      result.value.coordinates.forEach(
        coordinates => coordinates.forEach(
          coordinate => {
            if (Array.isArray(coordinate[0])) {
              coordinate.forEach(coord => coords.push({ lng: coord[0], lat: coord[1] }));
            } else {
              coords.push({ lng: coordinate[0], lat: coordinate[1] });
            }
          },
        ),
      );
      // eslint-disable-next-line no-await-in-loop
      result = await source.read();
    }
    return polygons;
  }, []);

  const loadAreasOfInterest = useCallback(() => {
    let result;
    if (isUsingEsriMaps()) {
      let canceled = false;
      const load = async () => {
        const polygons = await getAreasOfInterestPolygons();
        if (!canceled) {
          setAreaOfInterestPolygons(polygons);
        }
      };
      load();
      result = () => {
        canceled = true;
      };
    }
    return result;
  }, [getAreasOfInterestPolygons]);

  const fetchMapData = useCallback(() => {
    dispatch(getPropertiesMarkers());
    dispatch(getHistoricalListings());
  }, [dispatch]);

  const handleBasemapLoaded = useCallback(() => {
    fetchMapData();
    loadAreasOfInterest();
  }, [fetchMapData, loadAreasOfInterest]);

  useEffect(() => {
    if (!isUsingEsriMaps()) {
      fetchMapData();
    }
  }, [fetchMapData]);

  const points = pins.map(({
    propertyId,
    projectName,
    wellCount,
    longitude,
    latitude,
  }) => ({
    type: 'Feature',
    properties: {
      cluster: false,
      propertyId,
      projectName,
      wellCount,
    },
    geometry: {
      type: 'Point',
      coordinates: [longitude, latitude],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 100 },
  });

  const getClusterProperties = useCallback(
    cluster => {
      const [longitude, latitude] = cluster.geometry.coordinates;

      if (cluster.id) {
        const clusterData = supercluster.getLeaves(cluster.id);
        const listings = clusterData.map(
          ({
            properties: {
              propertyId,
              projectName,
              wellCount,
            },
          }) => ({
            id: propertyId,
            projectName,
            wellCount,
          }),
        );
        setInfoWindowData({ id: cluster.id, listings });
      } else {
        setInfoWindowData({
          propertyId: cluster.properties.propertyId,
          listings: [{
            id: cluster.properties.propertyId,
            projectName: cluster.properties.projectName,
            wellCount: cluster.properties.wellCount,
          }],
        });
      }

      mapRef.panTo({ lat: latitude, lng: longitude });
    },
    [supercluster, mapRef],
  );

  const handleToggleScrollwheel = useCallback(() => setMapScrollwheel(prevScrollwheel => !prevScrollwheel), [
    setMapScrollwheel,
  ]);

  const setMapDragging = useCallback(draggable => setDraggable(draggable), [setDraggable]);

  const handleCloseInfoWindow = useCallback(() => {
    setInfoWindowData(null);
    setMapScrollwheel(true);
    setMapDragging(true);
  }, [setInfoWindowData, setMapScrollwheel, setMapDragging]);

  const renderInfoWindow = ({ markerId, isCluster, pointCount }) => {
    if (isCluster && markerId === infoWindowData?.id) {
      return (
        <InfoWindow
          isCluster={isCluster}
          pointCount={pointCount}
          listings={infoWindowData.listings}
          handleToggleScrollwheel={handleToggleScrollwheel}
          handleCloseInfoWindow={handleCloseInfoWindow}
        />
      );
    }

    if (!isCluster && markerId === infoWindowData?.propertyId) {
      return (
        <InfoWindow
          isCluster={isCluster}
          pointCount={pointCount}
          listings={infoWindowData.listings}
          handleToggleScrollwheel={handleToggleScrollwheel}
          handleCloseInfoWindow={handleCloseInfoWindow}
        />
      );
    }
  };

  const handleApiLoaded = async (map, maps) => {
    const polygons = await getAreasOfInterestPolygons();
    polygons.forEach(coords => {
      const polygon = new maps.Polygon({
        paths: coords,
        strokeColor: toRgbString(mapAreaOfInterestOutline.strokeColorRgb),
        strokeOpacity: mapAreaOfInterestOutline.strokeOpacity,
        strokeWeight: mapAreaOfInterestOutline.strokeWeight,
        fillColor: toRgbString(mapAreaOfInterestFillRgba),
        fillOpacity: mapAreaOfInterestFillRgba[3],
      });
      polygon.setMap(map);
    });
  };

  const defaultZoomValue = useMemo(() => (isMobile ? 2 : 6), [isMobile]);

  return (
    <>
      <Header />

      <Grid container className={classes.wrapper}>
        {clusters && (
          isUsingEsriMaps()
            ? (
              <EsriMap
                isListingsMap
                areasOfInterestPolygons={areasOfInterestPolygons}
                listings={pins}
                historicalListings={historicalListings}
                onBasemapLoaded={handleBasemapLoaded}
              />
            )
            : (
              <GoogleMapReact
                options={{
                  scrollwheel: mapScrollwheel,
                  disableDefaultUI: !isMobile,
                  gestureHandling: !draggable && 'none',
                }}
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
                defaultCenter={{ lat: 39.77001, lng: -101.331774 }}
                zoom={defaultZoomValue}
                yesIWantToUseGoogleMapApiInternals
                onZoomAnimationStart={() => setInfoWindowData(null)}
                onChange={({ zoom, bounds }) => {
                  setZoom(zoom);
                  setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
                }}
                onGoogleApiLoaded={({ map, maps }) => {
                  setMapRef(map);
                  handleApiLoaded(map, maps);
                }}
              >
                {clusters.map((cluster, index) => {
                  const [longitude, latitude] = cluster.geometry.coordinates;
                  const { cluster: isCluster, point_count: pointCount } = cluster.properties;
                  const handleClick = () => {
                    getClusterProperties(cluster);
                    if (isMobile) setMapDragging(false);
                  };
                  return (
                    <Marker
                      key={index}
                      lat={latitude}
                      lng={longitude}
                      isCluster={isCluster}
                      pointCount={pointCount}
                      handleClick={handleClick}
                    >
                      {renderInfoWindow({
                        markerId: isCluster ? cluster.id : cluster.properties.propertyId,
                        isCluster,
                        pointCount,
                      })}
                    </Marker>
                  );
                })}
              </GoogleMapReact>
            )
        )}
      </Grid>
      <Footer />
    </>
  );
};

Map.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  pins: PropTypes.arrayOf(
    PropTypes.shape({
      projectName: PropTypes.string.isRequired,
      wellCount: PropTypes.number.isRequired,
      propertyId: PropTypes.number.isRequired,
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }).isRequired,
  ),
  historicalListings: PropTypes.arrayOf(
    PropTypes.shape({
      operator: PropTypes.string,
      // TODO: add remaining field type definitions
    }).isRequired,
  ),
};

Map.defaultProps = {
  pins: [],
  historicalListings: [],
};

export default compose(
  connect(({ map }) => ({
    pins: map.pins,
    historicalListings: map.historicalListings,
  })),
  withStyles(styles),
)(Map);
