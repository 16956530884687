const infoWindowBaseStyles = {
  backgroundColor: '#FFFFFF',
};

export default {
  infoWindowBase: infoWindowBaseStyles,
  infoWindow: {
    ...infoWindowBaseStyles,
    position: 'relative',
    top: 0,
    left: 0,
    width: '700px',
    padding: '24px',
    borderRadius: '4px',
    transform: 'translateX(-8px) translateY(-10px)',
    zIndex: 100,

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      border: '14px solid transparent',
      borderBottomColor: '#FFFFFF',
      borderTop: 0,
      borderLeft: 0,
      transform: 'translate3d(8px, -12px, 0)',

      '@media screen and (max-width: 960px)': {
        transform: 'translate3d(174px, -12px, 0)',
      },

      '@media screen and (max-width: 600px)': {
        transform: 'translate3d(140px, -12px, 0)',
      },
    },

    '@media screen and (max-width: 960px)': {
      left: '-165px',
    },

    '@media screen and (max-width: 600px)': {
      width: '280px',
      left: 0,
      transform: 'translateX(-50%) translateY(-10px)',
    },
  },
  infoContainer: {
    padding: '6px',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'normal',
    color: '#4F4F4F',
  },
  infoContainerOverflow: {
    maxHeight: '144px',
    overflow: 'auto',

    /* firefox scrollbar */
    scrollbarWidth: 'thin',
    scrollbarColor: '#8F8F8F #fff',

    /* webkit browsers scrollbar */
    '&::-webkit-scrollbar': {
      width: '3px',
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#8F8F8F',
      borderRadius: '4px',
    },

    '@media screen and (max-width: 600px)': {
      maxHeight: '76px',
    },
  },
  infoHeading: {
    marginBottom: '20px',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'bold',

    '@media screen and (max-width: 600px)': {
      textAlign: 'center',
      marginBottom: '10px',
    },
  },
  propertyInfo: {
    margin: '4px 0',
  },
  propertyId: {
    color: '#BDBDBD',
  },
  infoCount: {
    width: '100%',
    margin: '12px 0',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
  },
  infoCountBold: {
    fontWeight: 'bold',
  },
  infoMessage: {
    width: '100%',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#2F80ED',
  },
};
