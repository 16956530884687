import React, {
  memo,
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import cx from 'classnames';
import { DateTime, Interval } from 'luxon';
import notificationIcon from './components/notification.svg';

import { isUsingEsriMaps } from 'now-frontend-shared/features/feature-flags';

// components
import Header from 'components/Header';
import Footer from 'components/Footer';
import GoogleMap from 'now-frontend-shared/components/GoogleMap';
import LinkButton from 'now-frontend-shared/components/LinkButton';
import Countdown from 'now-frontend-shared/components/Countdown';
import StatusBadge from 'now-frontend-shared/components/StatusBadge';
import Spinner from 'now-frontend-shared/components/Spinner';
import TotalListingCost from 'components/TotalListingCost';
import Checkbox from 'now-frontend-shared/components/Checkbox';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DocumentsMenu from './components/DocumentsMenu';
import DocumentsTable from './components/DocumentsTable';
import EsriMap from 'now-frontend-shared/components/EsriMap';
import { useModalSetter } from 'now-frontend-shared/hooks/useModal';
import Watermark from 'components/Watermark';

import AmountInput from './components/AmountInput';
import PlaceBidButton from './components/PlaceBidButton';
import StyledLink from 'pages/Profile/components/StyledLink';
import SubmitConfirmationModal from 'now-frontend-shared/components/modals/SubmitConfirmationModal';
import useIsTablet from 'now-frontend-shared/hooks/useIsTablet';
import { getCurrentProperty, makeBet } from 'store/actions/propertyViewActions';
import { FormulaTooltip } from 'now-frontend-shared/components/Tooltips';

// back arrow
import { ReactComponent as BackArrow } from 'now-frontend-shared/assets/icons/blue_back_arrow.svg';

// helpers
import { isAdminOrCompliancePerson } from 'now-shared/validation/admin-upsert-user';
import {
  listingIsActive,
  listingIsArchived,
  listingHasBeenActive,
  listingNotYetClosed,
} from 'now-shared/validation/listing-validation';
import {
  decimalCount,
  formatNumberToCurrency,
  maskedAmountToNumber,
} from 'now-frontend-shared/utils/helpers';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import {
  nonOpWellsDateTime,
  toAuctionEventTime,
} from 'now-shared/helpers/time-helpers';
import { getCompanyName } from 'now-shared/helpers/company-helpers';
import {
  isListingTransactionCanceled,
  isListingTransactionComplete,
} from 'now-shared/helpers/escrow-transaction-helpers';
import { ClosingMethod } from 'now-shared/enums/closing-method';
import {
  listingDocumentAdditionalTypeMap,
  listingDocumentNewTypeMap,
} from 'now-shared/enums/listing-document-type';
import { computeWellsGrossAfe } from 'now-shared/helpers/listing-helpers';

// styles and components from material-ui
import { withStyles } from '@material-ui/core/styles';
import {
  Grid, makeStyles, Button,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import LaunchIcon from '@material-ui/icons/Launch';
import DollarIcon from '@material-ui/icons/AttachMoneyRounded';
import LockIcon from '@material-ui/icons/LockRounded';
import BankIcon from '@material-ui/icons/AccountBalanceRounded';
import SendIcon from '@material-ui/icons/SendRounded';
import MailUnreadIcon from '@material-ui/icons/MarkunreadRounded';
import ThumbsUpIcon from '@material-ui/icons/ThumbUpAltRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded';
import StepConnector from '@material-ui/core/StepConnector';

// styles
import styles from './styles';

// store
import {
  addListingDocumentDownload,
  addListingPageView,
} from 'store/actions/listingAnalyticsActions';
import {
  computeCommission,
  computeTotalListingCost,
  grantConsideration,
  computeImpliedPrice,
  computeAcquiredRetainedInterest,
} from 'now-shared/helpers/bid-helpers';
import { TransactionTypes } from 'now-shared/enums/transaction-types';
import { formValues, reduxForm, change } from 'redux-form';

export function getMyBidStatusText(myBid) {
  if (myBid?.status.title === 'winner') {
    return 'You won!';
  }
  if (myBid) {
    return 'You have already bid on this listing';
  }
  return 'You haven’t bid on this listing';
}

function getBidUsername({ user }) {
  return getUserFullName(user);
}

function getBidCompanyName({ user: { company } }) {
  return getCompanyName(company);
}

const TRANSACTION_CREATED = 'transactionCreated';
const PAYMENT_SENT = 'paymentSent';
const PAYMENT_SECURED = 'paymentSecured';
const DOCUMENTS_SENT = 'documentsSent';
const DOCUMENTS_RECEIVED = 'documentsReceived';
const DOCUMENTS_ACCEPTED = 'documentsAccepted';
const FUNDS_DISBURSED = 'fundsDisbursed';
const TRANSACTION_CLOSED = 'transactionClosed';

const steps = [
  {
    id: TRANSACTION_CREATED,
    label: 'Transaction created',
    icon: <LaunchIcon />,
    closingMethods: Object.values(ClosingMethod).filter(
      method => method !== ClosingMethod.EscrowDotCom,
    ),
  },
  {
    id: PAYMENT_SENT,
    label: 'Payment sent',
    icon: <DollarIcon />,
    closingMethods: [ClosingMethod.EscrowDotCom],
  },
  {
    id: PAYMENT_SECURED,
    label: 'Payment secured',
    icon: <LockIcon />,
    closingMethods: [ClosingMethod.EscrowDotCom],
  },
  {
    id: DOCUMENTS_SENT,
    label: 'Documents sent',
    icon: <SendIcon />,
    closingMethods: [ClosingMethod.EscrowDotCom],
  },
  {
    id: DOCUMENTS_RECEIVED,
    label: 'Documents received',
    icon: <MailUnreadIcon />,
    closingMethods: [ClosingMethod.EscrowDotCom],
  },
  {
    id: DOCUMENTS_ACCEPTED,
    label: 'Documents accepted',
    icon: <ThumbsUpIcon />,
    closingMethods: [ClosingMethod.EscrowDotCom],
  },
  {
    id: FUNDS_DISBURSED,
    label: 'Funds disbursed',
    icon: <BankIcon />,
    closingMethods: [ClosingMethod.EscrowDotCom],
  },
  {
    id: TRANSACTION_CLOSED,
    label: 'Transaction closed',
    icon: <CheckCircleIcon />,
    closingMethods: Object.values(ClosingMethod),
  },
];

const stepIds = steps.map(step => step.id);
const stepIdStepMap = new Map(steps.map(({ id, ...step }) => [id, step]));

function getStepIndex(stepId, visibleStepIds = stepIds) {
  return visibleStepIds.indexOf(stepId);
}

function stepIdFromNumber(stepNumber, visibleStepIds = stepIds) {
  return visibleStepIds[stepNumber - 1];
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});
function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const {
    active, completed, icon: stepNumberString, visibleStepIds,
  } = props;

  const stepId = useMemo(
    () => stepIdFromNumber(+stepNumberString, visibleStepIds),
    [stepNumberString, visibleStepIds],
  );

  return (
    <div
      className={cx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {stepIdStepMap.get(stepId).icon || stepNumberString}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node.isRequired,
  visibleStepIds: PropTypes.arrayOf(PropTypes.string.isRequired),
};

ColorlibStepIcon.defaultProps = {
  active: undefined,
  completed: undefined,
  visibleStepIds: stepIds,
};

const PropertyView = ({
  classes,
  match,
  property,
  user,
  isAdminOrComplianceUser,
  location,
  companyIsApprovedAndActive,
  hasBankInformation,
  hasBuyerAgreement,
  handleSubmit,
  userFullName,
  companyName,
}) => {
  const dispatch = useDispatch();
  const isTablet = useIsTablet();
  const { setModal } = useModalSetter();
  const [payTimeRemaining, setPayTimeRemaining] = useState('');
  const [canBidOnProperty, setCanBidOnProperty] = useState(false);
  const [wellAllocationBids, setWellAllocationBids] = React.useState({});

  const listingId = match.params.id;
  const watermarkText = `${companyName} / ${userFullName}`;

  useEffect(() => {
    dispatch(getCurrentProperty(listingId));
  }, [dispatch, listingId]);

  const wellsAugmented = useMemo(
    () => property?.wells.map(well => ({ ...well, property })),
    [property],
  );

  const propertyWells = property?.wells?.sort((a, b) => {
    if (+a.rank < +b.rank) {
      return -1;
    }
    if (+a.rank > +b.rank) {
      return 1;
    }
    return 0;
  }) || [];

  const getCoordinates = useCallback(() => {
    if (property?.wells) {
      const polylinesCoordinates = [];
      const markersCoordinates = [];

      propertyWells.forEach(
        ({
          surfaceLatitude,
          surfaceLongitude,
          bottomLatitude,
          bottomLongitude,
        }) => {
          if (
            surfaceLatitude
            && surfaceLongitude
            && bottomLatitude
            && bottomLongitude
          ) {
            polylinesCoordinates.push([
              { lat: +surfaceLatitude, lng: +surfaceLongitude },
              { lat: +bottomLatitude, lng: +bottomLongitude },
            ]);

            markersCoordinates.push({
              lat: +surfaceLatitude,
              lng: +surfaceLongitude,
            });
          }
        },
      );

      return { polylinesCoordinates, markersCoordinates };
    }
  }, [property]);
  const prevBidTypeSelectionRef = useRef();
  const hidePlaceBidButton = false;
  const isOwner = property?.user.id === user.id;
  const isOwnerCompany = property?.user?.company?.id === user.companyId;
  const isBuyer = !isOwnerCompany && !isAdminOrComplianceUser;
  const existingBid
    = isOwnerCompany || isAdminOrComplianceUser ? undefined : property?.bids[0];
  const winningBid
    = property?.bids[0]?.status.title === 'winner'
      ? property?.bids[0]
      : undefined;
  const bidAmount
    = existingBid?.amount !== undefined ? Number(existingBid.amount) : undefined;
  const bidCarryPercentage
    = existingBid?.carryPercentage !== undefined
      ? Number(existingBid.carryPercentage)
      : undefined;
  const bidCommission = existingBid ? +existingBid.commission : undefined;
  const bidTotalCost = existingBid ? +existingBid.totalCost : undefined;
  const bidGrantConsideration = existingBid
    ? +existingBid.grantConsideration
    : grantConsideration;
  const totalWellGrossAfe = computeWellsGrossAfe(property?.wells);
  const netAfe
    = property?.netAfe !== undefined ? Number(property?.netAfe) : undefined;
  const coordinates = useMemo(() => getCoordinates(), [getCoordinates]);
  const listingStatusTitle = property?.status.title;
  const minimumBidCarry = Number(property?.minimumBidCarry)
    ? `${property.minimumBidCarry}%`
    : '-';

  useEffect(() => {
    if (isBuyer && listingStatusTitle === 'active') {
      dispatch(addListingPageView({ listingId }));
    }
  }, [dispatch, listingId, listingStatusTitle, isBuyer]);

  const handleDocumentDownload = ({ documentId }) => {
    if (isBuyer && listingStatusTitle === 'active') {
      dispatch(addListingDocumentDownload({ listingId, documentId }));
    }
  };

  const minimumBidFormatted = useMemo(
    () => (Number(property?.minimumBid)
      ? formatNumberToCurrency(property?.minimumBid)
      : '-'),
    [property],
  );
  const netAfeFormatted = useMemo(
    () => formatNumberToCurrency(netAfe),
    [netAfe],
  );
  const totalWellGrossAfeFormatted = useMemo(
    () => formatNumberToCurrency(totalWellGrossAfe),
    [totalWellGrossAfe],
  );
  const bidAmountFormatted = useMemo(
    () => formatNumberToCurrency(bidAmount),
    [bidAmount],
  );
  const bidCarryPercentageFormatted = useMemo(
    () => `${bidCarryPercentage || 0}%`,
    [bidCarryPercentage],
  );
  const transactionType = property?.transactionType
    ? `${property.transactionType[0].toUpperCase()}${property.transactionType.substring(
      1,
    )}`
    : '-';
  const isCashTransaction = property?.transactionType === TransactionTypes.Cash;
  const isCarryTransaction
    = property?.transactionType === TransactionTypes.Carry;
  const isEitherTransaction
    = property?.transactionType === TransactionTypes.Either;

  const [bidTypeSelection, setBidTypeSelection] = React.useState(
    isCarryTransaction ? TransactionTypes.Carry : TransactionTypes.Cash,
  );

  useEffect(() => {
    if (existingBid) {
      setBidTypeSelection(
        property?.bids[0].amount
          ? TransactionTypes.Cash
          : TransactionTypes.Carry,
      );
    } else if (isCarryTransaction) {
      setBidTypeSelection(TransactionTypes.Carry);
    } else {
      setBidTypeSelection(TransactionTypes.Cash);
    }
  }, [existingBid, isCarryTransaction, property]);

  const transaction = useMemo(() => {
    let result;
    if (property?.transaction) {
      result = {
        ...property.transaction,
        createdAt: property.escrow?.createdDate || property.soldAt,
        isComplete: isListingTransactionComplete(property),
        isCanceled: isListingTransactionCanceled(property),
      };
    }
    return result;
  }, [property]);

  const closingFees = bidCommission;

  const totalCost = bidTotalCost;

  const previousPagePath = useMemo(() => {
    const prevPath = location.state?.prevPath;
    if (prevPath) {
      return `${prevPath.pathname}${prevPath.search}`;
    }
    return '/listings';
  }, [location]);

  const visibleStepIds = useMemo(
    () => steps
      .filter(step => {
        let result;
        if (transaction?.escrow) {
          result = step.closingMethods.includes(ClosingMethod.EscrowDotCom);
        } else {
          result = step.closingMethods.includes(
            ClosingMethod.AlternativeEscrowAgent,
          );
        }
        return result;
      })
      .map(step => step.id),
    [transaction],
  );

  let activeStepId;
  if (transaction) {
    if (transaction.isComplete) {
      activeStepId = TRANSACTION_CLOSED;
    } else if (transaction.escrow?.payment.isDisbursed) {
      activeStepId = FUNDS_DISBURSED;
    } else if (transaction.escrow?.item.status.accepted) {
      activeStepId = DOCUMENTS_ACCEPTED;
    } else if (transaction.escrow?.item.status.received) {
      activeStepId = DOCUMENTS_RECEIVED;
    } else if (transaction.escrow?.item.status.shipped) {
      activeStepId = DOCUMENTS_SENT;
    } else if (transaction.escrow?.payment.isSecured) {
      activeStepId = PAYMENT_SECURED;
    } else if (transaction.escrow?.payment.isInitiated) {
      activeStepId = PAYMENT_SENT;
    } else if (!transaction.escrow) {
      activeStepId = TRANSACTION_CREATED;
    }
  }

  const activeStepIndex = getStepIndex(activeStepId, visibleStepIds);

  const lastTimeFiredRef = useRef(0);

  useEffect(() => {
    let result;
    if (
      transaction?.escrow
      && !transaction.isComplete
      && !transaction.isCanceled
    ) {
      const debounceThresholdMillis = 1000;
      const handleVisibilityChange = () => {
        const now = Date.now();
        if (
          !document.hidden
          && now > lastTimeFiredRef.current + debounceThresholdMillis
        ) {
          lastTimeFiredRef.current = now;
          dispatch(getCurrentProperty(listingId));
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);
      window.addEventListener('focus', handleVisibilityChange);
      result = () => {
        window.removeEventListener('focus', handleVisibilityChange);
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
        );
      };
    }
    return result;
  }, [dispatch, transaction, listingId, lastTimeFiredRef]);

  useEffect(() => {
    let result;
    if (
      transaction
      && !isOwnerCompany
      && !isAdminOrComplianceUser
      && !transaction.isComplete
      && !transaction.isCanceled
      && (!transaction.escrow?.payment.isInitiated || !transaction.escrow)
    ) {
      const updateTimeRemaining = () => {
        const dueDate = DateTime.fromISO(transaction.payment.dueDate);
        const nowDate = DateTime.local();
        let remaining = '';
        if (dueDate > nowDate) {
          remaining = Interval.fromDateTimes(nowDate, dueDate)
            .toDuration()
            .toFormat("d'D' hh'H' mm'M'");
        } else {
          remaining = 'no';
        }
        setPayTimeRemaining(remaining);
      };

      updateTimeRemaining();
      const interval = setInterval(updateTimeRemaining, 1000);
      result = () => clearInterval(interval);
    } else {
      setPayTimeRemaining('');
    }
    return result;
  }, [transaction, isOwnerCompany, isAdminOrComplianceUser]);

  const PropertyHeader = ({ updateBidOnProperty }) => {
    if (isOwnerCompany || isAdminOrComplianceUser) {
      if (listingNotYetClosed(property)) {
        return (
          <>
            {listingIsActive(property)
              && !isAdminOrComplianceUser
              && (user.isAuthorizedSigner || user.isViewOnlyListingEditor) && (
                <LinkButton
                  buttonColor="clear"
                  label="Close Auction"
                  path="/contact-us"
                  data-cy="closeAuctionButton"
                />
            )}
            {isOwner
              && (user.isAuthorizedSigner || user.isViewOnlyListingEditor) && (
                <LinkButton
                  dataCy="editListingButton"
                  path={`/listings/${property.id}/edit`}
                  buttonColor="clear"
                  label="Edit"
                />
            )}
          </>
        );
      }

      if (transaction) {
        let label = '';
        let path = '';
        let disabled = listingIsArchived(property) || !user.isAccountManager;
        let customStyles = {};
        let openInNewTab = false;
        switch (activeStepId) {
          case TRANSACTION_CREATED:
          // fall through
          case undefined:
          // fall through
          case PAYMENT_SENT:
          // fall through
          case PAYMENT_SECURED:
            label = 'Send Documents';
            customStyles = {
              width: '100px',
              height: '70px',
            };
            break;
          case DOCUMENTS_SENT:
          // fall through
          case DOCUMENTS_RECEIVED:
          // fall through
          case DOCUMENTS_ACCEPTED:
          // fall through
          case FUNDS_DISBURSED:
            label = 'Escrow In Progress';
            customStyles = {
              width: '100px',
              height: '75px',
            };
            disabled = true;
            break;
          case TRANSACTION_CLOSED:
            label = 'Escrow Complete';
            customStyles = {
              width: '100px',
              height: '85px',
            };
            disabled = true;
            break;
          default:
            break;
        }

        if (
          transaction.escrow
          && !transaction.escrow?.seller.isDisbursementMethodSelected
        ) {
          label = 'Select Disbursement Method';
          customStyles = {
            height: '85px',
          };
        }

        if (transaction.escrow?.nextStep?.webUrl) {
          path = transaction.escrow.nextStep.webUrl;
          openInNewTab = true;
          disabled = false;
        }

        if (transaction.escrow?.verificationWebUrl) {
          label = 'Get Verified';
          path = transaction.escrow.verificationWebUrl;
          customStyles = {};
          disabled = listingIsArchived(property) || !user.isAccountManager;
          openInNewTab = false;
        }

        if (!path) {
          disabled = true;
        }

        if (isAdminOrComplianceUser) {
          disabled = true;
        }

        return (
          <LinkButton
            key="transactionActionButton"
            path={path}
            external
            openInNewTab={openInNewTab}
            buttonColor="clearGreen"
            label={label}
            disabled={disabled}
            customStyles={customStyles}
          />
        );
      }
    } else if (transaction) {
      let label = '';
      let path = '';
      let disabled = listingIsArchived(property) || !user.isAccountManager;
      let doEscrowPay = false;
      let customStyles = {};
      let openInNewTab = false;
      switch (activeStepId) {
        case TRANSACTION_CREATED:
        // fall through
        case undefined:
          label = 'Pay Now';
          doEscrowPay = true;
          break;
        case PAYMENT_SENT:
          label = 'Review Pay';
          doEscrowPay = true;
          openInNewTab = true;
          break;
        case PAYMENT_SECURED:
          disabled = true;
        // fall through
        case DOCUMENTS_SENT:
          label = 'Receive Documents';
          customStyles = {
            width: '100px',
            height: '70px',
          };
          break;
        case DOCUMENTS_RECEIVED:
          label = 'Accept/Reject Documents';
          customStyles = {
            width: '130px',
            height: '85px',
          };
          break;
        case DOCUMENTS_ACCEPTED:
        // fall through
        case FUNDS_DISBURSED:
          label = 'Escrow In Progress';
          customStyles = {
            width: '100px',
            height: '75px',
          };
          disabled = true;
          break;
        case TRANSACTION_CLOSED:
          label = 'Escrow Complete';
          customStyles = {
            width: '100px',
            height: '85px',
          };
          disabled = true;
          break;
        default:
          break;
      }

      if (doEscrowPay && transaction.escrow) {
        path = transaction.escrow.payment.webUrl;
      } else if (transaction.escrow?.nextStep?.webUrl) {
        path = transaction.escrow.nextStep.webUrl;
        openInNewTab = true;
        disabled = listingIsArchived(property) || !user.isAccountManager;
      }

      if (!path) {
        disabled = true;
      }
      if (existingBid?.status.title === 'winner') {
        updateBidOnProperty(true);
      }
      return (
        <LinkButton
          path={path}
          external
          openInNewTab={openInNewTab}
          buttonColor="clearGreen"
          label={label}
          disabled={disabled}
          customStyles={customStyles}
        />
      );
    } else if (user.isAuthorizedSigner) {
      if (existingBid) {
        updateBidOnProperty(true);
      } else if (!listingIsActive(property)) {
        updateBidOnProperty(false);
      } else {
        updateBidOnProperty(true);
      }
      return null;
    }
    return null;
  };

  const closingFeesForAllWells = property?.wells.reduce((acc, well) => {
    const fees
      = wellAllocationBids[well.id] === undefined
        ? 0
        : computeCommission({
          bidAmount:
              bidTypeSelection === TransactionTypes.Carry
                ? undefined
                : wellAllocationBids[well.id],
          netAfe: +well.wellNetAFE,
        });
    return fees + acc;
  }, 0);

  const totalBidAmount
    = bidTypeSelection === TransactionTypes.Carry
      ? undefined
      : Object.values(wellAllocationBids).reduce(
        (acc, val) => Number(acc) + Number(val),
        0,
      );

  const totalListingCost = useMemo(
    () => (Object.values(wellAllocationBids).length === property?.wells.length
      && !Object.values(wellAllocationBids).some(v => v === 0)
      ? computeTotalListingCost({
        bidAmount: totalBidAmount,
        closingFees: closingFeesForAllWells,
      })
      : undefined),
    [
      wellAllocationBids,
      closingFeesForAllWells,
      totalBidAmount,
      property?.wells,
    ],
  );

  const handleBidTypeSelectionChange = type => {
    setBidTypeSelection(type);
    dispatch(change('makeBet', 'carryPercentage', null));
    dispatch(change('makeBet', 'amount', null));
    setWellAllocationBids({});
  };

  const handleCarryAmountChange = well => e => {
    setWellAllocationBids({
      ...wellAllocationBids,
      [well.id]: maskedAmountToNumber(e.target.value) || 0,
    });
  };

  useEffect(() => {
    prevBidTypeSelectionRef.current = bidTypeSelection;
  }, [bidTypeSelection]);

  useEffect(() => {
    if (wellAllocationBids) {
      const result = Object.values(wellAllocationBids).reduce(
        (acc, val) => acc + val,
        0,
      );
      if (bidTypeSelection === TransactionTypes.Carry) {
        dispatch(change('makeBet', 'carryPercentage', result));
        dispatch(change('makeBet', 'amount', null));
      } else {
        dispatch(change('makeBet', 'amount', result));
        dispatch(change('makeBet', 'carryPercentage', null));
      }
    }
  }, [wellAllocationBids, bidTypeSelection, dispatch]);

  const wells = property?.wells;

  const inputDisabled = useMemo(
    () => !companyIsApprovedAndActive
      || !hasBankInformation
      || !hasBuyerAgreement
      || existingBid
      || isOwner
      || !listingIsActive,
    [
      existingBid,
      companyIsApprovedAndActive,
      hasBankInformation,
      hasBuyerAgreement,
      isOwner,
    ],
  );

  // eslint-disable-next-line no-shadow
  const onHandleSubmit = () => {
    const submitBid = () => {
      const createWellAllocationBids = Object.entries(wellAllocationBids).map(
        ([wellId, value]) => {
          const currWellCost = computeCommission({
            bidAmount:
              bidTypeSelection === TransactionTypes.Carry ? undefined : +value,
            netAfe: +wells.find(w => w.id === +wellId)?.wellNetAFE,
          });
          return {
            id: wellId,
            commission: currWellCost,
            amount: bidTypeSelection === TransactionTypes.Carry ? null : value,
            carryPercentage:
              bidTypeSelection === TransactionTypes.Carry ? value : null,
            totalCost: computeTotalListingCost({
              bidAmount: +value,
              closingFees: currWellCost,
            }),
          };
        },
      );
      dispatch(
        makeBet({
          grantConsideration,
          commission: closingFeesForAllWells,
          totalCost: totalListingCost,
          id: listingId,
          bidTypeSelection,
          wellAllocationBids: createWellAllocationBids,
        }),
      );
    };

    setModal(
      <SubmitConfirmationModal
        heading="As a reminder, all bids are binding. Are you sure you want to submit this bid?"
        confirmLabel="Submit"
        handleSubmit={submitBid}
      />,
    );
  };

  if (!property) return <Spinner />;

  return (
    <>
      <Header />
      <Grid container className={classes.wrapper}>
        <Grid container justify="center" className={classes.container}>
          <Grid container justify="center" className={classes.topSection}>
            <Grid container className={classes.topSectionContent}>
              <Grid item className={classes.arrow}>
                <Link to={previousPagePath}>
                  <BackArrow className={classes.icon} />
                </Link>
              </Grid>

              <Grid container item xs>
                <Grid
                  container
                  alignItems="center"
                  className={classes.topInfoContainer}
                >
                  <Grid item className={classes.heading}>
                    <span>
                      {`${property.projectName}, `}
                      <span className={classes.wellsCount}>
                        {`${propertyWells.length} ${
                          propertyWells.length > 1 ? 'wells' : 'well'
                        }`}
                      </span>
                    </span>
                  </Grid>

                  <Grid
                    container
                    justify="flex-end"
                    item
                    md={2}
                    lg={2}
                    xl={2}
                    className={classes.idContainer}
                  >
                    <span className={classes.id}>
                      <span className={classes.idNumber}>
                        {`LISTING-${property.id}`}
                      </span>
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  container
                  alignItems="center"
                  className={classes.topSectionContainer}
                >
                  <div className={classes.buttonGroup} style={{ gap: '23px' }}>
                    <Grid className={classes.topSectionHeading}>
                      <span className={classes.topHeading}>Seller:</span>
                      <span className={classes.topSectionDescription}>
                        {property.isAnonymous
                          ? 'Anonymous'
                          : getCompanyName(property.user.company)
                            || '<missing company>'}
                      </span>
                    </Grid>

                    <Grid className={classes.topSectionHeading}>
                      <span className={classes.topHeading}>Operator:</span>
                      <span className={classes.topSectionDescription}>
                        {property.operatorName}
                      </span>
                    </Grid>

                    {isOwnerCompany || isAdminOrComplianceUser ? (
                      <>
                        <Grid
                          className={cx(
                            classes.topSectionHeading,
                            classes.separation,
                          )}
                        >
                          <span className={classes.topHeading}>
                            Bid status:
                          </span>
                          <span className={classes.sellerBidStatus}>
                            {isOwnerCompany && 'This is your property'}
                            {isAdminOrComplianceUser && 'Admins cannot bid'}
                          </span>
                        </Grid>
                        {winningBid && (
                          <Grid
                            container
                            direction="column"
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            lg={2}
                            xl={2}
                            className={cx(
                              classes.topSectionHeading,
                              classes.separation,
                            )}
                          >
                            <span className={classes.topHeading}>Winner:</span>
                            <span className={classes.topSectionDescription}>
                              {`${getBidUsername(
                                winningBid,
                              )} (${getBidCompanyName(winningBid)})`}
                            </span>
                          </Grid>
                        )}
                      </>
                    ) : (
                      <Grid
                        className={cx(
                          classes.topSectionHeading,
                          classes.separation,
                        )}
                      >
                        <span className={classes.topHeading}>Bid status:</span>
                        <span className={classes.topSectionDescription}>
                          {getMyBidStatusText(existingBid)}
                        </span>
                      </Grid>
                    )}

                    <Grid className={classes.topSectionHeading}>
                      <span className={classes.topHeading}>Location:</span>
                      <span className={classes.topSectionDescription}>
                        {property.state.title}
                        {', '}
                        {property.basin.title}
                        {', '}
                        {property.county.title}
                      </span>
                    </Grid>

                    <Grid item className={classes.listingStatus}>
                      <span className={classes.listingStatusHeading}>
                        Status:
                      </span>
                      <StatusBadge
                        status={
                          existingBid?.status.title === 'winner'
                            ? 'winner'
                            : property.status.title
                        }
                        data-cy="listingStatusBadge"
                      />
                      {property.archivedAt && (
                        <StatusBadge
                          status="archived"
                          data-cy="listingStatusBadge"
                        />
                      )}
                    </Grid>
                  </div>
                  <div className={classes.buttonGroup}>
                    <PropertyHeader updateBidOnProperty={setCanBidOnProperty} />
                    {payTimeRemaining
                      && (payTimeRemaining === 'no' ? (
                        (!!transaction?.escrow && (
                          <span style={{ color: 'red' }}>Payment overdue!</span>
                        ))
                        || 'Payment past due'
                      ) : (
                        <>
                          {payTimeRemaining}
                          {' '}
                          left
                        </>
                      ))}

                    {(isOwnerCompany || isAdminOrComplianceUser)
                      && listingHasBeenActive(property) && (
                        <>
                          <LinkButton
                            path={`/listings/${property.id}/bids`}
                            buttonColor="clear"
                            label={`Bids (${property.bidCount})`}
                            data-cy="bidsButton"
                          />
                          <LinkButton
                            path={`/listings/${property.id}/analytics`}
                            buttonColor="clear"
                            label="Analytics"
                          />
                        </>
                    )}
                    <DocumentsMenu
                      documents={property.documents}
                      listingId={property.id}
                      onDownload={handleDocumentDownload}
                      user={user}
                      property={property}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.bottomSectionContent}>
            <div>
              {transaction && (
                <>
                  <Watermark
                    text={watermarkText}
                    rotate="-20"
                  >
                    <Grid container className={classes.detailsContainer}>
                      <Grid container alignItems="center">
                        <span className={classes.contentHeading}>
                          {transaction.escrow
                            ? 'Escrow'
                            : 'Transaction (Alternative Closing Method)'}
                        </span>
                      </Grid>

                      <Grid container className={classes.bottomContentContainer}>
                        {/* TODO: [UX][MOBILE] make the stepper look good on mobile */}
                        <Stepper
                          alternativeLabel
                          activeStep={activeStepIndex}
                          connector={<ColorlibConnector />}
                          classes={{
                            horizontal: classes.stepperHorizontal,
                          }}
                        >
                          {visibleStepIds.map((stepId, index) => (
                            <Step
                              key={stepId}
                              data-cy={`transactionStep-${stepId}-${
                                index > activeStepIndex ? 'pending' : 'done'
                              }`}
                            >
                              <StepLabel
                                StepIconComponent={ColorlibStepIcon}
                                StepIconProps={{
                                  visibleStepIds,
                                }}
                              >
                                {stepIdStepMap.get(stepId).label}
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                        {transaction?.escrow && (
                        <ul>
                          <li>
                            <Checkbox
                              name="verified"
                              label="Escrow ID verified"
                              checked={!!transaction.escrow.isVerified}
                              disabled
                              onChange={() => undefined}
                            />
                          </li>
                          {(isOwnerCompany || isAdminOrComplianceUser) && (
                            <li>
                              <Checkbox
                                name="disbursementMethodSelected"
                                label="Disbursement method selected"
                                checked={
                                  !!transaction.escrow.seller
                                    .isDisbursementMethodSelected
                                }
                                disabled
                                onChange={() => undefined}
                              />
                            </li>
                          )}
                        </ul>
                        )}
                      </Grid>
                    </Grid>
                  </Watermark>
                </>
              )}
              <Grid container className={classes.detailsContainer}>
                {/* This Watermark is set inside the detailsContainer so the
                zIndex can be set between the container and the placeBidButton */}
                <Watermark
                  text={watermarkText}
                  rotate="-20"
                >
                  <Grid className={classes.descriptionContainer}>
                    <span>Description</span>
                    {isEitherTransaction && (
                      <div className={classes.sidebarContainer}>
                        <Button
                          className={classes.button}
                          variant="outlined"
                          style={{
                            ...(bidTypeSelection === TransactionTypes.Cash
                              ? { background: '#7890a6', color: '#fff' }
                              : {}),
                            borderRight: 'none',
                            borderRadius: '8px 0 0 8px',
                          }}
                          onClick={() => handleBidTypeSelectionChange(TransactionTypes.Cash)}
                        >
                          Cash
                        </Button>
                        <Button
                          className={classes.button}
                          style={{
                            ...(bidTypeSelection === TransactionTypes.Carry
                              ? { background: '#7890a6', color: '#fff' }
                              : {}),
                            borderLeft: 'none',
                            borderRadius: '0px 8px 8px 0px',
                          }}
                          variant="outlined"
                          onClick={() => handleBidTypeSelectionChange(TransactionTypes.Carry)}
                        >
                          Carry
                        </Button>
                      </div>
                    )}
                  </Grid>
                  {canBidOnProperty && !existingBid && (
                    <div className={classes.notificationText}>
                      <img src={notificationIcon} alt="notification" />
                      {`Enter your ${
                        bidTypeSelection === TransactionTypes.Carry
                          ? 'Carry'
                          : 'Cash'
                      } bid to continue`}
                    </div>
                  )}
                  <Grid
                    container
                    className={cx(
                      classes.bottomContentContainer,
                      classes.wellsDesktop,
                    )}
                  >
                    {propertyWells.map((item, index) => (
                      <Grid
                        item
                        container
                        direction="column"
                        key={index}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={3}
                        xl={3}
                        className={classes.bottomSectionHeading}
                      >
                        <Grid
                          item
                          className={classes.bottomSectionHeading}
                          container
                          direction="column"
                        >
                          <div>
                            Landing Zone
                            {index + 1}
                          </div>
                        </Grid>
                        <Grid
                          item
                          className={classes.bottomSectionHeading}
                          container
                          direction="column"
                        >
                          <div>Name</div>
                          <div className={classes.bottomSectionDescription}>
                            {item.wellName}
                          </div>
                        </Grid>
                        <Grid
                          item
                          className={classes.bottomSectionHeading}
                          container
                          direction="column"
                        >
                          <div>Working Interest</div>
                          <div className={classes.bottomSectionDescription}>
                            {item.workingInterestPercentage || 0}
                            %
                          </div>
                        </Grid>
                        <Grid
                          item
                          className={classes.bottomSectionHeading}
                          container
                          direction="column"
                        >
                          <div>Net Revenue Interest</div>
                          <div className={classes.bottomSectionDescription}>
                            {item.netRevenueInterestNumber || 0}
                            %
                          </div>
                        </Grid>
                        <Grid
                          item
                          className={classes.bottomSectionHeading}
                          container
                          direction="column"
                        >
                          <div>Well Gross AFE Amount</div>
                          <div className={classes.bottomSectionDescription}>
                            {formatNumberToCurrency(item.wellGrossAfe)}
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <div
                    className={classes.wellsContainer}
                    style={
                    isOwnerCompany || !canBidOnProperty
                      ? { gridTemplateColumns: 'repeat(9, 1fr)' }
                      : {}
                  }
                  >
                    <Grid className={classes.bottomSectionHeading}>
                      <div>Name</div>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <div>API Number</div>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <div>Landing Zone</div>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <div>Net Working Interest</div>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <div>8/8ths Revenue Interest</div>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <FormulaTooltip
                        title="Net Working Interest * 8/8ths Revenue Interest"
                        maxWidth="335px"
                      >
                        <div>
                          Net Revenue Interest
                        </div>
                      </FormulaTooltip>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <div>Gross AFE</div>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <FormulaTooltip
                        title="Net Working Interest * Well Gross AFE"
                      >
                        <div>Net AFE</div>
                      </FormulaTooltip>
                    </Grid>
                    <Grid className={classes.bottomSectionHeading}>
                      <div>
                        {`${
                          bidTypeSelection === TransactionTypes.Cash
                            ? 'Cash'
                            : 'Carry'
                        } Minimum Bid`}
                      </div>
                    </Grid>
                    {!isOwnerCompany && canBidOnProperty && (
                      <Grid
                        className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                        style={{ borderLeft: 'solid 1px #000000' }}
                      >
                        <div>Bid</div>
                      </Grid>
                    )}
                    {!isOwnerCompany && canBidOnProperty && (
                      <Grid
                        className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                      >
                        <FormulaTooltip
                          title={bidTypeSelection === TransactionTypes.Carry
                            ? 'Net Working Interest * ( 1 - Carry Bid )'
                            : 'Net Working Interest'}
                        >
                          <div>Buyer Assigned Net WI</div>
                        </FormulaTooltip>
                      </Grid>
                    )}
                    {!isOwnerCompany && canBidOnProperty && (
                      <Grid
                        className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                      >
                        {bidTypeSelection === TransactionTypes.Carry
                          ? (
                            <FormulaTooltip
                              title="Net Working Interest * Carry Bid"
                            >
                              <div>Seller Retained Net WI</div>
                            </FormulaTooltip>
                          ) : (
                            <div>Seller Retained Net WI</div>
                          )}
                      </Grid>
                    )}
                    {!isOwnerCompany && canBidOnProperty && (
                      <Grid
                        className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                      >
                        <FormulaTooltip
                          title={`The minimum of 3% * Well Net AFE and 15% * ${
                            bidTypeSelection === TransactionTypes.Carry
                              ? 'Implied Purchase Price'
                              : 'Cash Bid'
                          }`}
                          maxWidth="300px"
                        >
                          <div>Closing Fee</div>
                        </FormulaTooltip>
                      </Grid>
                    )}
                    {!isOwnerCompany && canBidOnProperty && (
                      <Grid
                        className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                      >
                        <FormulaTooltip
                          title={bidTypeSelection === TransactionTypes.Carry
                            ? 'Well Gross AFE * Seller Retained Net WI'
                            : 'Closing Fee + Cash Bid'}
                        >
                          <div>
                            {bidTypeSelection === TransactionTypes.Carry
                              ? 'Implied Price'
                              : 'Total Bid'}
                          </div>
                        </FormulaTooltip>
                      </Grid>
                    )}
                  </div>

                  {propertyWells.map((item, index) => {
                    const existingBidPerWell = property?.bidPerWells?.find(
                      bid => +bid.wellId === +item.id,
                    );
                    const acquiredPercentage = computeAcquiredRetainedInterest({
                      bidCarryPercentage: existingBid
                        ? existingBidPerWell.carryPercentage
                        : bidTypeSelection === TransactionTypes.Carry
                          ? wellAllocationBids[item.id]
                          : undefined,
                      sellerOriginalOwnershipPercentage:
                      item.workingInterestPercentage,
                    });
                    const closingFee
                    = wellAllocationBids[item.id] === undefined
                      ? undefined
                      : computeCommission({
                        bidAmount:
                            bidTypeSelection === TransactionTypes.Carry
                              ? undefined
                              : wellAllocationBids[item.id],
                        netAfe: +item.wellNetAFE,
                      });

                    const closingFeesFormatted = closingFees => (closingFees === undefined
                      ? '-'
                      : formatNumberToCurrency(closingFees));

                    const eightsCalculations = well => {
                      if (!well) {
                        return '';
                      }

                      const workingInterestPercentage = parseFloat(
                        well?.workingInterestPercentage,
                      );
                      const netRevenueInterestNumber = parseFloat(
                        well?.netRevenueInterestNumber,
                      );

                      if (
                        !workingInterestPercentage
                      || !netRevenueInterestNumber
                      ) {
                        return '';
                      }

                      const decimals = Math.min(
                        Math.max(
                          decimalCount(workingInterestPercentage),
                          decimalCount(netRevenueInterestNumber),
                        ),
                        10,
                      );

                      return (
                        (netRevenueInterestNumber / workingInterestPercentage)
                      * 100
                      ).toFixed(decimals || 1);
                    };
                    return (
                      <React.Fragment key={item.id}>
                        <div
                          className={classes.wellsContainer}
                          style={
                          isOwnerCompany || !canBidOnProperty
                            ? { gridTemplateColumns: 'repeat(9, 1fr)' }
                            : {}
                        }
                        >
                          <Grid item className={classes.bottomSectionHeading}>
                            <div className={classes.bottomSectionDescription}>
                              {item.wellName}
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            <div className={classes.bottomSectionDescription}>
                              {item.wellAPINumber || '-'}
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            <div className={classes.bottomSectionDescription}>
                              {item.landingZone.title}
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            <div className={classes.bottomSectionDescription}>
                              {item.workingInterestPercentage || 0}
                              %
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            <div className={classes.bottomSectionDescription}>
                              {`${eightsCalculations(item)}%`}
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            <div className={classes.bottomSectionDescription}>
                              {item.netRevenueInterestNumber || 0}
                              %
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            <div className={classes.bottomSectionDescription}>
                              {formatNumberToCurrency(item.wellGrossAfe)}
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            <div className={classes.bottomSectionDescription}>
                              {formatNumberToCurrency(item.wellNetAFE)}
                            </div>
                          </Grid>
                          <Grid item className={classes.bottomSectionHeading}>
                            {bidTypeSelection === TransactionTypes.Cash && (
                              <div className={classes.bottomSectionDescription}>
                                {item.minimumBid === '0'
                                  ? '-'
                                  : formatNumberToCurrency(String(item.minimumBid))}
                              </div>
                            )}
                            {bidTypeSelection === TransactionTypes.Carry && (
                              <div className={classes.bottomSectionDescription}>
                                {item.minimumBidCarry === '0'
                                  ? '-'
                                  : `${String(item.minimumBidCarry)}%`}
                              </div>
                            )}
                          </Grid>
                          {!isOwnerCompany && canBidOnProperty && (
                            <>
                              <Grid
                                item
                                className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                                style={{ borderLeft: 'solid 1px #000000' }}
                              >
                                <div
                                  className={classes.bottomSectionDescription}
                                  style={{ marginTop: '6px' }}
                                >
                                  {bidTypeSelection === TransactionTypes.Cash ? (
                                    <Grid
                                      item
                                      xs={12}
                                      key={item.id}
                                      className={classes.amountContainer}
                                    >
                                      {existingBid ? (
                                        <AmountInput
                                          disabled
                                          input={{
                                            value:
                                            property.bidPerWells.find(
                                              bid => bid.wellId === item.id,
                                            )?.amount || '',
                                          }}
                                          meta={{}}
                                        />
                                      ) : (
                                        <AmountInput
                                          disabled={isOwnerCompany}
                                          input={{
                                            placeholder:
                                            '$ Enter your cash bid amount',
                                            onChange:
                                            handleCarryAmountChange(item),
                                            value:
                                            wellAllocationBids[item.id] || '',
                                          }}
                                          meta={{}}
                                        />
                                      )}
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      xs={12}
                                      key={item.id}
                                      className={classes.amountContainer}
                                    >
                                      {existingBid ? (
                                        <AmountInput
                                          disabled
                                          maskType="percent"
                                          input={{
                                            value: property.bidPerWells.find(
                                              bid => bid.wellId === item.id,
                                            )?.carryPercentage || '',
                                          }}
                                          meta={{}}
                                        />
                                      ) : (
                                        <AmountInput
                                          maskType="percent"
                                          disabled={isOwnerCompany}
                                          input={{
                                            placeholder:
                                            '% Enter your carry bid percentage',
                                            onChange:
                                            handleCarryAmountChange(item),
                                            value:
                                            wellAllocationBids[item.id] || '',
                                          }}
                                          meta={{}}
                                        />
                                      )}
                                    </Grid>
                                  )}
                                </div>
                              </Grid>
                              <Grid
                                item
                                className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                              >
                                <div className={classes.bottomSectionDescription}>
                                  {acquiredPercentage.buyerAcquiredPercentage.toFixed()}
                                  %
                                </div>
                              </Grid>
                              <Grid
                                item
                                className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                              >
                                <div className={classes.bottomSectionDescription}>
                                  {acquiredPercentage.sellerRetainedPercentage.toFixed(
                                    2,
                                  )}
                                  %
                                </div>
                              </Grid>
                              <Grid
                                item
                                className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                              >
                                <div className={classes.bottomSectionDescription}>
                                  {closingFeesFormatted(
                                    existingBid
                                      ? existingBidPerWell?.commission
                                      : closingFee,
                                  )}
                                </div>
                              </Grid>
                              <Grid
                                item
                                className={`${classes.bottomSectionHeading} ${classes.darkerTile}`}
                              >
                                <div className={classes.bottomSectionDescription}>
                                  {bidTypeSelection === TransactionTypes.Carry
                                    ? `${formatNumberToCurrency(computeImpliedPrice(
                                      item.wellGrossAfe,
                                      acquiredPercentage.sellerRetainedPercentage,
                                    ).toFixed(0))}`
                                    : existingBid
                                      ? `$${formatNumberToCurrency(existingBidPerWell.totalCost)}`
                                      : `${
                                        formatNumberToCurrency(closingFee
                                        + wellAllocationBids[item.id]) || '-'
                                      }`}
                                </div>
                              </Grid>
                            </>
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}
                  {!isOwnerCompany && canBidOnProperty && (
                    <>
                      <div className={classes.totalContainer}>
                        <TotalListingCost
                          grantConsideration={grantConsideration}
                          totalCost={
                          existingBid
                            ? property?.bids[0]?.totalCost
                            : totalListingCost
                        }
                        />
                      </div>
                      <Grid
                        component="form"
                        onSubmit={handleSubmit(onHandleSubmit)}
                        className={classes.bidWrapper}
                      >
                        {!hidePlaceBidButton && (
                        <>
                          <PlaceBidButton disabled={inputDisabled} />
                          <Grid item xs={12}>
                            <StyledLink
                              path="/increase-bid-allowance"
                              style={{ fontSize: '14px' }}
                            >
                              Increase Bid Allowance
                            </StyledLink>
                          </Grid>
                        </>
                        )}
                      </Grid>
                    </>
                  )}
                </Watermark>
              </Grid>
              <Watermark
                text={watermarkText}
                rotate="-20"
              >
                <Grid container className={classes.detailsContainer}>
                  <Grid container alignItems="center">
                    <span className={classes.contentHeading}>
                      Bidding Details
                    </span>
                  </Grid>

                  <Grid
                    container
                    justifyContent="flex-start"
                    className={classes.bottomContentContainer}
                  >
                    <Grid
                      container
                      direction="column"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                      className={cx(
                        classes.biddingDetailsSectionHeading,
                        classes.redText,
                      )}
                    >
                      <span>Time Remaining</span>
                      <span
                        className={classes.contentBorders}
                        style={{ marginTop: '8px', padding: '6.5px 0px' }}
                      >
                        <Countdown
                          className={classes.biddingDetailsSectionDescription}
                          endTime={toAuctionEventTime(
                            new Date(property.endTime),
                            'end',
                          )
                            .toJSDate()
                            .toISOString()}
                          withoutLabel
                          withoutAdaptive
                        />
                      </span>
                    </Grid>

                    <Grid
                      container
                      direction="column"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                      className={classes.biddingDetailsSectionHeading}
                    >
                      <span>End Time</span>
                      <span
                        className={cx(
                          classes.biddingDetailsSectionDescription,
                          classes.contentBorders,
                        )}
                      >
                        {`${nonOpWellsDateTime(
                          new Date(property.endTime),
                        ).toFormat('MM / dd / yyyy / t ZZZZ')}`}
                      </span>
                    </Grid>

                    <Grid
                      container
                      direction="column"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                      className={classes.biddingDetailsSectionHeading}
                    >
                      <span>Total Gross AFE</span>
                      <span
                        className={cx(
                          classes.biddingDetailsSectionDescription,
                          classes.contentBorders,
                        )}
                      >
                        {totalWellGrossAfeFormatted}
                      </span>
                    </Grid>

                    <Grid
                      container
                      direction="column"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      xl={3}
                      className={classes.biddingDetailsSectionHeading}
                    >
                      <span>Total Net AFE</span>
                      <span
                        className={cx(
                          classes.biddingDetailsSectionDescription,
                          classes.contentBorders,
                        )}
                      >
                        {netAfeFormatted}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Watermark>
              <Watermark
                text={watermarkText}
                rotate="-20"
              >
                <Grid container className={classes.detailsContainer}>
                  <Grid
                    container
                    direction="column"
                    className={classes.bottomSectionHeading}
                    style={{ border: 'none' }}
                  >
                    <Box className={classes.contentHeading}>
                      Recently Added Documents
                      <CheckCircleOutlineIcon className={classes.checkIcon} />
                    </Box>
                    <Box className={classes.contentSubHeading}>
                      Below are the recently added documents that have been
                      submitted by the seller after the listing was approved for
                      bidding.
                    </Box>
                    <DocumentsTable
                      listingId={property.id}
                      documents={property.documents.filter(
                        document => Object.keys(listingDocumentAdditionalTypeMap).includes(document.type),
                      )}
                      onDownload={handleDocumentDownload}
                    />
                  </Grid>
                </Grid>
              </Watermark>
              <Watermark
                text={watermarkText}
                rotate="-20"
              >
                <Grid container className={classes.detailsContainer}>
                  <Grid
                    container
                    direction="column"
                    className={classes.bottomSectionHeading}
                    style={{ border: 'none' }}
                  >
                    <Box className={classes.contentHeading}>
                      Original Documents
                    </Box>
                    <Box className={classes.contentSubHeading}>
                      These are documents that have been submitted by the seller
                      prior to the listing being approved for bidding.
                    </Box>
                    <DocumentsTable
                      listingId={property.id}
                      documents={property.documents.filter(
                        document => Object.keys(listingDocumentNewTypeMap).includes(document.type),
                      )}
                      onDownload={handleDocumentDownload}
                    />
                  </Grid>
                </Grid>
              </Watermark>
              <Grid container className={classes.mapContainer}>
                {isUsingEsriMaps() ? (
                  <EsriMap wells={wellsAugmented} />
                ) : (
                  <GoogleMap wells={coordinates} />
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

PropertyView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  property: PropTypes.object,
  user: PropTypes.shape({
    companyId: PropTypes.number,
    isAuthorizedSigner: PropTypes.bool,
    isViewOnlyListingEditor: PropTypes.bool,
    isAccountManager: PropTypes.bool,
    // TODO: [TYPE] fill out remaining fields
  }).isRequired,
  isAdminOrComplianceUser: PropTypes.bool,
};

PropertyView.defaultProps = {
  isAdminOrComplianceUser: false,
};

export default compose(
  reduxForm({
    form: 'makeBet',
  }),
  connect(({ propertyView, auth, company }) => ({
    hasBankInformation: auth.user.company?.hasBankInformation,
    hasBuyerAgreement: auth.user.company?.hasBuyerAgreement,
    companyIsApprovedAndActive:
      !!auth.user.company?.approved && !!auth.user.company?.active,
    property: propertyView.currentProperty,
    user: auth.user,
    isAdminOrComplianceUser: isAdminOrCompliancePerson(auth.user),
    userFullName: getUserFullName(auth.user),
    companyName: company.fullLegalCompanyName,
  })),
  withStyles(styles),
  formValues('amount', 'carryPercentage'),
  memo,
)(PropertyView);
