import {
  decimalCount,
  formatNumberToCurrency,
} from 'now-frontend-shared/utils/helpers';

import {
  computeAcquiredRetainedInterest,
  computeImpliedPrice,
} from 'now-shared/helpers/bid-helpers';

export const getWellDetailById = (property, wellId) => {
  const wellInfo = property.wells.find(w => w.id === wellId);
  return wellInfo;
};

export const getWellBidDetailById = (wellId, bid) => {
  const wellBid = bid.wellBids.find(wb => wb.wellId === wellId);
  return wellBid;
};

export const acquiredPercentage = (wellBid, well) => computeAcquiredRetainedInterest({
  bidCarryPercentage: wellBid.carryPercentage,
  sellerOriginalOwnershipPercentage:
    well.workingInterestPercentage,
});

export const getTotalBidAmount = wellBids => {
  const totalAmount = wellBids.reduce((t, wb) => {
    t += parseInt(wb.amount, 10);
    return t;
  }, 0);
  return totalAmount;
};

export const getTotalImpliedAmount = (wellBids, property) => {
  const totalAmount = wellBids.reduce((t, wb) => {
    const item = getWellDetailById(property, wb.wellId);
    t += parseInt(computeImpliedPrice(
      item.wellGrossAfe,
      acquiredPercentage(wb, item).sellerRetainedPercentage,
    ).toFixed(0), 10);
    return t;
  }, 0);
  return totalAmount;
};

export const closingFeesFormatted = closingFees => (closingFees === undefined
  ? '-'
  : formatNumberToCurrency(closingFees));

export const eightsCalculations = well => {
  if (!well) {
    return '';
  }

  const workingInterestPercentage = parseFloat(
    well?.workingInterestPercentage,
  );
  const netRevenueInterestNumber = parseFloat(
    well?.netRevenueInterestNumber,
  );

  if (
    !workingInterestPercentage
    || !netRevenueInterestNumber
  ) {
    return '';
  }

  const decimals = Math.min(
    Math.max(
      decimalCount(workingInterestPercentage),
      decimalCount(netRevenueInterestNumber),
    ),
    10,
  );

  return (
    (netRevenueInterestNumber / workingInterestPercentage)
    * 100
  ).toFixed(decimals || 1);
};
