import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider, useStore } from 'react-redux';
import { useTheme, withStyles, ThemeProvider } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import * as watchUtils from '@arcgis/core/core/watchUtils';
import esriConfig from '@arcgis/core/config';
import Point from '@arcgis/core/geometry/Point';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Graphic from '@arcgis/core/Graphic';
import GroupLayer from '@arcgis/core/layers/GroupLayer';
import InfoWindow from '../Map/components/InfoWindow';
import {
  historicalWellIconStyle,
  mapAreaOfInterestFillRgba,
  mapAreaOfInterestOutline,
  mapWellAreaLineStyle,
  mapWellIconStyle,
} from '../../themes/colors';
import { pluralize } from 'now-shared/helpers/text-helpers';
import { isUsingEsriMapLayers } from 'now-frontend-shared/features/feature-flags';
import mapTypeImageSatellite from 'now-frontend-shared/assets/map/images/map-type-satellite.png';
import mapTypeImageSatelliteWithLabels from 'now-frontend-shared/assets/map/images/map-type-satellite-with-labels.png';
import mapTypeImageTopographic from 'now-frontend-shared/assets/map/images/map-type-topographic.png';

esriConfig.apiKey = process.env.REACT_APP_ARCGIS_API_KEY;

const Accordion = MuiAccordion;

const AccordionSummary = withStyles({
  root: {
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    padding: 0,
  },
})(MuiAccordionDetails);

const MapType = {
  Topographic: 'topo-vector',
  Satellite: 'satellite',
  SatelliteWithLabels: 'hybrid',
};

const MapTypeLabel = {
  [MapType.Satellite]: 'Satellite',
  [MapType.SatelliteWithLabels]: 'Satellite w/ labels',
  [MapType.Topographic]: 'Topographic',
};

const MapTypeImage = {
  [MapType.Satellite]: mapTypeImageSatellite,
  [MapType.SatelliteWithLabels]: mapTypeImageSatelliteWithLabels,
  [MapType.Topographic]: mapTypeImageTopographic,
};

const defaultMapType = Object.values(MapType)[0];

const MapLayerType = {
  Wells: 'wells',
  Listings: 'listings',
  HistoricalListings: 'historicalListings',
  AreasOfInterest: 'areasOfInterest',
  LandSurvey: 'landSurvey',
  Counties: 'counties',
};

const minSpaceBetweenMapTypeCards = 5;
const minSpaceBetweenMapTypeCardsHalf = minSpaceBetweenMapTypeCards / 2;

const MapTypeCard = props => {
  const {
    mapType,
    selected,
    onClick,
  } = props;

  const theme = useTheme();

  const fontColor = selected
    ? theme.palette.primary.contrastText
    : theme.palette.primary.dark;

  return (
    <Card
      elevation={0}
      style={{
        backgroundColor: selected
          ? theme.palette.primary.main
          : theme.palette.primary.contrastText,
        color: fontColor,
        width: 100,
        height: 118,
        borderRadius: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: minSpaceBetweenMapTypeCardsHalf,
        marginRight: minSpaceBetweenMapTypeCardsHalf,
      }}
    >
      <CardActionArea
        onClick={onClick}
        disabled={selected}
        style={{
          height: '100%',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{
            margin: 0,
            textAlign: 'center',
          }}
        >
          <img
            src={MapTypeImage[mapType]}
            alt={MapTypeLabel[mapType]}
            style={{
              width: 90,
              height: 90,
              magin: 5,
            }}
          />
          <Typography
            variant="body2"
            style={{
              fontSize: '12px',
              paddingTop: '3px',
            }}
          >
            {MapTypeLabel[mapType]}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};

MapTypeCard.propTypes = {
  mapType: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

MapTypeCard.defaultProps = {
  selected: false,
  onClick: undefined,
};

const TriggerAction = {
  OpenListing: 'openListing',
};

const usingClusteringForListings = false;
const usingClusteringForWells = false;
const usingOriginalListingPopup = true;
const usingEmbeddedListingPopup = false;
const usingEsriMapLayers = isUsingEsriMapLayers();

const usaCensusCounties2020ServiceUrl = 'https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Census_Counties/FeatureServer/0';
const plssTownshipServiceUrl = 'https://gis.blm.gov/arcgis/rest/services/Cadastral/BLM_Natl_PLSS_CadNSDI/MapServer/1';
const plssSectionServiceUrl = 'https://gis.blm.gov/arcgis/rest/services/Cadastral/BLM_Natl_PLSS_CadNSDI/MapServer/2';
const texasLandSurveyServiceUrl = 'https://services1.arcgis.com/7DRakJXKPEhwv0fM/arcgis/rest/services/Original_Texas_Land_Survey/FeatureServer/0';

const isNumber = val => (
  val !== undefined
  && val !== null
  && !Number.isNaN(val)
  && (
    typeof val !== 'string'
    || val.trim() !== ''
  )
);

const generateWellClusteringProps = iconStyle => ({
  clusterRadius: '100px',
  clusterMinSize: '24px',
  clusterMaxSize: '60px',
  labelingInfo: [
    {
      deconflictionStrategy: 'none',
      labelExpressionInfo: {
        expression: 'Text($feature.cluster_count, \'#,###\')',
      },
      symbol: {
        type: 'text',
        color: iconStyle.lineColorRgba,
        font: {
          ...iconStyle.font,
        },
        haloSize: iconStyle.haloSize,
        haloColor: iconStyle.haloColorRgba,
      },
      labelPlacement: 'center-center',
    },
  ],
});

const wellClusteringProps = generateWellClusteringProps(mapWellIconStyle);
const historicalWellClusteringProps = generateWellClusteringProps(historicalWellIconStyle);

const generateWellIconRendererProps = iconStyle => ({
  type: 'simple',
  symbol: {
    type: 'simple-marker',
    size: 12,
    color: iconStyle.fillColorRgba,
    outline: {
      color: iconStyle.lineColorRgba,
      width: 2,
    },
  },
});

const wellIconRendererProps = generateWellIconRendererProps(mapWellIconStyle);
const historicalWellIconRendererProps = generateWellIconRendererProps(historicalWellIconStyle);

const defaultComponents = ['attribution'];

const EsriMap = ({
  listings,
  historicalListings,
  wells,
  areasOfInterestPolygons,
  isListingsMap,
  onBasemapLoaded,
}) => {
  const initialCenterRef = useRef({ lat: 40.134701, lng: -102.452763 });

  const history = useHistory();

  const theme = useTheme();

  const [map, setMap] = useState(undefined);
  const [mapView, setMapView] = useState(undefined);
  const [viewDiv, setViewDiv] = useState(undefined);
  const [listingPopup, setListingPopup] = useState(undefined);
  const [maxScaleForCountyNames] = useState(290000);
  const [maxScaleForCountyLines] = useState(maxScaleForCountyNames);
  const [selectedMapType, setSelectedMapType] = useState(defaultMapType);
  const [mapTypesExpanded, setMapTypesExpanded] = useState(false);
  const [mapLayers, setMapLayers] = useState([]);
  const [mapHillshadeLoaded, setMapHillshadeLoaded] = useState(false);
  const [mapTopoLoaded, setMapTopoLoaded] = useState(false);

  const usingLayersList = isListingsMap && usingEsriMapLayers;
  const usingMapTypeSelector = usingEsriMapLayers;
  const usingCounties = isListingsMap && usingEsriMapLayers;
  const usingPublicLandSurvey = isListingsMap && usingEsriMapLayers;
  const usingAreasOfInterest = isListingsMap || !!areasOfInterestPolygons.length;
  const usingListings = isListingsMap || !!listings.length;
  const usingHistoricalListings = isListingsMap || !!historicalListings.length;
  const usingWells = !isListingsMap || !!wells.length;

  const featurePopupDivsRef = useRef([]);

  const listingsRef = useRef();
  listingsRef.current = listings;

  const store = useStore();

  const wellsAugmented = useMemo(
    () => wells.map(
      (well, wellIndex) => ({
        ...well,
        wellIndex,
        wellTitle: `Well ${wellIndex + 1}${well.wellName ? `: <i>${well.wellName}</i>` : ''}`,
        ...isNumber(well.surfaceLatitude) && isNumber(well.surfaceLongitude) ? {
          latitude: +well.surfaceLatitude,
          longitude: +well.surfaceLongitude,
          hasWellPoint: true,
        } : (isNumber(well.bottomLatitude) && isNumber(well.bottomLongitude) && {
          latitude: +well.bottomLatitude,
          longitude: +well.bottomLongitude,
          hasWellPoint: true,
        }),
      }),
    ).filter(well => well.hasWellPoint),
    [wells],
  );

  useEffect(() => {
    if (viewDiv) {
      const newMap = new Map({
        basemap: defaultMapType,
      });

      const view = new MapView({
        map: newMap,
        center: [initialCenterRef.current.lng, initialCenterRef.current.lat],
        zoom: 5,
        container: viewDiv,
        constraints: {
          rotationEnabled: false,
          snapToZoom: false,
          minScale: 561,
          maxScale: 105791662,
        },
        ui: {
          components: defaultComponents,
        },
      });

      view.on('layerview-create', async ({ layer }) => {
        const layerLoadedDelayMillis = 500;
        if (layer.title === 'World Topo') {
          const layerView = await view.whenLayerView(layer);
          watchUtils.whenFalse(layerView, 'updating', () => setTimeout(() => setMapTopoLoaded(true), layerLoadedDelayMillis));
        } else if (layer.title === 'World Hillshade') {
          const layerView = await view.whenLayerView(layer);
          watchUtils.whenFalse(layerView, 'updating', () => setTimeout(() => setMapHillshadeLoaded(true), layerLoadedDelayMillis));
        }
      });

      setMap(newMap);
      setMapView(view);
    }
  }, [viewDiv]);

  useEffect(() => {
    if (mapTopoLoaded && mapHillshadeLoaded && onBasemapLoaded) {
      onBasemapLoaded();
    }
  }, [mapTopoLoaded, mapHillshadeLoaded, onBasemapLoaded]);

  useEffect(() => {
    if (mapView && mapTopoLoaded && mapHillshadeLoaded) {
      mapView.popup.dockOptions = {
        position: 'top-right',
      };

      if (isListingsMap) {
        const onTriggerAction = event => {
          if (event.action.id === TriggerAction.OpenListing) {
            history.push(`/listings/${mapView.popup.selectedFeature.attributes.listingId}`);
          }
        };
        mapView.popup.on('trigger-action', onTriggerAction);
      }

      if (isListingsMap && usingOriginalListingPopup && !usingEmbeddedListingPopup) {
        mapView.on('click', async event => {
          const response = await mapView.hitTest(event.screenPoint);
          let listingPopupOpened = false;

          const graphics = response.results;
          if (graphics.length) {
            const graphic = graphics[0];
            if (graphic.graphic.attributes) {
              const { listingId } = graphic.graphic.attributes;
              if (listingId !== undefined) {
                listingPopupOpened = true;
                const listing = listingsRef.current.find(record => record.propertyId === listingId);
                if (listing) {
                  const listingForInfoWindow = {
                    id: listing.propertyId,
                    projectName: listing.projectName,
                    wellCount: listing.wellCount,
                  };
                  const listingsForInfoWindow = [listingForInfoWindow];

                  const setPopupInfo = () => {
                    const screenPoint = mapView.toScreen(graphic.mapPoint);
                    const popupInfo = {
                      x: screenPoint.x,
                      y: screenPoint.y + wellIconRendererProps.symbol.size + 15,
                      listings: listingsForInfoWindow,
                    };
                    setListingPopup(popupInfo);
                  };

                  setPopupInfo();

                  await mapView.goTo({
                    center: [
                      graphic.mapPoint.longitude,
                      graphic.mapPoint.latitude,
                    ],
                  });

                  setPopupInfo();
                  // close any other feature popup that appears
                  mapView.popup.close();
                }
              }
            }
          }
          if (!listingPopupOpened) {
            // close listing popup if it exists
            setListingPopup(undefined);
          }
        });

        const closeListingPopup = () => setListingPopup(undefined);

        watchUtils.whenTrue(mapView.popup, 'visible', () => {
          closeListingPopup();
          watchUtils.whenFalseOnce(mapView.popup, 'visible', () => {
            if (featurePopupDivsRef.current.length) {
              featurePopupDivsRef.current.forEach(div => ReactDOM.unmountComponentAtNode(div));
              featurePopupDivsRef.current = [];
            }
          });
        });

        // TODO: [UX] instead of closing the popup when the map zoom or pan changes,
        // move the popup to stay in sync with the feature location on the screen.
        watchUtils.watch(mapView, 'zoom', closeListingPopup);
        watchUtils.watch(mapView, 'center', closeListingPopup);
        watchUtils.watch(mapView, 'scale', closeListingPopup);
        watchUtils.watch(mapView, 'extent', closeListingPopup);
        watchUtils.watch(mapView, 'size', closeListingPopup);
      }

      const usingScaleLogger = false;
      if (usingScaleLogger) {
        watchUtils.watch(mapView, 'scale', scale => {
          // eslint-disable-next-line no-console
          console.log('scale', scale);
        });
      }

      const usingZoomLogger = false;
      if (usingZoomLogger) {
        watchUtils.watch(mapView, 'zoom', zoom => {
          // eslint-disable-next-line no-console
          console.log('zoom', zoom);
        });
      }
    }
  }, [mapView, mapTopoLoaded, mapHillshadeLoaded, history, isListingsMap]);

  useEffect(() => {
    if (map && mapTopoLoaded && mapHillshadeLoaded && map.basemap !== selectedMapType) {
      map.basemap = selectedMapType;
    }
  }, [map, mapTopoLoaded, mapHillshadeLoaded, selectedMapType]);

  useEffect(() => {
    if (usingOriginalListingPopup && !usingEmbeddedListingPopup && !mapLayers[MapLayerType.Listings]?.visible) {
      setListingPopup(undefined);
    }
  }, [mapLayers]);

  useEffect(() => {
    let result;
    if (map && mapTopoLoaded && mapHillshadeLoaded && usingCounties) {
      const countiesLayer = new FeatureLayer({
        title: 'USA Census Counties',
        url: usaCensusCounties2020ServiceUrl,
        minScale: 12100000,
        ...maxScaleForCountyLines !== undefined && {
          maxScale: maxScaleForCountyLines,
        },
        labelingInfo: [{
          symbol: {
            type: 'text',
            color: 'gray',
            haloColor: 'white',
            haloSize: 0.1,
            font: {
              family: 'Playfair Display',
              size: 8,
            },
          },
          labelPlacement: 'above-center',
          labelExpressionInfo: {
            expression: String.raw`Replace(Replace($feature.NAME, 'County', ''), 'Parish', '')`,
          },
          minScale: 2700000,
          ...maxScaleForCountyNames !== undefined && {
            maxScale: maxScaleForCountyNames,
          },
        }],
        popupTemplate: {
          title: '{NAME}, {STATE_ABBR}',
          outFields: '*',
          content: [{
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'NAME',
                label: 'County name',
              },
              {
                fieldName: 'STATE_NAME',
                label: 'State name',
              },
              {
                fieldName: 'STATE_ABBR',
                label: 'State abbreviation',
              },
              {
                fieldName: 'STATE_FIPS',
                label: 'State FIPS',
              },
              {
                fieldName: 'COUNTY_FIPS',
                label: 'County FIPS',
              },
              {
                fieldName: 'FIPS',
                label: 'FIPS',
              },
              {
                fieldName: 'POPULATION',
                label: 'Population 2020',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
              },
              {
                fieldName: 'POP_SQMI',
                label: 'Population Density by square miles',
                format: {
                  digitSeparator: true,
                },
              },
              {
                fieldName: 'SQMI',
                label: 'Area in square miles',
                format: {
                  digitSeparator: true,
                },
              },
            ],
          }],
        },
        renderer: {
          type: 'simple',
          symbol: {
            type: 'simple-line',
            size: 12,
            color: 'gray',
            style: 'solid',
          },
        },
      });
      map.add(countiesLayer);
      setMapLayers(layers => ({
        ...layers,
        [MapLayerType.Counties]: countiesLayer,
      }));
      result = () => {
        map.remove(countiesLayer);
        setMapLayers(layers => Object.fromEntries(
          Object.entries(layers).filter(([key]) => key !== MapLayerType.Counties),
        ));
      };
    }
    return result;
  }, [map, mapTopoLoaded, mapHillshadeLoaded, usingCounties, maxScaleForCountyNames, maxScaleForCountyLines]);

  useEffect(() => {
    let result;
    if (map && mapTopoLoaded && mapHillshadeLoaded && usingPublicLandSurvey) {
      const landSurveyLayer = new GroupLayer({
        title: 'Land Survey',
      });

      const publicLandSurveyLayer = new GroupLayer({
        title: 'Public Land Survey System',
      });
      landSurveyLayer.add(publicLandSurveyLayer);

      const townshipMinScale = 720000;
      const sectionMinScale = 170000;

      const plssTownshipLayer = new FeatureLayer({
        title: 'PLSS Township',
        url: plssTownshipServiceUrl,
        minScale: townshipMinScale,
        maxScale: sectionMinScale,
        labelingInfo: [{
          symbol: {
            type: 'text',
            color: 'gray',
            haloColor: 'white',
            haloSize: 0.1,
            font: {
              family: 'Playfair Display',
              size: 8,
            },
          },
          labelPlacement: 'above-center',
          labelExpressionInfo: {
            expression: '$feature.TWNSHPLAB',
          },
        }],
        renderer: {
          type: 'simple',
          symbol: {
            type: 'simple-line',
            size: 12,
            color: 'gray',
            style: 'solid',
          },
        },
      });
      publicLandSurveyLayer.add(plssTownshipLayer);

      const plssSectionLayer = new FeatureLayer({
        title: 'PLSS Section',
        url: plssSectionServiceUrl,
        minScale: sectionMinScale,
        labelingInfo: [{
          symbol: {
            type: 'text',
            color: 'gray',
            haloColor: 'white',
            haloSize: 0.1,
            font: {
              family: 'Playfair Display',
              size: 8,
            },
          },
          labelPlacement: 'above-center',
          labelExpressionInfo: {
            expression: '$feature.FRSTDIVLAB',
          },
        }],
        renderer: {
          type: 'simple',
          symbol: {
            type: 'simple-line',
            size: 12,
            color: 'gray',
            style: 'solid',
          },
        },
      });
      publicLandSurveyLayer.add(plssSectionLayer);

      const texasLandSurveyLayer = new FeatureLayer({
        title: 'Original Texas Land Survey (OTLS) - Land Grid (Statewide)',
        url: texasLandSurveyServiceUrl,
        minScale: sectionMinScale,
        labelingInfo: [{
          symbol: {
            type: 'text',
            color: 'gray',
            haloColor: 'white',
            haloSize: 0.1,
            font: {
              family: 'Playfair Display',
              size: 8,
            },
          },
          labelPlacement: 'above-center',
          labelExpressionInfo: {
            expression: String.raw`Replace($feature.ABSTRACT_L, 'A-', '')`,
          },
          minScale: sectionMinScale,
        }],
        renderer: {
          type: 'simple',
          symbol: {
            type: 'simple-line',
            size: 12,
            color: 'gray',
            style: 'solid',
          },
        },
      });
      landSurveyLayer.add(texasLandSurveyLayer);
      map.add(landSurveyLayer);
      setMapLayers(layers => ({
        ...layers,
        [MapLayerType.LandSurvey]: landSurveyLayer,
      }));
      result = () => {
        map.remove(landSurveyLayer);
        setMapLayers(layers => Object.fromEntries(
          Object.entries(layers).filter(([key]) => key !== MapLayerType.LandSurvey),
        ));
      };
    }
    return result;
  }, [map, mapTopoLoaded, mapHillshadeLoaded, usingPublicLandSurvey, maxScaleForCountyNames, maxScaleForCountyLines]);

  useEffect(() => {
    let result;
    if (map && mapTopoLoaded && mapHillshadeLoaded && usingAreasOfInterest) {
      const graphicsLayer = new GraphicsLayer({
        title: 'Areas of Interest',
      });
      const simpleFillSymbol = {
        type: 'simple-fill',
        color: mapAreaOfInterestFillRgba,
        outline: {
          color: [
            mapAreaOfInterestOutline.strokeColorRgb[0],
            mapAreaOfInterestOutline.strokeColorRgb[1],
            mapAreaOfInterestOutline.strokeColorRgb[2],
            mapAreaOfInterestOutline.strokeOpacity,
          ],
          width: mapAreaOfInterestOutline.strokeWeight,
        },
      };
      areasOfInterestPolygons.forEach(coords => {
        const polygon = {
          type: 'polygon',
          rings: coords.map(({ lng, lat }) => [lng, lat]),
        };
        const polygonGraphic = new Graphic({
          geometry: polygon,
          symbol: simpleFillSymbol,
        });
        graphicsLayer.add(polygonGraphic);
      });
      map.add(graphicsLayer);
      setMapLayers(layers => ({
        ...layers,
        [MapLayerType.AreasOfInterest]: graphicsLayer,
      }));
      result = () => {
        map.remove(graphicsLayer);
        setMapLayers(layers => Object.fromEntries(
          Object.entries(layers).filter(([key]) => key !== MapLayerType.AreasOfInterest),
        ));
      };
    }
    return result;
  }, [map, mapTopoLoaded, mapHillshadeLoaded, areasOfInterestPolygons, usingAreasOfInterest]);

  useEffect(() => {
    let result;
    if (map && mapTopoLoaded && mapHillshadeLoaded && usingWells) {
      const wellsGroupLayer = new GroupLayer({
        title: 'Wells',
      });

      const graphicsLayer = new GraphicsLayer({
        title: 'Well Laterals',
      });

      const lineSymbol = {
        type: 'simple-line',
        color: mapWellAreaLineStyle.colorRgba,
        width: mapWellAreaLineStyle.width,
        style: mapWellAreaLineStyle.style,
      };

      wellsAugmented.forEach((
        {
          wellTitle,
          surfaceLatitude,
          surfaceLongitude,
          bottomLatitude,
          bottomLongitude,
        },
        wellIndex,
      ) => {
        if (
          isNumber(surfaceLatitude)
          && isNumber(surfaceLongitude)
          && isNumber(bottomLatitude)
          && isNumber(bottomLongitude)
        ) {
          const coords = [
            { lat: +surfaceLatitude, lng: +surfaceLongitude },
            { lat: +bottomLatitude, lng: +bottomLongitude },
          ];
          const polyline = {
            type: 'polyline',
            paths: [coords.map(({ lat, lng }) => [lng, lat])],
          };
          const lineAtt = {
            wellIndex,
            wellTitle,
          };
          const polylineGraphic = new Graphic({
            geometry: polyline,
            symbol: lineSymbol,
            attributes: lineAtt,
            popupTemplate: {
              title: '{wellTitle} - Lateral',
            },
          });
          graphicsLayer.add(polylineGraphic);
        }
      });
      wellsGroupLayer.add(graphicsLayer);

      const wellsLayer = new FeatureLayer({
        title: 'Well Locations',
        source: wellsAugmented.map(well => ({
          geometry: new Point({
            latitude: well.latitude,
            longitude: well.longitude,
          }),
          attributes: {
            isWell: true,
            wellIndex: well.wellIndex,
            wellId: well.id,
            wellName: well.wellName,
            wellTitle: well.wellTitle,
            wellListingId: well.property?.id || '',
            projectName: well.property?.projectName || '',
          },
        })),
        fields: [
          {
            name: 'wellIndex',
            alias: 'Well Index',
            type: 'oid',
          },
          {
            name: 'wellId',
            alias: 'Well ID',
            type: 'oid',
          },
          {
            name: 'wellName',
            alias: 'Well Name',
            type: 'string',
          },
          {
            name: 'wellTitle',
            alias: 'Well Title',
            type: 'string',
          },
          {
            name: 'wellListingId',
            alias: 'Listing ID',
            type: 'oid',
          },
          {
            name: 'projectName',
            alias: 'Project Name',
            type: 'string',
          },
        ],
        objectIdField: 'wellIndex',
        displayField: 'wellName',
        geometryType: 'point',
        ...usingClusteringForWells && {
          featureReduction: {
            type: 'cluster',
            popupTemplate: {
              title: 'There are <b>{cluster_count}</b> wells at this location',
              content: 'Zoom in or click on "Browse features" to explore individual wells',
              fieldInfos: [
                {
                  fieldName: 'cluster_count',
                  format: {
                    places: 0,
                    digitSeparator: true,
                  },
                },
              ],
            },
            ...wellClusteringProps,
          },
        },
        popupTemplate: {
          title: '{wellTitle}',
        },
        renderer: {
          ...wellIconRendererProps,
        },
      });
      wellsGroupLayer.add(wellsLayer);

      if (mapView) {
        let coords = [];
        const defaultPoint = {};
        wellsAugmented.forEach(({
          surfaceLatitude,
          surfaceLongitude,
          bottomLatitude,
          bottomLongitude,
        }) => {
          const surfaceCoord = {};
          if (isNumber(surfaceLatitude)) {
            surfaceCoord.lat = +surfaceLatitude;
            defaultPoint.lat = surfaceCoord.lat;
          }
          if (isNumber(surfaceLongitude)) {
            surfaceCoord.lng = +surfaceLongitude;
            defaultPoint.lng = surfaceCoord.lng;
          }
          if (Object.keys(surfaceCoord).length) {
            coords.push(surfaceCoord);
          }

          const bottomCoord = {};
          if (isNumber(bottomLatitude)) {
            bottomCoord.lat = +bottomLatitude;
            defaultPoint.lat = bottomCoord.lat;
          }
          if (isNumber(bottomLongitude)) {
            bottomCoord.lng = +bottomLongitude;
            defaultPoint.lng = bottomCoord.lng;
          }
          if (Object.keys(bottomCoord).length) {
            coords.push(bottomCoord);
          }
        });
        coords = coords.map(coord => ({
          lat: coord.lat || defaultPoint.lat,
          lng: coord.lng || defaultPoint.lng,
        })).filter(coord => coord.lat && coord.lng);

        if (!coords.length) {
          // TODO: if no coordinates, zoom to initial zoom and center the map
        } else {
          if (coords.length === 1) {
            const dist = 0.002;
            const halfDist = dist / 2;
            coords[0].lat -= halfDist;
            coords[0].lng -= halfDist;
            coords.push({
              lat: coords[0].lat + dist,
              lng: coords[0].lng + dist,
            });
          }
          const polyline = {
            type: 'polyline',
            paths: [coords.map(({ lat, lng }) => [lng, lat])],
          };
          const polylineGraphic = new Graphic({
            geometry: polyline,
          });
          const wellsExtent = polylineGraphic.geometry.extent.clone().expand(1.5);
          mapView.goTo(wellsExtent);
        }
      }
      map.add(wellsGroupLayer);
      setMapLayers(layers => ({
        ...layers,
        [MapLayerType.Wells]: wellsLayer,
      }));
      result = () => {
        map.remove(wellsGroupLayer);
        setMapLayers(layers => Object.fromEntries(
          Object.entries(layers).filter(([key]) => key !== MapLayerType.Wells),
        ));
      };
    }
    return result;
  }, [map, mapTopoLoaded, mapHillshadeLoaded, mapView, usingWells, wellsAugmented]);

  useEffect(() => {
    let result;
    if (map && mapTopoLoaded && mapHillshadeLoaded && usingListings) {
      const listingsLayer = new FeatureLayer({
        title: 'Listings',
        source: listings.map(listing => ({
          geometry: new Point({
            latitude: listing.latitude,
            longitude: listing.longitude,
          }),
          attributes: {
            listingId: listing.propertyId,
            listingTitle: `${listing.projectName} (${listing.wellCount} well${pluralize('s', listing.wellCount)})`,
            isListing: true,
            projectName: listing.projectName,
            wellCount: listing.wellCount,
          },
        })),
        fields: [
          {
            name: 'listingId',
            alias: 'Listing ID',
            type: 'oid',
          },
          {
            name: 'listingTitle',
            alias: 'Title',
            type: 'string',
          },
          {
            name: 'projectName',
            alias: 'Project Name',
            type: 'string',
          },
          {
            name: 'wellCount',
            alias: 'Well Count',
            type: 'integer',
          },
        ],
        objectIdField: 'listingId',
        displayField: 'projectName',
        geometryType: 'point',
        ...usingClusteringForListings && {
          featureReduction: {
            type: 'cluster',
            /**
             * TODO: use custom popup `frontend-main/src/pages/Map/components/InfoWindow` instead?
             */
            popupTemplate: {
              title: 'There are <b>{cluster_count}</b> listings at this location',
              content: 'Zoom in or click on "Browse features" to explore individual listings',
              fieldInfos: [
                {
                  fieldName: 'cluster_count',
                  format: {
                    places: 0,
                    digitSeparator: true,
                  },
                },
              ],
            },
            ...wellClusteringProps,
          },
        },
        popupEnabled: usingEmbeddedListingPopup,
        ...usingEmbeddedListingPopup && {
          popupTemplate: {
            title: '{listingTitle}',
            actions: [
              ...!usingOriginalListingPopup ? [{
                title: 'Listing Details',
                id: TriggerAction.OpenListing,
              }] : [],
            ],
            ...usingOriginalListingPopup && {
              content: feature => {
                const div = document.createElement('div');
                featurePopupDivsRef.current.push(div);

                const { propertyId } = feature.graphic.attributes;

                const item = feature.graphic.sourceLayer.source.items.find(
                  record => record.propertyId === propertyId,
                );

                const itemAttributes = item.attributes;
                const listingForInfoWindow = {
                  id: itemAttributes.propertyId,
                  projectName: itemAttributes.projectName,
                  wellCount: itemAttributes.wellCount,
                };
                const listingsForInfoWindow = [listingForInfoWindow];

                ReactDOM.render(
                  <Provider store={store}>
                    <ConnectedRouter history={history}>
                      <ThemeProvider theme={theme}>
                        <InfoWindow
                          isEmbedded
                          listings={listingsForInfoWindow}
                        />
                      </ThemeProvider>
                    </ConnectedRouter>
                  </Provider>,
                  div,
                );
                return div;
              },
            },
          },
        },
        renderer: {
          ...wellIconRendererProps,
        },
      });
      map.add(listingsLayer);
      setMapLayers(layers => ({
        ...layers,
        [MapLayerType.Listings]: listingsLayer,
      }));
      result = () => {
        map.remove(listingsLayer);
        setMapLayers(layers => Object.fromEntries(
          Object.entries(layers).filter(([key]) => key !== MapLayerType.Listings),
        ));
      };
    }
    return result;
  }, [map, mapTopoLoaded, mapHillshadeLoaded, listings, usingListings, history, store, theme]);

  useEffect(() => {
    let result;
    if (map && mapTopoLoaded && mapHillshadeLoaded && usingHistoricalListings) {
      const historicalListingsLayer = new FeatureLayer({
        title: 'Historical Transactions',
        source: historicalListings.map(listing => ({
          geometry: new Point({
            latitude: listing.surfaceHoleLatitude,
            longitude: listing.surfaceHoleLongitude,
          }),
          attributes: {
            listingId: listing.id,
            listingTitle: `Historical Transaction: ${listing.wellName}`,
            isHistoricalListing: true,
            operator: listing.operator,
            api: listing.api,
            surfaceHoleLatitude: listing.surfaceHoleLatitude,
            surfaceHoleLongitude: listing.surfaceHoleLongitude,
            bottomHoleLatitude: listing.bottomHoleLatitude,
            bottomHoleLongitude: listing.bottomHoleLongitude,
            buyer: listing.buyer,
            seller: listing.seller,
            carryPercent: listing.carryPercent,
            recordedDate: listing.recordedDate && new Date(listing.recordedDate).getTime(),
            executedDate: listing.executedDate && new Date(listing.executedDate).getTime(),
            effectiveDate: listing.effectiveDate && new Date(listing.effectiveDate).getTime(),
            wtiSpotPrice: listing.wtiSpotPrice,
            henryHubSpotPrice: listing.henryHubSpotPrice,
            target: listing.target,
            totalVerticalDepthInFeet: listing.totalVerticalDepthInFeet,
            wellName: listing.wellName,
          },
        })),
        fields: [
          {
            name: 'listingId',
            alias: 'Historical Transaction ID',
            type: 'oid',
          },
          {
            name: 'listingTitle',
            alias: 'Title',
            type: 'string',
          },
          {
            name: 'operator',
            alias: 'Operator',
            type: 'string',
          },
          {
            name: 'api',
            alias: 'API',
            type: 'string',
          },
          {
            name: 'surfaceHoleLatitude',
            alias: 'Surface Hole Lat',
            type: 'double',
          },
          {
            name: 'surfaceHoleLongitude',
            alias: 'Surface Hole Long',
            type: 'double',
          },
          {
            name: 'bottomHoleLatitude',
            alias: 'Bottom Hole Lat',
            type: 'double',
          },
          {
            name: 'bottomHoleLongitude',
            alias: 'Bottom Hole Long',
            type: 'double',
          },
          {
            name: 'totalVerticalDepthInFeet',
            alias: 'TVD',
            type: 'integer',
          },
          {
            name: 'wellName',
            alias: 'Well Name',
            type: 'string',
          },
          {
            name: 'recordedDate',
            alias: 'Recorded Date',
            type: 'date',
          },
          {
            name: 'executedDate',
            alias: 'Executed Date',
            type: 'date',
          },
          {
            name: 'effectiveDate',
            alias: 'Effective Date',
            type: 'date',
          },
          {
            name: 'wtiSpotPrice',
            alias: 'WTI Spot Price',
            type: 'double',
          },
          {
            name: 'henryHubSpotPrice',
            alias: 'Henry Hub Spot Price',
            type: 'double',
          },
          {
            name: 'target',
            alias: 'Target',
            type: 'string',
          },
          {
            name: 'carryPercent',
            alias: 'Carry %',
            type: 'double',
          },
          {
            name: 'seller',
            alias: 'Seller',
            type: 'string',
          },
          {
            name: 'buyer',
            alias: 'Buyer',
            type: 'string',
          },
        ],
        objectIdField: 'listingId',
        displayField: 'listingTitle',
        geometryType: 'point',
        ...usingClusteringForListings && {
          featureReduction: {
            type: 'cluster',
            /**
             * TODO: use custom popup `frontend-main/src/pages/Map/components/InfoWindow` instead?
             */
            popupTemplate: {
              title: 'There are <b>{cluster_count}</b> historical listings at this location',
              content: 'Zoom in or click on "Browse features" to explore individual listings',
              fieldInfos: [
                {
                  fieldName: 'cluster_count',
                  format: {
                    places: 0,
                    digitSeparator: true,
                  },
                },
              ],
            },
            ...historicalWellClusteringProps,
          },
        },
        popupEnabled: true,
        popupTemplate: {
          title: '{listingTitle}',
          outFields: '*',
          content: [{
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'listingId',
                label: 'ID',
                visible: false,
              },
              {
                fieldName: 'operator',
                label: 'Operator',
              },
              {
                fieldName: 'api',
                label: 'API',
              },
              {
                fieldName: 'surfaceHoleLatitude',
                label: 'Surface Hole Lat',
              },
              {
                fieldName: 'surfaceHoleLongitude',
                label: 'Surface Hole Long',
              },
              {
                fieldName: 'bottomHoleLatitude',
                label: 'Bottom Hole Lat',
              },
              {
                fieldName: 'bottomHoleLongitude',
                label: 'Bottom Hole Long',
              },
              {
                fieldName: 'totalVerticalDepthInFeet',
                label: 'TVD',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
              },
              {
                fieldName: 'wellName',
                label: 'Well Name',
              },
              {
                fieldName: 'recordedDate',
                label: 'Recorded Date',
                format: {
                  dateFormat: 'day-short-month-year',
                },
              },
              {
                fieldName: 'executedDate',
                label: 'Executed Date',
                format: {
                  dateFormat: 'day-short-month-year',
                },
              },
              {
                fieldName: 'effectiveDate',
                label: 'Effective Date',
                format: {
                  dateFormat: 'day-short-month-year',
                },
              },
              {
                fieldName: 'wtiSpotPrice',
                label: 'WTI Spot Price',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
              },
              {
                fieldName: 'henryHubSpotPrice',
                label: 'Henry Hub Spot Price',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
              },
              {
                fieldName: 'target',
                label: 'Target',
              },
              {
                fieldName: 'carryPercent',
                label: 'Carry %',
                format: {
                  digitSeparator: true,
                  places: 2,
                },
              },
              {
                fieldName: 'seller',
                label: 'Seller',
              },
              {
                fieldName: 'buyer',
                label: 'Buyer',
              },
            ],
          }],
        },
        renderer: {
          ...historicalWellIconRendererProps,
        },
      });
      map.add(historicalListingsLayer);
      setMapLayers(layers => ({
        ...layers,
        [MapLayerType.HistoricalListings]: historicalListingsLayer,
      }));
      result = () => {
        map.remove(historicalListingsLayer);
        setMapLayers(layers => Object.fromEntries(
          Object.entries(layers).filter(([key]) => key !== MapLayerType.HistoricalListings),
        ));
      };
    }
    return result;
  }, [map, mapTopoLoaded, mapHillshadeLoaded, historicalListings, usingHistoricalListings, history, store, theme]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div style={{ width: '100%', height: '100%' }} ref={setViewDiv} />
      {listingPopup && (
        <div
          style={{
            position: 'absolute',
            left: `${listingPopup.x}px`,
            top: `${listingPopup.y}px`,
          }}
        >
          <InfoWindow
            listings={listingPopup.listings}
            handleCloseInfoWindow={() => setListingPopup(undefined)}
          />
        </div>
      )}
      <Box
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: 'calc(100% - 16px)',
          pointerEvents: 'none',
          padding: 15,
        }}
      >
        <Box
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          {usingLayersList && (
            <Box
              display="flex"
              style={{
                position: 'absolute',
                pointerEvents: 'auto',
                left: 0,
                top: 0,
                padding: 5,
              }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Map Layers</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List
                    style={{
                      padding: 0,
                    }}
                  >
                    {(
                      Object.values(MapLayerType)
                        .filter(type => mapLayers[type])
                        .map(type => ({ type, layer: mapLayers[type] }))
                        .map(({ type, layer }) => {
                          const checkBoxId = `mapLayerCheckBox-${type}`;
                          return (
                            <ListItem
                              key={type}
                              role={undefined}
                              dense
                              button
                              onClick={() => {
                                // eslint-disable-next-line no-param-reassign
                                layer.visible = !layer.visible;
                                setMapLayers(layers => ({ ...layers }));
                              }}
                              style={{
                                borderTop: `1px solid ${theme.palette.text.disabled}`,
                              }}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={layer.visible}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': checkBoxId }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                id={checkBoxId}
                                primary={layer.title}
                              />
                            </ListItem>
                          );
                        })
                      )}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}

          {usingMapTypeSelector && (
            <Box
              display="flex"
              flexDirection="row"
              style={{
                position: 'absolute',
                backgroundColor: 'white',
                pointerEvents: 'auto',
                padding: 5,
                left: 0,
                bottom: 0,
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
              >
                {(
                  Object.values(MapType)
                    .filter(mapType => mapTypesExpanded || mapType === selectedMapType)
                    .map(mapType => (
                      <MapTypeCard
                        key={mapType}
                        mapType={mapType}
                        selected={mapType === selectedMapType}
                        onClick={() => setSelectedMapType(mapType)}
                      />
                    ))
                )}
              </Box>
              {Object.values(MapType).length > 1 && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <IconButton
                    color="primary"
                    aria-label="expand/minimize map type control"
                    size="medium"
                    onClick={() => setMapTypesExpanded(expanded => !expanded)}
                    style={{
                      padding: 0,
                    }}
                  >
                    {(
                      mapTypesExpanded
                        ? (
                          <ChevronLeftIcon />
                        )
                        : (
                          <ChevronRightIcon />
                        )
                    )}
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

EsriMap.propTypes = {
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      projectName: PropTypes.string.isRequired,
      wellCount: PropTypes.number.isRequired,
      propertyId: PropTypes.number.isRequired,
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }).isRequired,
  ),
  historicalListings: PropTypes.arrayOf(
    PropTypes.shape({
      operator: PropTypes.string,
      // TODO: add remaining field type definitions
    }).isRequired,
  ),
  wells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      wellName: PropTypes.string,
      property: PropTypes.shape({
        id: PropTypes.number,
        projectName: PropTypes.string,
      }),
      surfaceLatitude: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      surfaceLongitude: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      bottomLatitude: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      bottomLongitude: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    }).isRequired,
  ),
  areasOfInterestPolygons: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        lng: PropTypes.number.isRequired,
        lat: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
  ),
  isListingsMap: PropTypes.bool,
  onBasemapLoaded: PropTypes.func,
};

EsriMap.defaultProps = {
  listings: [],
  historicalListings: [],
  wells: [],
  areasOfInterestPolygons: [],
  isListingsMap: false,
  onBasemapLoaded: undefined,
};

export default EsriMap;
