import '@fontsource/archivo-black';
import { landingPageLargeText, white } from 'now-frontend-shared/themes/colors';

export default {
  wrapper: {
    background: "url('/images/rig-day.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '35px',
    minHeight: '1188px',
  },
  backgroundContainer: {
    position: 'absolute',
    overflow: 'hidden',
  },
  background: {
    minHeight: '100vh',
    minWidth: '100vw',
    zIndex: 0,
    'object-fit': 'cover',

    '@media screen and (max-width: 1100px)': {
      transform: 'translateX(20%)',
      minWidth: 'unset',
    },

    '@media screen and (max-width: 600px)': {
      minHeight: 'unset',
      height: '100vh',
      transform: 'translateX(18%)',
    },

    '@media (aspect-ratio: 256/135)': {
      width: '100vw',
    },
  },
  headerContainer: {
    margin: '50px 0 200px 0',
    textAlign: 'center',
  },
  headerText: {
    fontFamily: 'Archivo Black',
    fontSize: '60px',
    fontWeight: 'normal',
    color: landingPageLargeText,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    zIndex: 3,
    marginLeft: 'auto',
    marginRight: 'auto',
    top: '130px',
    left: 0,
    right: 0,
  },
  button: {
    width: '236px',
  },
  bioContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',

    '@media screen and (max-width: 1100px)': {
      flexDirection: 'column',
      gap: '150px',
    },
  },
  memberBio: {
    width: '30%',
    maxWidth: '408px',
    textAlign: 'justify',
    '@media screen and (max-width: 1100px)': {
      width: '100%',
      maxWidth: 'inherit',
    },
  },
  memberBioText: {
    width: '95%',
  },
  requestDemoContainer: {
    marginTop: '10px',
  },
  requestDemoHeader: {
    fontFamily: 'Archivo Black',
    fontColor: white,
    fontSize: '60px',
    color: white,
  },
};
