/**
 * @typedef ComputeCommissionArgs
 * @property {number|null=} bidAmount
 * @property {number} netAfe
 */

/**
 * NOTE: changing this calculation after a live auction in production has opened
 * must be accompanied by a database migration to retroactively store closing fees
 * and total listing costs per listing, so that historical/audit data regarding the
 * listing fees is preserved and recorded.
 *
 * For a Cash bid (the bid amount exists and is greater than zero),
 * the closing fee is the lesser of:
 * * 15% of the Purchase Price (i.e., bid amount)
 * * 3% of the Listing's Net AFE
 *
 * For a Carry bid (the bid amount does not exist or is zero), the closing fee is:
 * * 3% of the Listing's Net AFE
 *
 * @arg {ComputeCommissionArgs} args
 * @returns {number}
 */
export function computeCommission({ bidAmount, netAfe }) {
  const purchasePrice = bidAmount;
  const defaultCommission = 0.03 * netAfe;
  const commission = purchasePrice
    ? Math.min(
      defaultCommission,
      0.15 * purchasePrice,
    )
    : defaultCommission;
  return Math.round(commission);
}

/**
 * This is the dollar amount the buyer pays to the seller in escrow for a carry transaction.
 *
 * NOTE: changing this value after a carry bid has been placed on a listing
 * in production must be accompanied by a database migration to retroactively store
 * total listing costs per listing, so that historical/audit data regarding the
 * listing cost is accurately preserved and recorded.
 */
export const grantConsideration = 10;

/**
 * @typedef ComputeTotalListingCostArgs
 * @property {number|null=} bidAmount
 * @property {number} closingFees
 */

/**
 * NOTE: changing this calculation after a live auction in production has opened
 * must be accompanied by a database migration to retroactively store closing fees
 * and total listing costs per listing, so that historical/audit data regarding the
 * listing fees is preserved and recorded.
 *
 * @arg {ComputeTotalListingCostArgs} args
 * @returns {number}
 */
export function computeTotalListingCost({ bidAmount, closingFees }) {
  const purchasePrice = bidAmount || 0;
  return (purchasePrice || grantConsideration) + closingFees;
}

/**
 * @typedef ComputeAcquiredRetainedInterestArgs
 * @property {number|null=} carryPercentage Carry percentage
 * @property {number} sellerOriginalOwnershipPercentage Original seller ownership percentage
 */

/**
 * @typedef ComputeAcquiredRetainedInterestResult
 * @property {number} buyerAcquiredPercentage Percentage buyer ownership
 * @property {number} sellerRetainedPercentage Percentage seller owernship
 */

/**
 * @param {ComputeAcquiredRetainedInterestArgs} args
 * @returns {ComputeAcquiredRetainedInterestResult}
 */
export function computeAcquiredRetainedInterest({
  bidCarryPercentage,
  sellerOriginalOwnershipPercentage,
}) {
  const sanitizedCarryPercentage = Math.max(0, Math.min(100, (bidCarryPercentage ?? 0)));
  const carryPercent = sanitizedCarryPercentage / 100;
  const sanitizedOriginalPercentage = Math.max(0, Math.min(100, sellerOriginalOwnershipPercentage));
  const sellerRetainedPercentage = carryPercent * sanitizedOriginalPercentage;
  const buyerAcquiredPercentage = (1 - carryPercent) * sanitizedOriginalPercentage;
  return {
    buyerAcquiredPercentage,
    sellerRetainedPercentage,
  };
}

/**
 * @property {number} sellerRetainedPercentage Percentage seller owernship
 * @property {number} wellGrossAFE
 */
export function computeImpliedPrice(
  wellGrossAFE,
  sellerRetainedPercentage,
) {
  return wellGrossAFE * (sellerRetainedPercentage / 100);
}
